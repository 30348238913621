import {
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Hidden,
} from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useStyles from "../../theme/styles";
import MButton from "../Reusables/MButton";

const Jobs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={clsx(classes.jobsContainer, classes.relativePosition)}>
      <div>
        <Grid
          container
          justify="space-between"
          direction={matches ? "column-reverse" : "row"}
        >
          <Grid item xs={12} md={6}>
            <div
              className={clsx(classes.container, classes.mt60, classes.mb50)}
            >
              <Typography gutterBottom className={clsx(classes.teamHeader2)}>
                Ready to journey with us
              </Typography>
              <Typography
                gutterBottom
                className={clsx(classes.text, classes.textUpper)}
              >
                We've got you covered
              </Typography>
              <motion.div
                className={clsx(classes.fitContent)}
                whileTap={{
                  scale: 0.8,
                }}
              >
                <MButton variant="outlined" component={Link} to="/careers">
                  Search Open Roles
                </MButton>
              </motion.div>
            </div>
          </Grid>
          <Hidden smDown>
            <div className={classes.greySkewLine} />
          </Hidden>

          <Grid item xs={12} md={6}>
            <Hidden smDown>
              <div className={clsx(classes.skewed)}>
                <div className={clsx(classes.overflowHidden)}>
                  <img
                    src="/img/large/coders.jpeg"
                    alt="Careers"
                    draggable="false"
                    className={clsx(classes.skewedInnerImg)}
                  />
                </div>
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Jobs;
