const Loader = () => {
  return (
    <div className="bubblingG">
      <span id="bubblingG_1"></span>
      <span id="bubblingG_2"></span>
      <span id="bubblingG_3"></span>
    </div>
  );
};

export default Loader;
