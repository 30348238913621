import { Typography, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../../theme/styles";
import AppButtons from "../../Reusables/AppButtons";

const GetApps = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={clsx(classes.container, classes.getAppContainer2)}>
      <Grid container justify="space-between">
        <Grid item>
          <div className={classes.mb50}>
            <Typography
              gutterBottom
              className={clsx(classes.subtitle, classes.mt40)}
            >
              Do More in the App
            </Typography>
            <div className={classes.mt20}>
              <Typography gutterBottom>Download our mobile app</Typography>
              <AppButtons {...{ clsx, classes }} />
            </div>
          </div>
        </Grid>
        <Grid item>
          {matches ? null : (
            <div className={clsx(classes.textCenter)}>
              {/* <img src="/img/svgs/qr-code.svg" alt="QR code" height={250} /> */}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default GetApps;
