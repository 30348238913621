import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

import Wrapper from "../components/Wrapper";
import TermsComponent from "../components/Policies/TermsComponent";
import { pageTransition } from "../utils/transition";
import { organizationStructuredData } from "../structured-data";

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Germiny&reg; - Read more about our Terms & Conditions.</title>
        <meta property="og:title" content="Germiny Terms & Conditions" />
        <meta property="og:site_name" content="Germiny" />
        <meta property="og:url" content="https://germiny.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Germiny Terms of Condition &mdash; Primary healthcare is one of the most important aspects of the healthcare system. Accessibility and affordability of the right healthcare professional plays a huge role in getting this care. The Germiny Practitioner is concerned with maintaining and restoring health through the diagnosis, prognosis and treatment of diseases, injuries and other physical and mental impairments in the comfort of your home, office or any where you are."
        />

        <script type="application/ld+json">
          {organizationStructuredData({
            headline: "Terms Of Condition",
            authorName: "Arifayan Idowu",
            description:
              "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
          })}
        </script>
      </Helmet>
      <Wrapper>
        <motion.div
          layout
          variants={pageTransition}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <TermsComponent />
        </motion.div>
      </Wrapper>
    </>
  );
};

export default Terms;
