import { Route } from "react-router";
import ComingSoon from "../components/Reusables/ComingSoon";
import About from "../pages/About";
import Applications from "../pages/Applications";
import BusinessHome from "../pages/BusinessHome";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import Leadership from "../pages/Leadership";
import LeadershipDetail from "../pages/LeadershipDetail";
import PractionerReg from "../pages/PractionerReg";
import PractitionerHome from "../pages/PractitionerHome";
import Privacy from "../pages/Privacy";
import Products from "../pages/Products";
import Terms from "../pages/Terms";
import VeterinarianHome from "../pages/VeterinarianHome";
import Veterinary from "../pages/Veterinary";
import DeleteAccount from "../pages/deleteAccount";

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/practitioner/register",
    component: PractionerReg,
    exact: true,
  },
  {
    path: "/veterinary/register",
    component: Veterinary,
    exact: true,
  },
  {
    path: "/leadership",
    component: Leadership,
    exact: true,
  },
  {
    path: "/leadership/:name",
    component: LeadershipDetail,
    exact: true,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/details/products/:slug",
    component: Products,
  },
  {
    path: "/details/products",
    component: Products,
    exact: true,
  },
  {
    path: "/products/practitioner",
    component: PractitionerHome,
    exact: true,
  },
  {
    path: "/products/veterinary",
    component: VeterinarianHome,
    exact: true,
  },
  {
    path: "/products/applications",
    component: Applications,
    exact: true,
  },
  {
    path: "/business",
    component: BusinessHome,
  },
  {
    path: "/terms",
    component: Terms,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/careers",
    component: ComingSoon,
  },
  {
    path: "/blog",
    component: ComingSoon,
  },
  {
    path: "/newsroom",
    component: ComingSoon,
  },
  {
    path: "/deleteAccountAuth",
    component: DeleteAccount,
  },
  {
    path: "/deleteAccountConfirmation",
    component: ComingSoon,
  },
];

const RouteWrapper = ({ toggleTheme, ...route }) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        <route.component
          {...props}
          routes={route.routes}
          toggleTheme={toggleTheme}
        />
      )}
    />
  );
};

export { routes as default, RouteWrapper };
