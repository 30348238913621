import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { asyncFetch } from "../api/asyncFetch";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const contactUsLogic = createAsyncThunk(
  "info/contactUs",
  async (data, { rejectWithValue }) => {
    try {
      const res = await asyncFetch(`${BASE_URL}/visitor/contact`, "post", data);
      return await res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const newsLetterLogic = createAsyncThunk(
  "info/newsLetter",
  async (data, { rejectWithValue }) => {
    try {
      const res = await asyncFetch(
        `${BASE_URL}/visitor/subscribe`,
        "post",
        data
      );
      return await res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const comingSoonLogic = createAsyncThunk(
  "info/comingSoon",
  async (data, { rejectWithValue }) => {
    try {
      const res = await asyncFetch(
        `${BASE_URL}/visitor/reminder`,
        "post",
        data
      );
      return await res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  info: null,
  status: "idle",
  loading: false,
  error: null,
};

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactUsLogic.pending, (state, _) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(contactUsLogic.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "idle";
        state.info = payload.data;
      })
      .addCase(contactUsLogic.rejected, (state, action) => {
        state.status = "idle";
        state.loading = false;
        state.error = action.error?.error;
      });
    builder
      .addCase(newsLetterLogic.pending, (state, _) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(newsLetterLogic.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "idle";
        state.info = payload.data;
      })
      .addCase(newsLetterLogic.rejected, (state, action) => {
        state.status = "idle";
        state.loading = false;
        state.error = action.error?.error;
      });
    builder
      .addCase(comingSoonLogic.pending, (state, _) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(comingSoonLogic.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "idle";
        state.info = payload.data;
      })
      .addCase(comingSoonLogic.rejected, (state, action) => {
        state.status = "idle";
        state.loading = false;
        state.error = action.error?.error;
      });
  },
});

const { reducer } = infoSlice;

export default reducer;
