import { motion } from "framer-motion";

function LeftArrow({ page, color = "#000", size = 40 }) {
  const pathVariants = {
    hidden: {
      pathLength: 0,
      fill: color,
    },
    visible: {
      pathLength: 1,
      fill: color,
      transition: {
        type: "spring",
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate="visible"
        d="M512 256c0 50.531-15 99.676-43.375 142.117-6.137 9.18-18.559 11.649-27.742 5.508-9.184-6.137-11.649-18.559-5.512-27.742C459.336 340.043 472 298.59 472 256c0-119.102-96.898-216-216-216S40 136.898 40 256s96.898 216 216 216c39.508 0 78.145-10.758 111.738-31.105 9.446-5.723 21.742-2.704 27.465 6.742 5.727 9.449 2.707 21.746-6.742 27.468C348.617 499.242 302.812 512 256 512c-68.379 0-132.668-26.629-181.02-74.98C26.63 388.668 0 324.379 0 256S26.629 123.332 74.98 74.98C123.332 26.63 187.621 0 256 0s132.668 26.629 181.02 74.98C485.37 123.332 512 187.621 512 256zm-112 0c0-11.047-8.953-20-20-20H172.816l72.282-71.813c7.836-7.785 7.875-20.449.09-28.285-7.786-7.836-20.45-7.875-28.286-.09l-78.199 77.696C127.285 224.852 121 239.94 121 256s6.285 31.148 17.703 42.488l78.2 77.7c3.902 3.875 9 5.812 14.097 5.812a19.94 19.94 0 0014.188-5.902c7.785-7.836 7.746-20.5-.09-28.286L172.816 276H380c11.047 0 20-8.953 20-20zm0 0"
      ></motion.path>
    </motion.svg>
  );
}

export default LeftArrow;
