import { Grid, ButtonGroup, Button, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";

import clsx from "clsx";
import { wrap } from "popmotion";

import useStyles from "../../../../theme/styles";
import AllPracts from "./AllPracts";
import MIcon from "../../../Reusables/MIcon";
import { ReactComponent as ChevronLeft } from "../../../../theme/assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../../theme/assets/chevron-right.svg";
import FAQs from "./FAQs";
import GetApps from "./GetApps";
import Benefit from "../../Benefit";

const FeatureIndex = () => {
  const classes = useStyles();
  const [[page, direction], setPage] = useState([0, 0]);

  const contentsIndex = wrap(0, contents.length, page);
  const imagesIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    if (page > (images.length - 1 || contents.length - 1)) {
      setPage([0, direction]);
    } else if (page < 0) {
      setPage([images.length - 1 || contents.length - 1, direction]);
    }
  }, [page, direction]);

  return (
    <>
      <div className={clsx(classes.container, classes.featureIndexContainer)}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.mb20}
        >
          <Grid item>
            <Typography className={classes.boldText}>Features</Typography>
          </Grid>
          <Grid item>
            <Grid container justify="center" alignItems="center" spacing={3}>
              <Grid item>
                <span>
                  {imagesIndex >= 9 ? null : 0}
                  {imagesIndex + 1}
                </span>
              </Grid>
              <Grid item>
                <ButtonGroup className={clsx(classes.borderNone)}>
                  <Button
                    color="primary"
                    onClick={() => paginate(-1)}
                    className={clsx(classes.borderNone)}
                  >
                    <MIcon src={ChevronLeft} />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(classes.textWhite, classes.borderNone)}
                    onClick={() => paginate(1)}
                  >
                    <MIcon src={ChevronRight} />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AllPracts
          {...{
            page,
            direction,
            setPage,
            contents,
            images,
            contentsIndex,
            imagesIndex,
          }}
        />
      </div>
      <Benefit title="How Germiny Practitioner works?" />
      <FAQs />
      <GetApps />
    </>
  );
};

const contents = [
  {
    header: "PHYSIOTHERAPIST",
    body: "Following injuries and illnesses, rehabilitation is sometimes required to prevent disability. Physiotherapists are trained to use physical therapy in form of massage and carefully mapped out exercise routines to help patients recover or achieve maximum mobility of the affected body parts.",
  },
  {
    header: "NUTRITIONIST",
    body: "Good nutrition is key to good physical and mental well being however, it is often times ignored. A nutritionist uses his/her knowledge of the different classes of food and nutrition to promote healthy lifestyles and manage disease conditions.",
  },
  {
    header: "GENERAL PRACTITIONER",
    body: "A general practitioner is a doctor who treats acute and chronic illnesses and provides preventive care and health education to patients. They are not limited by age, gender, body system or disease process as they are trained to manage a number of commonly occurring illnesses and refer should the need arise.",
  },
  {
    header: "OPTICIAN",
    body: "Getting the right lens not only improves vision but also prevents further damage. Opticians are professionals who fit and dispense corrective lenses.",
  },
  {
    header: "OPTOMETRIST",
    body: "The eye is the organ of sight. Though small and delicate it remains a very vital sense organ. Optometrists are specially trained to examine, diagnose and treat eye conditions and defects with the use of medications and prescription of refractive lenses.",
  },
  {
    header: "CLINICAL PSYCHOLOGIST",
    body: "Most humans go through difficult times/challenges in the course of life which affects our mental health differently. For some, these tough times or life events affect their mental health negatively. Mental health is just as important as physical health and should never be neglected. A clinical psychologist is a mental health professional who manages mental and behavioural illnesses with psychotherapy.",
  },
  {
    header: "DENTIST",
    body: "The mouth is as important as other parts of the body and shouldn't be neglected hence, the need for regular dental checks. A dentist specialises in the diagnosis, prevention, and treatment of diseases and conditions of the oral cavity.",
  },
  {
    header: "NURSE",
    body: "Everyone requires nursing care at some point or the other in life. Nurses play significant roles in caring for and supporting patients through periods of ill health to full recovery. ",
  },
  {
    header: "PEDIATRICIAN",
    body: "A pediatrician is more than just a doctor for your child; they're your trusted partner in navigating all stages of their health and development. From those precious first breaths to the teenage years, pediatricians are specially trained to understand the unique needs of growing children.",
  },
  {
    header: "LABORATORY SCIENTIST",
    body: "In arriving at a diagnosis, sometimes the doctor requests for lab tests. The lab scientist is skilled in taking and analysing such blood samples to help support diagnosis.",
  },
];

const images = [
  {
    img: "/img/small/physio2.jpg",
  },
  {
    img: "/img/small/nutri.jpg",
  },
  {
    img: "/img/small/gen-pract.jpg",
  },
  {
    img: "/img/small/glasses.jpg",
  },
  {
    img: "/img/small/optometrist.jpg",
  },
  {
    img: "/img/small/brain-2.jpg",
  },
  {
    img: "/img/small/teeth.jpg",
  },
  {
    img: "/img/small/nurse.jpg",
  },
  {
    img: "/img/small/pediatrician.jpg",
  },
  {
    img: "/img/small/lab-scientist.jpg",
  },
];

export default FeatureIndex;
