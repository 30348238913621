import { Button } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../theme/styles";

const MButton = ({ children, extraStyle, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      className={clsx(classes.mt20, classes.mb20, classes.btn, extraStyle)}
      size="large"
    >
      {children}
    </Button>
  );
};

export default MButton;
