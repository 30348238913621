import { combineReducers } from "redux";
import counterReducer from "../features/counter/counterSlice";
import teamsReducer from "../features/teams/teamsSlice";
import usersReducer from "../features/practitioners/practSlice";
import infoReducer from "../features/info/infoSlice";

const rootReducer = combineReducers({
  counter: counterReducer,
  teams: teamsReducer,
  users: usersReducer,
  info: infoReducer,
});

export default rootReducer;
