import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

import { pageTransition } from "../utils/transition";
import Wrapper from "../components/Wrapper";
import AppIndex from "../components/ProductComponents/Applications/AppIndex";
import { organizationStructuredData } from "../structured-data";

const Applications = () => {
  return (
    <>
      <Helmet>
        <title>
          Germiny - Check out our list of world class applications & solutions.
        </title>
        <meta property="og:title" content="Applications" />
        <meta property="og:site_name" content="Germiny" />
        <meta property="og:url" content="https://germiny.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="At Germiny®, we are all about empowerment with the help of technology."
        />

        <script type="application/ld+json">
          {organizationStructuredData({
            headline: "Germiny Application",
            authorName: "Arifayan Idowu",
            description:
              "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
          })}
        </script>
      </Helmet>
      <Wrapper>
        <motion.div
          layout
          variants={pageTransition}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <AppIndex />
        </motion.div>
      </Wrapper>
    </>
  );
};

export default Applications;
