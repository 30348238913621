import { Helmet } from "react-helmet-async";
import { organizationStructuredData } from "../../structured-data";
import { Grid, Typography } from "@material-ui/core";
import InputField from "../../components/Fields/InputField";
import MButton from "../../components/Reusables/MButton";
import Loader from "../../components/Reusables/Loader";
import Wrapper from "../../components/Wrapper";
import { useState } from "react";
import clsx from "clsx";
import useStyles from "../../theme/styles";
import { asyncFetch } from "../../features/api/asyncFetch";
import { motion } from "framer-motion";
import { pageTransition } from "../../utils/transition";

const initialState = {
  phoneno: "",
  password: "",
  accounttype: "",
};

const DeleteAccount = () => {
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [tempToken, setTempToken] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const submitAccountDeletionForm = async (e) => {
    e.preventDefault();
    if (selectedAccountType) {
      setLoading(true);
      try {
        const res = await asyncFetch(
          `https://germiny.dev/api/v1/auth/${
            state.accounttype === "practitioner" ? "practitioner" : "user"
          }/login`,
          "post",
          {
            phone: state.phoneno,
            password: state.password,
          }
        );
        const data = await res.data;
        console.log(data.token, "data");
        setTempToken(data.token);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error, "err");
        //   return rejectWithValue(error.response.data);
      }
    }
  };

  const handleAccountDeletion = async () => {
    if (selectedAccountType) {
      setLoading(true);
      try {
        const res = await asyncFetch(
          `https://germiny.dev/api/v1/${
            state.accounttype === "practitioner" ? "practitioner" : "user"
          }/delete`,
          "put"
        );
        const data = await res.data;
        if (data) {
          setLoading(false);
          setTempToken("");
        }
      } catch (error) {
        setLoading(false);
        console.log(error, "err");
        //   return rejectWithValue(error.response.data);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Germiny - Delete your Germiny account&reg;</title>
        <meta property="og:title" content="Delete Account" />
        <meta property="og:site_name" content="Germiny" />
        <meta property="og:url" content="https://germiny.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Delete your user or practitoner account"
        />

        <script type="application/ld+json">
          {organizationStructuredData({
            headline: "Redefining existence through technology.",
            authorName: "Olamide Faniyan",
            description:
              "If you want to delete your account, you need to authenticate here",
          })}
        </script>
      </Helmet>
      <Wrapper>
        <motion.div
          layout
          variants={pageTransition}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={clsx(
            classes.comingSoonContainer,
            classes.relativePosition
          )}
        >
          <Typography
            gutterBottom
            className={clsx(classes.comingSoonText, classes.mt60)}
          >
            Delete Account
          </Typography>
          <Typography
            gutterBottom
            className={clsx(
              classes.mt20,
              classes.textUpper,
              classes.lightGreyText
            )}
          >
            Verify your account deletion
          </Typography>
          {!tempToken ? (
            <form onSubmit={submitAccountDeletionForm}>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <InputField
                    type="tel"
                    name="phoneno"
                    value={state.phoneno}
                    onChange={handleChange}
                    className={clsx(classes.bgWhite)}
                    placeholder="Phone number"
                    // required
                  />
                  <InputField
                    type="password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                    className={clsx(classes.bgWhite, classes.mt20)}
                    placeholder="Password"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <div>
                    <select
                      style={{
                        fontFamily: "inherit",
                        width: 395,
                        height: 55,
                        paddingLeft: 5,
                        paddingRight: 5,
                        //   marginLeft: 5,
                      }}
                      value={selectedAccountType}
                      onChange={(e) => setSelectedAccountType(e.target.value)}
                      className={clsx(classes.bgWhite, classes.mt20)}
                      defaultValue={""}
                    >
                      <option
                        style={{ fontFamily: "inherit" }}
                        value=""
                        disabled
                      >
                        Account Type
                      </option>
                      <option
                        style={{ fontFamily: "inherit" }}
                        value="practitioner"
                      >
                        Practitioner
                      </option>
                      <option value="user">User</option>
                    </select>
                  </div>
                  <MButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Confirm Account"}
                  </MButton>
                </Grid>
              </Grid>
              {/* <Grid> */}
            </form>
          ) : (
            <div style={{ gap: 20 }}>
              <MButton
                variant="contained"
                // type="submit"
                color="primary"
                disabled={loading}
                style={{ marginRight: 20 }}
                onClick={() => setTempToken("")}
              >
                {"Cancel"}
              </MButton>
              <MButton
                variant="contained"
                // type="submit"
                style={{ backgroundColor: "red", color: "white" }}
                // color="error"
                disabled={loading}
                onClick={handleAccountDeletion}
              >
                {loading ? <Loader /> : "Delete"}
              </MButton>
            </div>
          )}
        </motion.div>
      </Wrapper>
    </>
  );
};

export default DeleteAccount;
