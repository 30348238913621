import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

import CompanyInfo from "../components/AboutComponents/CompanyInfo";
import Culture from "../components/AboutComponents/Culture";
import HeroAbout from "../components/AboutComponents/HeroAbout";
import Jobs from "../components/AboutComponents/Jobs";
import MissionVisionWrapper from "../components/AboutComponents/MissionVisionWrapper";
import NewsSection from "../components/AboutComponents/NewsSection";

import VisionSection from "../components/AboutComponents/VisionSection";
import Wrapper from "../components/Wrapper";
import { organizationStructuredData } from "../structured-data";
import { pageTransition } from "../utils/transition";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Germiny - Find out more about Germiny&reg;</title>
        <meta property="og:title" content="About Us" />
        <meta property="og:site_name" content="Germiny" />
        <meta property="og:url" content="https://germiny.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are"
        />

        <script type="application/ld+json">
          {organizationStructuredData({
            headline: "Redefining existence through technology.",
            authorName: "Arifayan Idowu",
            description:
              "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
          })}
        </script>
      </Helmet>
      <Wrapper>
        <motion.div
          layout
          variants={pageTransition}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <HeroAbout />
          <VisionSection />
          <MissionVisionWrapper />

          <Culture />

          <NewsSection />
          <CompanyInfo />
          <Jobs />
        </motion.div>
      </Wrapper>
    </>
  );
};

export default About;
