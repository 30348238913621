import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@material-ui/core";

import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressiveImage from "react-progressive-graceful-image";

import { useParams, useHistory } from "react-router-dom";
import { getTeam } from "../../features/teams/teamsSlice";
import useStyles from "../../theme/styles";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as LeftArrow } from "../../theme/assets/left-arrow.svg";

const Detail = () => {
  const classes = useStyles();
  const history = useHistory();
  const { name } = useParams();
  const dispatch = useDispatch();
  const { team } = useSelector((state) => state.teams);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getTeam(name));
  }, [name, dispatch]);

  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 60,
    ease: "easeOut",
  };

  const titleVariant = {
    hidden: {
      y: -50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };

  const contentVariant = {
    hidden: {
      y: 200,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
    exit: {
      y: -200,
      opacity: 0,
    },
  };

  const imageVariants = {
    hidden: {
      scale: 1.1,
      opacity: 0.8,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition,
    },
    exit: {
      scale: 1.1,
      opacity: 0,
      transition,
    },
  };

  return (
    <div>
      <motion.div
        layout
        key={name}
        className={clsx(classes.container, classes.leadershipHeader)}
        exit={{ opacity: 0 }}
      >
        <div className={clsx(classes.aboutHeroTitle, classes.container)}>
          <motion.div
            variants={titleVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Typography gutterBottom className={clsx(classes.teamHeader)}>
              {team?.name}
            </Typography>
          </motion.div>

          <Typography gutterBottom className={clsx(classes.boldText2)}>
            {team?.title}
          </Typography>
        </div>
      </motion.div>
      <div className={clsx(classes.mt20, classes.container, classes.mb50)}>
        <Button
          onClick={() => history.goBack()}
          variant="contained"
          startIcon={<MIcon src={LeftArrow} />}
          className={clsx(classes.btn)}
          color="primary"
        >
          Go Back
        </Button>
        <Grid
          container
          spacing={3}
          className={classes.mt20}
          direction={matches ? "column-reverse" : "row"}
        >
          <Grid item xs={12} md={7}>
            <motion.div
              variants={contentVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Typography
                className={clsx(classes.text)}
                style={{ whiteSpace: "pre-line" }}
              >
                {team?.fullBio}
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={5}>
            <ProgressiveImage
              delay={3000}
              src={team?.image}
              placeholder={team?.image}
              style={{
                backgroundColor: "#000",
                overflow: "hidden",
              }}
            >
              {(src) => (
                <div className={classes.aboutImgWrapper}>
                  <motion.img
                    key={name}
                    variants={imageVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    src={src}
                    alt={team?.name}
                    className={clsx(classes.passport4)}
                    style={{
                      objectPosition:
                        (team?.id === 2 ||
                          team?.id === 9 ||
                          team?.id === 7 ||
                          team?.id === 5 ||
                          team?.id === 8) &&
                        "center 40%",
                    }}
                    whileHover={{
                      scale: 1.1,
                    }}
                    transition={transition}
                  />
                </div>
              )}
            </ProgressiveImage>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Detail;
