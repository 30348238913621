import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import useStyles from "../../theme/styles";

const TermsComponent = () => {
  const classes = useStyles();

  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 60,
    ease: "easeOut",
  };

  const titleWrapperVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const titleVariant = {
    hidden: {
      y: -50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
    exit: {
      y: -50,
      opacity: 0,
      transition,
    },
  };

  return (
    <div>
      <div className={clsx(classes.policyContainer)}>
        <motion.div
          variants={titleWrapperVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={clsx(
            classes.aboutHeroTitle,
            classes.container,
            classes.textWhite
          )}
        >
          <motion.div variants={titleVariant}>
            <Typography
              gutterBottom
              className={clsx(classes.teamHeader2, classes.mb50)}
              align="center"
            >
              TERMS AND CONDITIONS
            </Typography>
          </motion.div>
        </motion.div>
      </div>

      <div className={classes.container}>
        <div className={classes.mb50}>
          <Typography className={classes.teamHeader} gutterBottom>
            TERMS OF USE
          </Typography>
          <Typography className={classes.boldText} gutterBottom>
            These Terms of Use (Terms) governs your use of the Germiny App and
            website.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Use of the Site
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            Company that offers Telehealth services (Primary Health Care
            Services) by linking our users with qualified healthcare
            practitioners who in turn provides medical and health services. By
            accessing and using this app, our devices or our website, you agree
            to be bound by these Terms and all other terms and policies that
            appear on the app and our website. You are not allowed to use this
            app and our devices if you do not agree with these terms. It is
            prohibited for Healthcare Professionals to use the Germiny App while
            at their jobs, or place of paid employment, any Healthcare
            Professionals who do this, do so at their own risk.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Healthcare Services
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            The Healthcare Professionals who deliver Services through Germiny
            are independent professionals. Germiny does not practice medicine or
            any other licensed profession, and does not interfere with the
            practice of medicine or any other licensed profession by Healthcare
            Professionals, each of whom is responsible for his or her services
            and compliance with the requirements applicable to his or her
            profession and license. Neither Germiny nor any third parties who
            promote the Services or provide you with a link to the Services
            shall be liable for any professional advice you obtain from a
            Healthcare Professional via the Services.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            App Content
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            None of the App content (other than information you receive from
            Healthcare Professionals) should be considered medical advice or an
            endorsement, representation or warranty that any particular
            medication or treatment is safe, appropriate, or effective for you.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            User Accounts
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            When you register on the App, you are required to create an account
            (“Account”) by entering your name, email address, password and
            certain other information collected by Germiny (collectively
            “Account Information”). To create an Account, you must be of legal
            age to form a binding contract. If you are not of legal age to form
            a binding contract, you may not register to use our Services. You
            agree that the Account Information that you provide to us at all
            times, including during registration and in any information, you
            upload to the App, will be true, accurate, current, and complete.
            You may not transfer or share your Account password with anyone or
            create more than one Account (with the exception of subaccounts
            established for children of whom you are the parent or legal
            guardian). You are responsible for maintaining the confidentiality
            of your Account password and for all activities that occur under
            your Account. Germiny reserves the right to take any and all action,
            as it deems necessary or reasonable, regarding the security of the
            App and your Account Information. In no event and under no
            circumstances shall Germiny Limited be held liable to you for any
            liabilities or damages resulting from or arising out of your use of
            the App, your use of the Account Information or your release of the
            Account Information to a third party. You may not use anyone else's
            account at any time.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Use of the Services by Children
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            The Services are available for use by children but children are not
            authorized to become Account holders and may not use the Services
            without the supervision of a parent or legal guardian. If you
            register as the parent or legal guardian on behalf of a minor, you
            will be fully responsible for complying with these Terms of Use.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Access Rights
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            We hereby grant to you a limited, non-exclusive, nontransferable
            right to access the App and use the Services solely for your
            personal non-commercial use and only as permitted under these Terms
            of Use and any separate agreements you may have entered into with us
            (“Access Rights”). We reserve the right, in our sole discretion, to
            deny or suspend use of the App, devices or Services to anyone for
            any reason. You agree that you will not, and will not attempt to:
            (a) impersonate any person or entity, or otherwise misrepresent your
            affiliation with a person or entity; (b) use the App, devices or
            Services to violate any local, state, national or international law;
            (c) reverse engineer, disassemble, decompile, or translate any
            software or other components of the App, devices or Services; (d)
            distribute viruses or other harmful computer code through the App
            and devices; or (e) otherwise use the Services, devices or App in
            any manner that exceeds the scope of use granted above. In addition,
            you agree to refrain from abusive language and behavior which could
            be regarded as inappropriate, or conduct that is unlawful or
            illegal, when communicating with Healthcare Professionals through
            the App and to refrain from contacting Healthcare Professionals for
            telehealth services (Primary Healthcare Services) outside of the
            App. Germiny is not responsible for any interactions with Healthcare
            Professionals that are not conducted through the App. We strongly
            recommend that you do not use the Services on public computers. We
            also recommend that you do not store your Account password through
            your web browser or other software.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Fees and Purchase Terms
          </Typography>
          <Typography gutterBottom className={clsx(classes.boldText)}>
            You agree to pay all fees or charges to your Account in accordance
            with the fees, charges, and billing terms in effect at the time a
            fee or charge is due and payable. By providing Germiny with your
            payment information, you agree that Germiny is authorized to
            immediately invoice your account for all fees and charges due and
            payable to Germiny hereunder and that no additional notice or
            consent is required. If your health plan, employer or agency has
            arranged with Germiny to pay the fee or any portion of the fee, or
            if the fee is pursuant to some other arrangement with Germiny, that
            fee adjustment will be reflected in the fee that you are ultimately
            charged. Please check with your employer, health plan or agency to
            determine if any Services will be reimbursed.
          </Typography>

          <Typography className={clsx(classes.boldText, classes.deepGreyText)}>
            <strong>
              You understand and agree that for Services provided on an
              appointment basis, you will be responsible for a missed
              appointment fee equal to all or a portion of the fees you would
              have paid for the scheduled services if you do not cancel a
              scheduled appointment at least 24 hours in advance, unless we
              notify you in writing that a shorter cancellation window applies.
            </strong>
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Termination
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            deactivating your account in the app or sending an email to us.
            Germiny may suspend or terminate your use of the App, your Account
            and/or registration for any reason at any time. Subject to
            applicable law, Germiny reserves the right to maintain, delete or
            destroy all communications and materials posted or uploaded to the
            App pursuant to its internal record retention and/or content
            destruction policies. After such termination, Germiny will have no
            further obligation to provide the Services, except to the extent we
            are obligated to provide you access to your health records or
            Healthcare Professionals are required to provide you with continuing
            care under their applicable legal, ethical and professional
            obligations to you.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Right to Modify
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            We may at our sole discretion change, add, or delete portions of
            these Terms of Use at any time on a going-forward basis. Continued
            use of the App, devices and/or Services following notice of any such
            changes will indicate your acknowledgement of such changes and
            agreement to be bound by the revised Terms of Use, inclusive of such
            changes.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            DISCLAIMER OF WARRANTIES
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            YOU EXPRESSLY AGREE THAT USE OF THE APP, DEVICES OR SERVICES IS AT
            YOUR SOLE RISK. THE APP, DEVICES AND SERVICES ARE PROVIDED ON AN "AS
            IS" AND "AS AVAILABLE" BASIS. GERMINY EXPRESSLY DISCLAIMS ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR USE OR PURPOSE, NON-INFRINGEMENT, TITLE, OPERABILITY,
            CONDITION, QUIET ENJOYMENT, VALUE, ACCURACY OF DATA AND SYSTEM
            INTEGRATION.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            LIMITATION OF LIABILITY
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN
            NO EVENT WILL GERMINY, GERMINY HEALTHCARE PROFESSIONALS OR THEIR
            OFFICERS, EMPLOYEES, DIRECTORS, PARENTS, SUBSIDIARIES, AFFILIATES,
            AGENTS OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO,
            DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER
            INTANGIBLE LOSSES ARISING OUT OF OR RELATED TO YOUR USE OF THE APP,
            DEVICES OR THE SERVICES, REGARDLESS OF WHETHER SUCH DAMAGES ARE
            BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY),
            WARRANTY, STATUTE OR OTHERWISE.
          </Typography>

          <Typography className={clsx(classes.boldText, classes.deepGreyText)}>
            <strong>
              To the extent that we may not, as a matter of applicable law,
              disclaim any implied warranty or limit its liabilities, the scope
              and duration of such warranty and the extent of our liability will
              be the minimum permitted under such applicable law.
            </strong>
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Indemnification
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            You agree to indemnify, defend and hold harmless Germiny
            Practitioners and their officers, directors, employees, agents,
            subsidiaries, affiliates, licensors, and suppliers, harmless from
            and against any claim, actions, demands, liabilities and
            settlements, including without limitation reasonable legal and
            accounting fees (“Claims”), resulting from, or alleged to result
            from, your violation of these Terms of Use.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Geographical Restrictions
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            Germiny makes no representation that all products, services and/or
            material described on the App, Devices or the Services available
            through the App, are appropriate or available for use in locations
            outside the Federal Republic of Nigeria.
          </Typography>
        </div>
        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Disclosures
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            licenses issued by the professional licensing boards or agencies in
            the states where they practice. All physicians and psychologists
            hold advanced degrees in either medicine or psychology and have
            undergone postgraduate training. You can report a complaint relating
            to the care provided by a Healthcare Professional by contacting the
            professional licensing board in the state where the care was
            received. In a professional relationship, sexual intimacy is never
            appropriate and should be reported to the board or agency that
            licenses, registers, or certifies the licensee. Any clinical records
            created as a result of your use of the Services will be securely
            maintained by Germiny on behalf of Health Professionals for a period
            that is no less than the minimum number of years such records are
            required to be maintained under relevant laws and regulations.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Web Links
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            WE WILL NOT BE LIABLE FOR ANY INFORMATION, SOFTWARE, OR LINKS FOUND
            AT ANY OTHER APP, WEBSITE, INTERNET LOCATION, OR SOURCE OF
            INFORMATION, NOR FOR YOUR USE OF SUCH INFORMATION, SOFTWARE OR
            LINKS, NOR FOR THE ACTS OR OMISSIONS OF ANY SUCH PLATFORMS OR THEIR
            RESPECTIVE OPERATORS.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Ownership
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            The App and Devices and their entire contents, features and
            functionality (including but not limited to all information,
            software, text, displays, images, video and audio, and the design,
            selection and arrangement thereof), are owned by Germiny, its
            licensors or other providers of such material and are protected by
            Nigerian and international copyright, trademark, patent, trade
            secret and other intellectual property or proprietary rights laws.
            These Terms of Use permit you to use the App and Devices for your
            personal, non-commercial use only. You must not reproduce,
            distribute, modify, create derivative works of, publicly display,
            publicly perform, republish, download, store or transmit any of the
            material on our App except as generally and ordinarily permitted
            through the App according to these Terms of Use. You must not access
            or use for any commercial purposes any part of the App or any
            services or materials available through the App.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Trademarks
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            Certain of the names, logos, and other materials displayed on the
            App may constitute trademarks, trade names, service marks or logos
            ("Marks") of Germiny or other entities. You are not authorized to
            use any such Marks without the express written permission of
            Germiny. Ownership of all such Marks and the goodwill associated
            therewith remains with us or those other entities.
          </Typography>
        </div>
        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Privacy and Communications
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            Germiny is required to comply with relevant healthcare privacy and
            security laws and maintain safeguards to protect the security of
            your health information. Additionally, the information you provide
            to your Healthcare Professional during a medical consultation or
            therapy session is legally confidential, except for certain legal
            exceptions as more fully described in our Privacy Policy. We devote
            considerable effort toward ensuring that your personal information
            is secure. Information regarding our use of health and other
            personal information is provided in our Privacy Policy. As part of
            providing you with the Services, we may need to provide you with
            certain communications such as appointment reminders, service
            announcements, privacy notices, administrative messages and other
            communications about the Services (“Communications”). These
            Communications are considered part of the Services and your Account.
            You understand that by checking the “agree” box for these Terms of
            Use and/or any other forms presented to you on the App you are
            agreeing to these Terms of Use and that such action constitutes a
            legal signature. You agree that we may send to you Communications
            through electronic means including but not limited to: <br />
            <br />
            (1) by email, using the address that you provided to us during
            registration, <br /> (2) short messaging service (“SMS”) text
            message to the mobile number you provided us during registration
            (“SMS Messages”), <br />
            (3) push notifications on your tablet or mobile device, or <br />
            (4) by posting Communications on the App. <br />
            <br /> The delivery of any Communications from us is effective when
            sent by us, regardless of whether you read the Communication. You
            can withdraw your consent to receive Communications by deactivating
            your Account. While secure electronic messaging is always preferred
            to insecure email, under certain circumstances, insecure email
            communication containing personal health information may take place
            between you and Germiny Germiny cannot ensure the security or
            confidentiality of messages sent by email. Information relating to
            your care, including clinical notes and medical records, are stored
            on secure, encrypted servers maintained by Germiny. The number of
            SMS Messages we send as part of our Communications will depend on
            the frequency of your use of the Services. You can stop receiving
            SMS Messages at any time by unsubscribing in the App.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography
            className={clsx(classes.teamHeader, classes.capitalize)}
            gutterBottom
          >
            Miscellaneous
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            These Terms of Use and your use of the App shall be governed by the
            laws of the Federal Republic of Nigeria. ANY DISPUTE ARISING UNDER
            OR RELATING IN ANY WAY TO THESE TERMS OF USE WILL BE RESOLVED
            EXCLUSIVELY BY FINAL AND BINDING ARBITRATION IN LAGOS, NIGERIA UNDER
            THE RULES OF THE LAGOS COURT OF ARBITRATION, EXCEPT THAT EITHER
            PARTY MAY BRING A CLAIM RELATED TO INTELLECTUAL PROPERTY RIGHTS, OR
            SEEK TEMPORARY AND PRELIMINARY SPECIFIC PERFORMANCE AND INJUNCTIVE
            RELIEF, IN ANY COURT OF COMPETENT JURISDICTION, WITHOUT THE POSTING
            OF BOND OR OTHER SECURITY. ALL CLAIMS, WHETHER IN ARBITRATION OR
            OTHERWISE, MUST BE BROUGHT SOLELY IN YOUR INDIVIDUAL CAPACITY, AND
            NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            COLLECTIVE PROCEEDING. No waiver by Germiny of any term or condition
            set forth in these Terms of Use shall be deemed a further or
            continuing waiver of such term or condition or a waiver of any other
            term or condition, and any failure of Germiny to assert a right or
            provision under these Terms of Use shall not constitute a waiver of
            such right or provision. If any provision of these Terms of Use is
            held by a court or other tribunal of competent jurisdiction to be
            invalid, illegal or unenforceable for any reason, such provision
            shall be eliminated or limited to the minimum extent such that the
            remaining provisions of the Terms of Use will continue in full force
            and effect. Germiny devotes considerable effort to optimizing signal
            strength and diagnosis deficiencies but is not responsible for the
            internet or data bandwidth and signal of your mobile device.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TermsComponent;
