import { useState } from "react";
import {
  Typography,
  Grid,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import useStyles from "../../theme/styles";
import InputField from "../Fields/InputField";
import MButton from "../Reusables/MButton";
import useIntersection from "../../hooks/useIntersection";
import { newsLetterLogic } from "../../features/info/infoSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "../Reusables/Loader";

const initialState = {
  email: "",
};

const Newsletter = () => {
  const classes = useStyles();
  const [inView, ref] = useIntersection();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.info);
  const [state, setState] = useState(initialState);

  const leftVariants = {
    hidden: {
      y: -150,
    },
    visible: (inView) => ({
      y: inView ? 0 : -150,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    }),
  };
  const rightVariants = {
    hidden: {
      y: 150,
    },
    visible: (inView) => ({
      y: inView ? 0 : 150,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    }),
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(newsLetterLogic(state))
      .then(unwrapResult)
      .then((doc) => {
        Swal.fire({
          title: "Success",
          text: doc?.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            setState(initialState);
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "",
          text: err?.error,
          icon: "warning",
        }).then((result) => {
          if (result.isConfirmed) {
            setState(initialState);
          }
        });
      });
  };

  return (
    <div
      ref={ref}
      className={clsx(classes.overflowHidden, classes.newsContainer)}
    >
      <Grid container justify="space-between">
        <Grid item xs={12} md={6}>
          <div
            className={clsx(
              classes.container,
              classes.newsletterContainer,
              classes.relativePosition
            )}
          >
            <motion.p
              variants={leftVariants}
              initial="hidden"
              animate="visible"
              custom={inView}
              className={clsx(classes.newsLogoAbsLeft)}
              style={{
                rotate: "90deg",
              }}
            >
              Germiny
            </motion.p>
            <div className={clsx(classes.pr20)}>
              <Typography className={clsx(classes.newsHeader)} gutterBottom>
                Newsletter{" "}
              </Typography>
              <Typography
                className={clsx(classes.text, classes.mb20)}
                gutterBottom
              >
                Subscribe to our newsletter and stay up to date on all the
                latest posts, updates and product releases.
              </Typography>
              <form onSubmit={handleSubmit}>
                <InputField
                  type="email"
                  placeholder="youremail@example.com"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  required
                />
                <motion.div
                  className={classes.fitContent}
                  whileTap={{
                    scale: 0.8,
                  }}
                >
                  <MButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    extraStyle={classes.btn2}
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Subscribe"}
                  </MButton>
                </motion.div>
              </form>
            </div>
          </div>
        </Grid>
        <Grid item md={6} className={clsx(classes.newsBg)}>
          <div className={clsx(classes.pt70, classes.relativePosition)}>
            <motion.p
              custom={inView}
              initial="hidden"
              animate="visible"
              variants={rightVariants}
              style={{
                rotate: "90deg",
              }}
              className={clsx(classes.newsLogoAbsRight)}
            >
              Germiny
            </motion.p>
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Newsletter;
