import { useState } from "react";
import {
  useTransform,
  useMotionTemplate,
  motion,
  useViewportScroll,
} from "framer-motion";
import useStyles from "../theme/styles";
import Footer from "./Footer";
import DropdownMenu from "./Menus/DropdownMenu";
import Navbar from "./Menus/Navbar";
import SignupDropdown from "./Menus/SignupDropdown";

const Wrapper = ({ children }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdown2, setOpenDropdown2] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const classes = useStyles();

  const { scrollYProgress } = useViewportScroll();
  const width = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const progress = useMotionTemplate`${width}`;

  const handleClick = (event) => {
    setOpenDropdown(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenDropdown(false);
    setAnchorEl(null);
  };
  const handleOpenSignup = (event) => {
    setOpenDropdown2(true);
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setOpenDropdown2(false);
    setAnchorEl2(null);
  };

  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    height: 3,
    zIndex: 9999999999999,
    background: "rgb(230 46 131)",
    width: "100%",
    scaleX: progress,
    originX: 0,
  };
  const transition = {
    type: "spring",
    stiffness: 120,
    damping: 60,
  };

  return (
    <>
      <motion.div
        style={style}
        initial={{
          originX: 0,
        }}
        transition={transition}
      />
      <div className={classes.relativePosition}>
        <Navbar
          {...{ handleClick, openDropdown, handleOpenSignup, openDropdown2 }}
        />
        <DropdownMenu {...{ anchorEl, handleClose, openDropdown }} />
        <SignupDropdown
          {...{ anchorEl: anchorEl2, handleClose: handleClose2, openDropdown2 }}
        />
        <div className={classes.content}>
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Wrapper;
