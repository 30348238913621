import { TextField, InputAdornment } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

const InputField = ({ isError, iconProps, ...rest }) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      {...rest}
      InputProps={{
        endAdornment: isError ? (
          <InputAdornment position="end">
            <Warning color="error" />
          </InputAdornment>
        ) : (
          iconProps
        ),
      }}
    />
  );
};

export default InputField;
