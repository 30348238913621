import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Divider,
  MenuItem,
  IconButton,
  Backdrop,
  CircularProgress,
  Hidden,
  useTheme,
  useMediaQuery,
  Tooltip,
  withStyles,
  Fade,
} from "@material-ui/core";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import moment from "moment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Swal from "sweetalert2";
import useStyles from "../../theme/styles";
import InputField from "../Fields/InputField";
import MButton from "../Reusables/MButton";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as Stethoscope } from "../../theme/assets/stethoscope.svg";
import { ReactComponent as ChevronRight } from "../../theme/assets/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../../theme/assets/chevron-left.svg";
import { ReactComponent as UploadIcon } from "../../theme/assets/upload.svg";
import { TabPanel, a11yProps } from "../Reusables/TabPanel";
import SelectField from "../Fields/SelectField";
import DatePickerField from "../Fields/DatePickerField";
import SafetyModal from "../Modals/SafetyModal";
import Loader from "../Reusables/Loader";
import AppButtons from "../Reusables/AppButtons";
import InfoModal from "../Modals/InfoModal";
import Feedback from "../Reusables/Feedback";

import {
  createUser,
  uploadDoc,
  resetUserState,
  resetLoader,
  cancelRequest,
} from "../../features/practitioners/practSlice";

const initState = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  gender: "",
  field: "",
  licenseNo: "",
  yearOfExp: 0,
  practclass: "Practitioner",
  channel: "website",
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 11,
    borderRadius: 0,
    padding: 24,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip);

const PasswordRefField = React.forwardRef((props, ref) => (
  <InputField {...props} ref={ref} />
));

const PractionerForm = () => {
  const classes = useStyles();
  const ref = useRef();
  const mounted = useRef(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [dob, setDob] = useState(new Date());
  const [yearOfGrad, setYearOfGrad] = useState(new Date());
  const [yearOfHouse, setYearOfHouse] = useState(new Date());
  const [licenseExpire, setLicenseExpire] = useState(new Date());
  const [state, setState] = useState(initState);
  const [isPositive, setIsPositive] = useState(false);
  const [nationalId, setNationalId] = useState("");
  const [medLicense, setMedLicense] = useState("");
  const [eduCert, setEduCert] = useState("");
  const [resume, setResume] = useState("");
  const [photo, setPhoto] = useState("");
  const [isIdSuccess, setIsIdSuccess] = useState(false);
  const [isMedSuccess, setIsMedSuccess] = useState(false);
  const [isEduSuccess, setIsEduSuccess] = useState(false);
  const [isResSuccess, setIsResSuccess] = useState(false);
  const [isPhotoSuccess, setIsPhotoSuccess] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passSuccess, setPassSuccess] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [fileLists, setFileLists] = useState({
    id: null,
    license: null,
    certificate: null,
    resume: null,
    photo: null,
  });

  const { token, loading, isNew } = useSelector((state) => state.users);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fileListsCheck = useCallback(() => {
    return (
      (fileLists.certificate &&
        fileLists.id &&
        fileLists.license &&
        fileLists.resume &&
        fileLists.photo) !== null
    );
  }, [fileLists]);

  const fileUploadFunc = useCallback(
    (file, type, state, msg) => {
      if (file && token) {
        const formData = new FormData();
        formData.append("doc", file);
        let data = {
          type,
          token,
          formData,
        };
        dispatch(uploadDoc(data))
          .then(unwrapResult)
          .then((res) => {
            if (res) {
              if (!mounted.current) return null;
              state(true);
              setFileLists((prev) => ({
                ...prev,
                [type]: res,
              }));
              Swal.fire({
                title: "Success",
                text: msg,
                icon: "success",
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Error!",
              text: err?.error || err,
              icon: "error",
            });
            state(false);
          });
      }
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (matches) {
      setOpenInfo(true);
    } else {
      setOpenInfo(false);
    }
  }, [matches]);

  useEffect(() => {
    let timeout;
    if (fileListsCheck()) {
      dispatch(resetUserState());

      Swal.fire({
        title: "Success",
        text: `Congratulations!!!, you have completed the final step of your registration. Your credentials will now undergo processing. Thank you for registering with us.`,
        icon: "success",
      });
      timeout = setTimeout(() => {
        setPage((prev) => (prev = 0));
        setState(initState);
        setDob(new Date());
        setYearOfGrad(new Date());
        setYearOfHouse(new Date());
        setLicenseExpire(new Date());
        setNationalId("");
        setMedLicense("");
        setEduCert("");
        setResume("");
        setPhoto("");
        setFileLists({
          id: null,
          license: null,
          certificate: null,
          resume: null,
          photo: null,
        });
      }, 6000);
    }
    return () => clearTimeout(timeout);
  }, [fileListsCheck, dispatch]);

  useEffect(() => {
    if (isNew) {
      setPage((prev) => (prev = 3));
    }
  }, [isNew, dispatch]);

  useEffect(() => {
    fileUploadFunc(
      nationalId,
      "id",
      setIsIdSuccess,
      "Your valid means of identification was uploaded successfully."
    );
    return () => {
      if (!mounted.current) {
        dispatch(resetLoader());
        cancelRequest();
      }
    };
  }, [nationalId, fileUploadFunc, dispatch]);

  useEffect(() => {
    fileUploadFunc(
      medLicense,
      "license",
      setIsMedSuccess,
      "Your Medical License was uploaded successfully."
    );
    return () => {
      if (!mounted.current) {
        dispatch(resetLoader());
        return cancelRequest();
      }
    };
  }, [medLicense, fileUploadFunc, dispatch]);

  useEffect(() => {
    fileUploadFunc(
      eduCert,
      "certificate",
      setIsEduSuccess,
      "Your Education certificate was uploaded successfully."
    );
    return () => {
      if (!mounted.current) {
        dispatch(resetLoader());
        return cancelRequest();
      }
    };
  }, [eduCert, fileUploadFunc, dispatch]);

  useEffect(() => {
    fileUploadFunc(
      resume,
      "resume",
      setIsResSuccess,
      "Your Resume was uploaded successfully."
    );
    return () => {
      if (!mounted.current) {
        dispatch(resetLoader());
        return cancelRequest();
      }
    };
  }, [resume, fileUploadFunc, dispatch]);

  useEffect(() => {
    fileUploadFunc(
      photo,
      "photo",
      setIsPhotoSuccess,
      "Your Passport photograph was uploaded successfully."
    );
    return () => {
      if (!mounted.current) {
        dispatch(resetLoader());
        return cancelRequest();
      }
    };
  }, [photo, fileUploadFunc, dispatch]);

  useEffect(() => {
    const policyCheck = JSON.parse(localStorage.getItem("germiny:policy"));

    let pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );
    let lowercaseCheck = new RegExp("(?=.*[a-z])");
    let uppercaseCheck = new RegExp("(?=.*[A-Z])");
    let numberCheck = new RegExp("(?=.*\\d)");
    let specialCheck = new RegExp("(?=.*[-+_!@#$%^&*., ?])");

    const timeout = setTimeout(() => {
      if (policyCheck) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }, 2000);

    if (page > 2) {
      setDisable(true);
      return;
    } else {
      setDisable(false);
    }

    if (lowercaseCheck.test(state.password)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
    if (uppercaseCheck.test(state.password)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
    if (numberCheck.test(state.password)) {
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }
    if (specialCheck.test(state.password)) {
      setIsSpecial(true);
    } else {
      setIsSpecial(false);
    }

    if (state.password.length > 8 && pattern.test(state.password)) {
      setPassSuccess(true);
    } else {
      setPassSuccess(false);
    }

    return () => clearTimeout(timeout);
  }, [token, page, state.password]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleCancelRequest = () => {
    dispatch(resetLoader());
    cancelRequest();
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeFile = (e, cb) => {
    const file = e.target.files[0];
    const size = file?.size / 1024 / 1024;
    if (size && size > 1) {
      Swal.fire({
        title: "Failed file upload",
        text: "Your file is too large (limit is 1MiB).",
        icon: "error",
      });
      return;
    }

    cb(file);
  };

  const overlayVariant = {
    hidden: {
      x: -350,
      opacity: 0.8,
    },
    visible: {
      x: -45,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
      },
    },
    exit: {
      x: -350,
      opacity: 0.8,
    },
  };

  const tabsVariant = {
    hidden: (isPositive) => ({
      x: isPositive ? -50 : 50,
      opacity: 0,
    }),
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeIn",
      },
    },
    exit: {
      x: 0,
      opacity: 0,
    },
  };

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
    if (newValue > page) {
      setIsPositive(true);
    } else {
      setIsPositive(false);
    }
  };

  const handleNext = () => {
    setPage((prev) => prev + 1);
    setIsPositive(true);
  };
  const handlePrev = () => {
    setPage((prev) => prev - 1);
    setIsPositive(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      ...state,
      dob: moment(dob).format("LLLL"),
      yearOfHouse: new Date(yearOfHouse).getFullYear(),
      yearOfGrad: new Date(yearOfGrad).getFullYear(),
      licenseExpire: moment(licenseExpire).format("LLLL"),
    };
    if (state.password !== state.confirmPassword) {
      Swal.fire({
        title: "Error!",
        text: "Password's do not match",
        icon: "error",
      });
      return;
    }

    Swal.fire({
      title: "Complete Registration",
      text: "By clicking 'Yes', your data will be submitted and you'll be moved to document upload. Please note that you will not be able to go to the previous tabs. Are you sure you want to proceed?",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "black",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(createUser(data))
          .then(unwrapResult)
          .then((user) => {
            Swal.fire({
              title: "Success",
              text: `You have completed the first step of your registration. An email will be sent to ${user?.data?.email}, once the final step is completed.`,
              icon: "success",
            });
            setPage((page) => (page = 3));
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: err?.error,
              icon: "error",
            });
          });
      }
    });
  };

  const validCheck = () =>
    !state.firstname ||
    !state.lastname ||
    !state.email ||
    !state.phone ||
    !state.password ||
    !state.gender ||
    !state.field ||
    !state.licenseNo ||
    state.yearOfExp === 0 ||
    dob === new Date();

  return (
    <>
      <AnimatePresence initial={false} key="form">
        <motion.div key="form--1">
          <SafetyModal {...{ open, handleClose }} />
          <InfoModal
            {...{ open: openInfo, handleClose: handleCloseInfo, classes }}
          />
        </motion.div>
        <motion.div className={clsx(classes.practionerFormContainer)}>
          <Grid container component={Paper} elevation={0} square>
            <Grid
              item
              xs={12}
              md={5}
              className={clsx(classes.imgWrapper)}
              ref={ref}
            >
              <motion.div
                variants={overlayVariant}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={classes.practImgOverlayBlack}
                drag="x"
                dragConstraints={{ left: -350, right: -45, bottom: 0, top: 0 }}
                style={{
                  x: -45,
                  height: "100%",
                }}
              >
                <Hidden smDown>
                  <div className={clsx(classes.stepsContainer)}>
                    <Typography
                      gutterBottom
                      style={{ width: 400 }}
                      className={classes.mt20}
                    >
                      Before you commence your registration, ensure you have the
                      following documents:
                    </Typography>
                    <Paper
                      className={clsx(
                        classes.paperBorder,
                        classes.paperTransparent,
                        classes.textWhite
                      )}
                      style={{
                        width: 350,
                      }}
                      square
                    >
                      <ul>
                        <li>Valid National Identification</li>
                        <li>Education Certificate</li>
                        <li>Medical License</li>
                        <li>Resume/CV</li>
                      </ul>
                    </Paper>
                    <div className={classes.mt20}>
                      <Typography gutterBottom>Steps:</Typography>
                      <Typography gutterBottom>
                        1. Register (step 1 - 3)
                      </Typography>
                      <Typography gutterBottom>
                        2. Upload documents (step 4)
                      </Typography>
                      <>
                        <Typography gutterBottom>
                          3. Download the mobile app
                        </Typography>
                        <AppButtons {...{ clsx, classes }} user="pract" />
                      </>
                      <Typography gutterBottom>
                        4. Login with your phone number & password
                      </Typography>
                    </div>
                  </div>
                </Hidden>
                <div className={classes.holder}></div>
              </motion.div>

              <motion.img
                src="/img/small/stethoscope.jpeg"
                alt="stethoscope"
                className={classes.practFormImg1}
                whileHover={{ scale: 1.09 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              className={clsx(classes.practFormWrapper)}
            >
              <div className={clsx(classes.mb20)}>
                <Typography className={clsx(classes.lightGreyText)}>
                  Start Your Journey with Germiny Here
                </Typography>
                <Typography
                  gutterBottom
                  className={clsx(classes.teamHeader, classes.grid)}
                >
                  Practitioner Form
                  <MIcon src={Stethoscope} className={classes.ml10} />
                </Typography>
              </div>
              <form onSubmit={handleSubmit}>
                <Paper
                  square
                  elevation={0}
                  className={clsx(classes.heroContentPaper)}
                >
                  <Tabs
                    value={page}
                    onChange={handleChangePage}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    variant="fullWidth"
                  >
                    <Tab label="Step 1" {...a11yProps(0)} disabled={disable} />
                    <Tab label="Step 2" {...a11yProps(1)} disabled={disable} />
                    <Tab label="Step 3" {...a11yProps(2)} disabled={disable} />
                    {!disable ? (
                      <LightTooltip
                        arrow
                        title={
                          <Typography>Kindly complete steps 1 - 3</Typography>
                        }
                        placement="top"
                        open={openTooltip}
                        onClose={() => setOpenTooltip(false)}
                        onOpen={() => setOpenTooltip(true)}
                      >
                        <span>
                          <Tab label="Step 4" {...a11yProps(3)} disabled />
                        </span>
                      </LightTooltip>
                    ) : (
                      <Tab label="Step 4" {...a11yProps(3)} />
                    )}
                  </Tabs>
                  <Divider />

                  <TabPanel value={page} index={0}>
                    <motion.div
                      variants={tabsVariant}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      custom={isPositive}
                    >
                      <InputField
                        required
                        placeholder="Firstname"
                        label="Firstname"
                        className={classes.textField}
                        name="firstname"
                        value={state.firstname}
                        onChange={handleChange}
                      />
                      <InputField
                        required
                        placeholder="Lastname"
                        label="Lastname"
                        className={classes.textField}
                        name="lastname"
                        value={state.lastname}
                        onChange={handleChange}
                      />

                      <InputField
                        required
                        error={state.phone.length <= 10}
                        isError={state.phone.length <= 10}
                        placeholder="Phone"
                        label="Phone"
                        type="tel"
                        className={classes.textField}
                        name="phone"
                        value={state.phone}
                        color={
                          state.phone.length > 10 ? "secondary" : "primary"
                        }
                        onChange={handleChange}
                        helperText={
                          state.phone.length <= 10
                            ? "Must not be less than 10 characters"
                            : ""
                        }
                      />

                      <InputField
                        required
                        type="email"
                        placeholder="Email"
                        label="Email"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                        className={classes.textField}
                      />

                      <DatePickerField
                        disableFuture
                        openTo="year"
                        format="dd/MM/yyyy"
                        views={["year", "month", "date"]}
                        value={dob}
                        onChange={setDob}
                        label="Date Of Birth"
                        className={classes.textField}
                        required
                      />
                      <SelectField
                        name="gender"
                        value={state.gender}
                        onChange={handleChange}
                        label="Gender"
                        className={classes.textField}
                        required
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </SelectField>
                    </motion.div>
                  </TabPanel>
                  <TabPanel value={page} index={1}>
                    <motion.div
                      variants={tabsVariant}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      custom={isPositive}
                    >
                      <SelectField
                        label="Field of study"
                        name="field"
                        value={state.field}
                        onChange={handleChange}
                        className={classes.textField}
                        required
                      >
                        <MenuItem value="optometrist">Optometrist</MenuItem>
                        <MenuItem value="optician">Optician</MenuItem>
                        <MenuItem value="pediatrician">Pediatrician</MenuItem>
                        <MenuItem value="physiotherapist">
                          Physiotherapist
                        </MenuItem>
                        <MenuItem value="lab-scientist">Lab Scientist</MenuItem>
                        <MenuItem value="nutritionist">Nutritionist</MenuItem>
                        <MenuItem value="clinical-psychologist">
                          Clinical Psychologist
                        </MenuItem>
                        <MenuItem value="nurse-midwife">Nurse/Midwife</MenuItem>
                        <MenuItem value="general-practitioner">
                          General Practitioner
                        </MenuItem>
                        <MenuItem value="dentist">Dentist</MenuItem>
                      </SelectField>
                      <DatePickerField
                        disableFuture
                        openTo="year"
                        format="yyyy"
                        views={["year"]}
                        value={yearOfGrad}
                        onChange={setYearOfGrad}
                        label="Year of Graduation"
                        className={classes.textField}
                        required
                      />
                      <DatePickerField
                        disableFuture
                        openTo="year"
                        format="yyyy"
                        views={["year"]}
                        value={yearOfHouse}
                        onChange={setYearOfHouse}
                        label="Year of Housemanship"
                        className={classes.textField}
                        required
                      />
                      <InputField
                        required
                        type="number"
                        label="Years of Experience"
                        placeholder="-"
                        name="yearOfExp"
                        value={state.yearOfExp}
                        onChange={handleChange}
                        className={classes.textField}
                      />
                      <InputField
                        required
                        placeholder="Licence Number"
                        label="Licence Number"
                        className={classes.textField}
                        name="licenseNo"
                        value={state.licenseNo}
                        onChange={handleChange}
                      />
                      <DatePickerField
                        openTo="year"
                        format="dd/MM/yyyy"
                        views={["year", "month", "date"]}
                        value={licenseExpire}
                        onChange={setLicenseExpire}
                        label="Licence Expiry Date"
                        className={classes.textField}
                        required
                      />
                    </motion.div>
                  </TabPanel>
                  <TabPanel value={page} index={2}>
                    <motion.div
                      variants={tabsVariant}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      custom={isPositive}
                    >
                      <>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item></Grid>
                          <Grid item>
                            <div className={classes.boxFlex}>
                              <div
                                className={clsx(classes.box, {
                                  [classes.borderSuccess]: isUpperCase,
                                })}
                              >
                                A
                              </div>
                              <div
                                className={clsx(classes.box, {
                                  [classes.borderSuccess]: isLowerCase,
                                })}
                              >
                                a
                              </div>

                              <div
                                className={clsx(classes.box, {
                                  [classes.borderSuccess]: isNumber,
                                })}
                              >
                                1
                              </div>
                              <div
                                className={clsx(classes.box, {
                                  [classes.borderSuccess]: isSpecial,
                                })}
                              >
                                @
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <LightTooltip
                          arrow
                          placement="left"
                          TransitionComponent={Fade}
                          title={
                            <>
                              <Typography gutterBottom>
                                Password requirements:
                              </Typography>
                              <Typography>
                                &bull; <span>8 or more characters</span>
                              </Typography>
                              <Typography>
                                &bull; Has uppercase, lowercase, numeric &
                                symbol
                              </Typography>
                              <Typography>
                                &bull; Not common (e.g., password)
                              </Typography>
                            </>
                          }
                        >
                          <div>
                            <PasswordRefField
                              required
                              error={!passSuccess}
                              type={showPassword ? "text" : "password"}
                              placeholder="Password (minimum 8 characters)"
                              label="Password"
                              name="password"
                              value={state.password}
                              onChange={handleChange}
                              color={passSuccess ? "secondary" : "primary"}
                              iconProps={
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowPassword((prev) => !prev)
                                  }
                                  onMouseDown={() =>
                                    setShowPassword((prev) => !prev)
                                  }
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              }
                            />
                          </div>
                        </LightTooltip>

                        <div
                          className={clsx(
                            classes.flexWrapper,
                            classes.textField
                          )}
                        >
                          <div>
                            <small>Uppercase</small>
                            <motion.div
                              className={clsx(classes.errorCheck, {
                                [classes.successCheck]: isUpperCase,
                              })}
                              transition={{
                                type: "spring",
                                stiffness: 200,
                                damping: 30,
                                ease: "easeIn",
                              }}
                              animate={{
                                y: isUpperCase ? 0 : -1.5,
                              }}
                            />
                          </div>
                          <div className={clsx(classes.ml10)}>
                            <small>Lowercase</small>
                            <motion.div
                              className={clsx(classes.errorCheck, {
                                [classes.successCheck]: isLowerCase,
                              })}
                              transition={{
                                type: "spring",
                                stiffness: 200,
                                damping: 30,
                                ease: "easeIn",
                              }}
                              animate={{
                                y: isLowerCase ? 0 : -1.5,
                              }}
                            />
                          </div>
                          <div className={clsx(classes.ml10)}>
                            <small>Numeric</small>
                            <motion.div
                              className={clsx(classes.errorCheck, {
                                [classes.successCheck]: isNumber,
                              })}
                              transition={{
                                type: "spring",
                                stiffness: 200,
                                damping: 30,
                                ease: "easeIn",
                              }}
                              animate={{
                                y: isNumber ? 0 : -1.5,
                              }}
                            />
                          </div>
                          <div className={clsx(classes.ml10)}>
                            <small>Special Character</small>
                            <motion.div
                              className={clsx(classes.errorCheck, {
                                [classes.successCheck]: isSpecial,
                              })}
                              transition={{
                                type: "spring",
                                stiffness: 200,
                                damping: 30,
                                ease: "easeIn",
                              }}
                              animate={{
                                y: isSpecial ? 0 : -1.5,
                              }}
                            />
                          </div>
                        </div>
                      </>
                      <InputField
                        required
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Re-type Password"
                        label="Re-type Password"
                        name="confirmPassword"
                        value={state.confirmPassword}
                        onChange={handleChange}
                        className={classes.textField}
                        iconProps={
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowConfirmPassword((prev) => !prev)
                            }
                            onMouseDown={() =>
                              setShowConfirmPassword((prev) => !prev)
                            }
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        }
                      />
                      <Grid container justify="center">
                        <Grid item>
                          <MButton
                            color="primary"
                            variant="contained"
                            endIcon={
                              loading ? null : <MIcon src={Stethoscope} />
                            }
                            type="submit"
                            className={classes.btn}
                            size="large"
                            disabled={validCheck() || loading}
                          >
                            {loading ? <Loader /> : "Register"}
                          </MButton>
                        </Grid>
                      </Grid>
                    </motion.div>
                  </TabPanel>
                  <TabPanel value={page} index={3}>
                    <motion.div
                      variants={tabsVariant}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      custom={isPositive}
                    >
                      {fileListsCheck() ? (
                        <Typography
                          color="secondary"
                          variant="body1"
                          gutterBottom
                          style={{ lineHeight: 1 }}
                        >
                          All documents have been uploaded successfully, click{" "}
                          <Link to="/details/products">link</Link> to go back.
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            color="error"
                            variant="body1"
                            style={{ lineHeight: 1 }}
                            gutterBottom
                          >
                            * All documents are required to be uploaded
                          </Typography>
                          <Typography
                            color="error"
                            gutterBottom
                            style={{ lineHeight: 1 }}
                          >
                            * Accepted file formats & Size limit: (.pdf, .doc,
                            .docx, .jpg, .jpeg ,.png) - 1Mb
                          </Typography>
                        </>
                      )}
                      <div className={classes.mb20}>
                        <Typography gutterBottom>
                          Valid means of Identification
                        </Typography>
                        <label
                          className={clsx(classes.labelBtnOutlined)}
                          htmlFor="passport"
                          style={{
                            borderColor: isIdSuccess ? "green" : "black",
                          }}
                        >
                          <MIcon
                            fontSize="small"
                            src={UploadIcon}
                            viewBox="0 0 330 330"
                          />
                        </label>
                        <small style={{ display: "block" }}>
                          Acceptable documents: International Passport, National
                          ID, Voters Card, Driver's License
                        </small>

                        <input
                          id="passport"
                          type="file"
                          accept=".doc,.docx,.png,.pdf,.jpg,.jpeg"
                          className={classes.dNone}
                          onChange={(e) => handleChangeFile(e, setNationalId)}
                        />
                      </div>
                      <div className={classes.mb20}>
                        <Typography gutterBottom>Medical License</Typography>
                        <label
                          className={clsx(classes.labelBtnOutlined)}
                          htmlFor="license"
                          style={{
                            borderColor: isMedSuccess ? "green" : "black",
                          }}
                        >
                          <MIcon
                            fontSize="small"
                            src={UploadIcon}
                            viewBox="0 0 330 330"
                          />
                        </label>
                        <input
                          id="license"
                          type="file"
                          accept=".doc,.docx,.png,.pdf,.jpg,.jpeg"
                          className={classes.dNone}
                          onChange={(e) => handleChangeFile(e, setMedLicense)}
                        />
                      </div>

                      <div className={classes.mb20}>
                        <Typography gutterBottom>
                          Education Certificate
                        </Typography>
                        <label
                          className={clsx(classes.labelBtnOutlined)}
                          htmlFor="cert"
                          style={{
                            borderColor: isEduSuccess ? "green" : "black",
                          }}
                        >
                          <MIcon
                            fontSize="small"
                            src={UploadIcon}
                            viewBox="0 0 330 330"
                          />
                        </label>
                        <input
                          id="cert"
                          type="file"
                          accept=".doc,.docx,.png,.pdf,.jpg,.jpeg"
                          className={classes.dNone}
                          onChange={(e) => handleChangeFile(e, setEduCert)}
                        />
                      </div>
                      <div className={classes.mb20}>
                        <Typography gutterBottom>Resume/CV</Typography>
                        <label
                          className={clsx(classes.labelBtnOutlined)}
                          htmlFor="resume"
                          style={{
                            borderColor: isResSuccess ? "green" : "black",
                          }}
                        >
                          <MIcon
                            fontSize="small"
                            src={UploadIcon}
                            viewBox="0 0 330 330"
                          />
                        </label>
                        <input
                          id="resume"
                          type="file"
                          accept=".doc,.docx,.png,.pdf,.jpg,.jpeg"
                          className={classes.dNone}
                          onChange={(e) => handleChangeFile(e, setResume)}
                        />
                      </div>
                      <div className={classes.mb20}>
                        <Typography gutterBottom>
                          Passport Photograph
                        </Typography>
                        <label
                          className={clsx(classes.labelBtnOutlined)}
                          htmlFor="photo"
                          style={{
                            borderColor: isPhotoSuccess ? "green" : "black",
                          }}
                        >
                          <MIcon
                            fontSize="small"
                            src={UploadIcon}
                            viewBox="0 0 330 330"
                          />
                        </label>
                        <input
                          id="photo"
                          type="file"
                          accept=".png,.pdf,.jpg,.jpeg"
                          className={classes.dNone}
                          onChange={(e) => handleChangeFile(e, setPhoto)}
                        />
                      </div>
                    </motion.div>
                  </TabPanel>
                </Paper>
                <Divider />
                {page > 2 ? null : (
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      {page > 0 && (
                        <MButton
                          color="primary"
                          variant="outlined"
                          onClick={handlePrev}
                          startIcon={<MIcon src={ChevronLeft} />}
                          disabled={disable}
                        >
                          Prev
                        </MButton>
                      )}
                    </Grid>
                    <Grid item>
                      {page > 1 ? null : (
                        <MButton
                          color="primary"
                          variant="contained"
                          onClick={handleNext}
                          endIcon={<MIcon src={ChevronRight} />}
                        >
                          Next
                        </MButton>
                      )}
                    </Grid>
                  </Grid>
                )}
              </form>
            </Grid>
          </Grid>
        </motion.div>
      </AnimatePresence>
      <Feedback
        open={loading}
        message="Please wait..."
        handleClose={() => {}}
        vertical="top"
        horizontal="center"
        onClose={handleCancelRequest}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PractionerForm;
