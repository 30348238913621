import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

import PractionerForm from "../components/Forms/PractionerForm";
import Wrapper from "../components/Wrapper";
import { pageTransition } from "../utils/transition";
import { organizationStructuredData } from "../structured-data";

const PractionerReg = () => {
  return (
    <>
      <Helmet>
        <title>
          Germiny&reg; - Register with us as a practitioner by either
          downloading our mobile app or via the website.
        </title>
        <meta property="og:title" content="Practitioner Registration form" />
        <meta property="og:site_name" content="Germiny" />
        <meta property="og:url" content="https://germiny.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="At Germiny®, we work to provide the best solutions to global issues, through the power of technology and team work."
        />

        <script type="application/ld+json">
          {organizationStructuredData({
            headline: "Practitioner Form",
            authorName: "Arifayan Idowu",
            description:
              "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
          })}
        </script>
      </Helmet>
      <Wrapper>
        <motion.div
          variants={pageTransition}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <PractionerForm />
        </motion.div>
      </Wrapper>
    </>
  );
};

export default PractionerReg;
