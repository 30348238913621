import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import useStyles from "../../theme/styles";
import MButton from "../Reusables/MButton";

const SafetyModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const policyHandle = () => {
    localStorage.setItem("germiny:policy", JSON.stringify(true));
    handleClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      PaperComponent={Paper}
      PaperProps={{
        square: true,
      }}
    >
      <DialogContent>
        <Typography className={classes.teamHeader} align="center" gutterBottom>
          Terms & Conditions
        </Typography>
        <Typography className={clsx(classes.text)} gutterBottom>
          On the Germiny&reg; website, your usage data and personal data may be
          collected for reasons indicated in the{" "}
          <Link to="/privacy" className={classes.link4}>
            Privacy & Safety Policy
          </Link>
          . To read more about our Terms & Condition, please click{" "}
          <Link to="/terms" className={classes.link4}>
            here
          </Link>
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <MButton variant="contained" color="primary" onClick={policyHandle}>
              I Agree
            </MButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SafetyModal;
