import {
  Typography,
  Grid,
  CardActionArea,
  Paper,
  useTheme,
  useMediaQuery,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { wrap } from "popmotion";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import useStyles from "../../../theme/styles";
import AppButtons from "../../Reusables/AppButtons";
import MIcon from "../../Reusables/MIcon";
import { ReactComponent as ChevronLeft } from "../../../theme/assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../theme/assets/chevron-right.svg";

const Features = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [[page, direction], setPage] = useState([0, 0]);

  const contentsIndex = wrap(0, contents.length, page);
  const imagesIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    if (page > (images.length - 1 || contents.length - 1)) {
      setPage([0, direction]);
    } else if (page < 0) {
      setPage([images.length - 1 || contents.length - 1, direction]);
    }
  }, [page, direction]);

  const imageVariants = {
    hidden: {
      opacity: 0,

      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
        delay: 0.5,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
        delay: 0.1,

        // duration: 1.5,
      },
    },
    exit: {
      opacity: 0,

      // scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 60,
        ease: "easeOut",
      },
    },
  };

  const textWrapperVariant = {
    hidden: {
      // opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const textVariant = {
    hidden: (direction) => ({
      y: direction > 0 ? 50 : -50,
      opacity: 0,
    }),
    visible: {
      y: 0,
      opacity: 1,

      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
      },
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };

  return (
    <AnimatePresence key={page}>
      <div className={clsx(classes.appFeaturesContainer, classes.container)}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.mb20}
        >
          <Grid item>
            <Typography className={classes.boldText}>Features</Typography>
          </Grid>
          <Grid item>
            <Grid container justify="center" alignItems="center" spacing={3}>
              <Grid item>
                <span>
                  {imagesIndex >= 9 ? null : 0}
                  {imagesIndex + 1}
                </span>
              </Grid>
              <Grid item>
                <ButtonGroup className={clsx(classes.borderNone)}>
                  <Button
                    color="primary"
                    onClick={() => paginate(-1)}
                    className={clsx(classes.borderNone)}
                  >
                    <MIcon src={ChevronLeft} />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(classes.textWhite, classes.borderNone)}
                    onClick={() => paginate(1)}
                  >
                    <MIcon src={ChevronRight} />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Paper
          className={classes.overflowHidden}
          square
          elevation={0}
          component={CardActionArea}
          onClick={() => history.push(contents[page].link)}
        >
          <Grid container direction={matches ? "column-reverse" : "row"}>
            <Grid item md={6} xs={12}>
              <div
                className={clsx(
                  classes.slider,
                  classes.container,
                  classes.bgBlack,
                  classes.textWhite
                )}
              >
                {contents.map((item, i) => (
                  <motion.div
                    key={i}
                    style={{
                      display: i === contentsIndex ? "block" : "none",
                    }}
                    variants={textWrapperVariant}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <motion.div
                      variants={textVariant}
                      custom={direction}
                      className={classes.relativePosition}
                    >
                      <Typography className={classes.absText2}>
                        Explore
                      </Typography>

                      <Typography gutterBottom className={classes.subtitle}>
                        {item.title}
                      </Typography>
                    </motion.div>
                    <motion.div variants={textVariant} custom={direction}>
                      <Typography gutterBottom className={classes.boldText}>
                        {item.content}
                      </Typography>
                    </motion.div>
                    <motion.div
                      variants={textVariant}
                      custom={direction}
                      className={classes.mt30}
                    >
                      <Typography
                        component={Link}
                        to={item.link}
                        className={classes.textWhite}
                        gutterBottom
                      >
                        Learn More
                      </Typography>
                    </motion.div>
                  </motion.div>
                ))}
                <motion.div
                  variants={textWrapperVariant}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className={clsx(classes.mt50)}
                >
                  <motion.div variants={textVariant} custom={direction}>
                    <AppButtons {...{ clsx, classes }} user="pract" />
                  </motion.div>
                </motion.div>
              </div>
            </Grid>

            <Grid item md={6}>
              <div
                className={clsx(
                  classes.overflowHidden,
                  classes.bgBlack,
                  classes.relativePosition
                )}
              >
                {images.map((item, i) => (
                  <motion.img
                    variants={imageVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    key={i}
                    alt={`bg-${i}`}
                    className={classes.bg}
                    src={item.img}
                    style={{ display: i === imagesIndex ? "block" : "none" }}
                    draggable="false"
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </AnimatePresence>
  );
};

const contents = [
  {
    title: "Germiny Practitioner",
    content:
      "We are particular about your health, so we provide affordable primary healthcare to you wherever you are. We also save you the stress of joining a waiting list or booking appointments at the hospital.",
    link: "/products/practitioner",
  },
  {
    title: "Germiny Veterinarian",
    content:
      "Do you have and love animals? Germiny Veterinarian can be used to search for veterinarians within your neighbourhood to have your pets checked and treated.",
    link: "/products/veterinary",
  },
];

const images = [
  {
    img: "/img/small/healthcare2.jpg",
  },
  {
    img: "/img/small/husky.jpeg",
  },
];

export default Features;
