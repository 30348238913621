import {
  Typography,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import useStyles from "../theme/styles";
import MIcon from "./Reusables/MIcon";
import { ReactComponent as Facebook } from "../theme/assets/facebook.svg";
import { ReactComponent as LinkedIn } from "../theme/assets/linkedin.svg";
import { ReactComponent as Instagram } from "../theme/assets/instagram.svg";
import { ReactComponent as Twitter } from "../theme/assets/twitter.svg";
import { ReactComponent as YouTube } from "../theme/assets/youtube.svg";
import colors from "../theme/colors";
import Logo from "./Reusables/Logo";
import AppButtons from "./Reusables/AppButtons";

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={clsx(classes.footer, classes.container)}>
      <div
        className={clsx(classes.mb50, classes.mt20)}
        style={{
          width: "fit-content",
        }}
      >
        <Logo />
      </div>
      <div>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography
              className={clsx(classes.teamHeader, classes.lightGreyText)}
              gutterBottom
            >
              Company
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Home
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/about"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              About Us
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/contact"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Contact Us
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/details/products"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Products
            </Typography>

            <Typography
              exact
              component={NavLink}
              to="/careers"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Careers
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/blog"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Blog
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              className={clsx(classes.teamHeader, classes.lightGreyText)}
            >
              Products
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/products/applications"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Germiny Application
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/products/veterinary"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Germiny Veterinarian
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/products/practitioner"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Germiny Practitioner
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/business"
              className={classes.linkFooter}
              activeStyle={{
                color: colors.activeLink,
              }}
            >
              Germiny Corporate (Coming soon)
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className={clsx(classes.mt50)}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <a
              href="https://www.facebook.com/112255277213482/"
              target="_blank"
              rel="noreferrer"
            >
              <MIcon
                src={Facebook}
                className={clsx(classes.textWhite)}
                fontSize="small"
              />
            </a>
            <a
              href="https://twitter.com/Germiny_tech"
              target="_blank"
              rel="noreferrer"
              className={classes.ml40}
            >
              <MIcon
                src={Twitter}
                className={clsx(classes.textWhite)}
                fontSize="small"
                viewBox="0 0 438.536 438.536"
              />
            </a>

            <a
              href="https://www.linkedin.com/company/Germiny"
              target="_blank"
              rel="noreferrer"
              className={classes.ml40}
            >
              <MIcon
                src={LinkedIn}
                className={clsx(classes.textWhite)}
                fontSize="small"
              />
            </a>

            <a
              href="https://instagram.com/germiny_tech"
              target="_blank"
              rel="noreferrer"
              className={classes.ml40}
            >
              <MIcon
                src={Instagram}
                className={clsx(classes.textWhite)}
                fontSize="small"
                viewBox="0 0 511 511.9"
              />
            </a>
            <a
              href="https://youtube.com/channel/UCC8SOOph0qa_62k0kZXjwlQ"
              target="_blank"
              rel="noreferrer"
              className={classes.ml40}
            >
              <MIcon
                src={YouTube}
                className={clsx(classes.textWhite)}
                fontSize="small"
                viewBox="0 0 438.536 438.536"
              />
            </a>
          </Grid>
          <Grid
            item
            className={clsx({
              [classes.mt20]: matches,
            })}
          >
            <AppButtons {...{ clsx, classes }} user="user" />
          </Grid>
        </Grid>
      </div>
      <div className={clsx(classes.mt50)}>
        <Divider className={classes.footerDivider} />
        <Grid container justify="space-between" className={classes.mt20}>
          <Grid item>
            <Typography
              className={clsx(classes.footerLink, classes.lightGreyText)}
            >
              &copy; {new Date().getFullYear()}{" "}
              <span className={classes.capitalize}>Germiny&reg;</span>. All
              Rights Reserved
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component={Link}
              to="/privacy"
              className={clsx(classes.footerLink, classes.lightGreyText)}
            >
              Privacy & Safety
            </Typography>
            <Typography
              component={Link}
              to="/terms"
              className={clsx(classes.footerLink, classes.lightGreyText)}
            >
              Terms
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
