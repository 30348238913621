import { Grid, Typography, Paper } from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import useStyles from "../../../theme/styles";
import AppButtons from "../../Reusables/AppButtons";
import MButton from "../../Reusables/MButton";

const IntroSection = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, classes.introSectionContainer)}>
      <Grid
        container
        className={clsx(classes.mt20, classes.mb20, classes.overflowHidden)}
        component={Paper}
        square
        elevation={0}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6} className={classes.productImg2}></Grid>
        <Grid item xs={12} md={6}>
          <div className={clsx(classes.h100, classes.container)}>
            <Typography gutterBottom className={clsx(classes.subtitle)}>
              Germiny for everyone
            </Typography>
            <Typography gutterBottom className={clsx(classes.boldText)}>
              With Germiny, everything you're looking for is brought to your
              fingertips, by leveraging geolocation API to give users a chance
              to get affordable and easily accessible services.
            </Typography>
            <motion.div
              className={classes.fitContent}
              whileTap={{
                scale: 0.8,
              }}
            >
              <MButton
                variant="outlined"
                component={Link}
                to="/products/applications"
                size="large"
              >
                {"Learn More"}
              </MButton>
            </motion.div>
            <div className={classes.mt20}>
              <Typography>Download our app</Typography>
              <AppButtons {...{ clsx, classes }} user="user" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default IntroSection;
