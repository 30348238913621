const colors = {
  primary: "#000",
  white: "#fff",
  black: "#000",
  fadedBlack: "#171717",
  deepblue: "#3f6eb5",
  lightblue: "#414C83",
  lightred: "#D95151",
  lightGrey: "rgb(195 195 195 / 61%)",
  extraLightGrey: "rgb(132 132 132 / 33%)",
  deepGrey: "#4c4c4c",
  darkerBlue: "#212b36",
  paperDark: "#161c28",
  background: "#151825",
  offWhite: "#f4f6f8",
  secondary: "#C62368",
  fadedGrey: "#bdbdbd",
  green: "#388e3c",
  red: "#c2185b",
  orange: "#f57c00",
  purple: "#7b1fa2",
  teal: "#009688",
  armygreen: "#1f441e",
  activeLink: "#848484",
};

export default colors;
