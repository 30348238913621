import { useState } from "react";
import { Typography, CircularProgress, Backdrop } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { comingSoonLogic } from "../../../features/info/infoSlice";
import useStyles from "../../../theme/styles";
import InputField from "../../Fields/InputField";
import MButton from "../../Reusables/MButton";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "../../Reusables/Loader";

const initialState = {
  email: "",
};

const Hero = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const { loading } = useSelector((state) => state.info);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(comingSoonLogic(state))
      .then(unwrapResult)
      .then((doc) => {
        Swal.fire({
          title: "Success",
          text: doc?.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            setState(initialState);
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "",
          text: err?.error,
          icon: "warning",
        }).then((result) => {
          if (result.isConfirmed) {
            setState(initialState);
          }
        });
      });
  };

  return (
    <>
      <div className={clsx(classes.container, classes.businessHeroContainer)}>
        <div className={classes.mt50}>
          <div>
            <Typography gutterBottom className={clsx(classes.text)}>
              Coming soon...
            </Typography>
          </div>
          <div>
            <Typography gutterBottom className={clsx(classes.subtitle)}>
              Germiny Corporate
            </Typography>
          </div>
          <div>
            <Typography gutterBottom className={clsx(classes.text)}>
              A platform that caters to all your business needs.
            </Typography>
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <InputField
                type="email"
                required
                name="email"
                value={state.email}
                onChange={handleChange}
                className={clsx(classes.bgWhite, classes.minTextField)}
                placeholder="Enter your email address"
              />
            </div>
            <motion.div
              className={classes.fitContent}
              whileTap={{
                scale: 0.8,
              }}
            >
              <MButton type="submit" variant="contained" color="primary">
                {loading ? <Loader /> : "Notify Me"}
              </MButton>
            </motion.div>
          </form>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Hero;
