import { Grid, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useStyles from "../../../theme/styles";
import AppButtons from "../../Reusables/AppButtons";
import MButton from "../../Reusables/MButton";

const Hero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className={clsx(classes.vetHeroContainer, classes.container)}>
      <div className={clsx(classes.textWhite)}>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          spacing={5}
        >
          <Grid item md={8}>
            <Typography className={clsx(classes.subtitle)} gutterBottom>
              Germiny Veterinarian
            </Typography>
            <Typography gutterBottom className={classes.boldText}>
              The health and well-being of animals is very important to the
              survival of humans. Whether as pets or sources of food, animals
              help keep humans alive. The Germiny Veterinarian is a trained
              professional who practices the medicine of treating diseases,
              disorders and injuries in animals.
            </Typography>

            <motion.div
              className={classes.fitContent}
              whileTap={{ scale: 0.8 }}
            >
              <MButton
                variant="outlined"
                extraStyle={classes.btnWhite}
                component={Link}
                to="/veterinary/register"
              >
                Sign Up
              </MButton>
            </motion.div>
            {matches ? (
              <AppButtons {...{ clsx, classes }} user="pract" />
            ) : null}
          </Grid>
          <Grid item md={4}>
            {matches ? null : (
              <div className={classes.textCenter}>
                <img src="/img/svgs/qr-code.svg" alt="QR code" height={250} />

                <div className={clsx(classes.mt20)}>
                  <AppButtons
                    {...{ clsx, classes }}
                    justify="center"
                    user="pract"
                  />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Hero;
