import React from "react";
import {
  Grid,
  Button,
  IconButton,
  Drawer,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";

import useStyles from "../../theme/styles";
import { useHistory } from "react-router";
import { navigate } from "../../utils/navigate";

const SignupDropdown = ({ anchorEl, handleClose, openDropdown2 }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleRoute = (path) => {
    navigate(history, path);
    handleClose();
  };

  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 50,
    ease: "easeOut",
  };

  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        staggerDirection: -1,
      },
    },
    exit: { opacity: 0 },
  };

  const item = {
    hidden: {
      y: -300,
      opacity: 0,
      transition,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
    exit: {
      y: -300,
      opacity: 0,
      transition,
    },
  };

  const slideInRight = {
    hidden: {
      x: 300,
      opacity: 0,
      rotate: "360deg",
      transition,
    },
    visible: {
      x: 0,
      opacity: 1,
      rotate: "0deg",
      transition,
    },
    exit: {
      x: 300,
      opacity: 0,
      transition,
    },
  };

  const slideInLeft = {
    hidden: {
      x: -300,
      opacity: 0,
      transition,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition,
    },
    exit: {
      x: -300,
      opacity: 0,
      transition,
    },
  };

  const open = Boolean(anchorEl);

  return (
    <AnimatePresence key={openDropdown2}>
      <Drawer
        anchor="top"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: "100vh",
            color: "#fff",
            backgroundColor: "#000",
            overflow: "unset",
            overflowY: "scroll",
            zIndex: 900,
            overflowX: "hidden",
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
      >
        <motion.div
          className={clsx(classes.container)}
          variants={container}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Grid container justify="space-between">
            <Grid item>
              <motion.div
                variants={slideInLeft}
                style={{
                  marginTop: 13,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    display: "block",
                  }}
                  onClick={() => handleRoute("/")}
                >
                  Germiny
                </Typography>
              </motion.div>
            </Grid>
            <Grid item>
              <motion.div variants={slideInRight}>
                <IconButton color="primary" onClick={handleClose}>
                  <Close style={{ color: "#fff" }} fontSize="large" />
                </IconButton>
              </motion.div>
            </Grid>
          </Grid>

          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.mt50}
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <motion.div
                whileTap={{
                  scale: 0.8,
                }}
                variants={item}
              >
                <Button
                  className={clsx(classes.bigBtnWhite2)}
                  variant="outlined"
                  fontSize="small"
                  onClick={() => handleRoute("/practitioner/register")}
                  style={{ width: "100%" }}
                >
                  Register as a practitioner
                </Button>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                whileTap={{
                  scale: 0.8,
                }}
                variants={item}
              >
                <Button
                  className={clsx(classes.bigBtnWhite2)}
                  variant="outlined"
                  fontSize="small"
                  onClick={() => handleRoute("/veterinary/register")}
                  style={{ width: "100%" }}
                >
                  Register as a veterinarian
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </motion.div>
      </Drawer>
    </AnimatePresence>
  );
};

export default SignupDropdown;
