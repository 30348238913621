import { useState } from "react";
import {
  Grid,
  Hidden,
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { motion } from "framer-motion";
import clsx from "clsx";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

import useStyles from "../../theme/styles";
import InputField from "../Fields/InputField";
import MButton from "../Reusables/MButton";
import { contactUsLogic } from "../../features/info/infoSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "../Reusables/Loader";

const initialState = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const ContactContent = () => {
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.info);

  const position = [6.4973733, 3.3790146];

  const zoomLvl = 17;

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(contactUsLogic(state))
      .then(unwrapResult)
      .then((doc) => {
        Swal.fire({
          title: "Success",
          text: doc?.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            setState(initialState);
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          text: err?.error,
          icon: "error",
        });
      });
  };

  const isValid = () =>
    !state.name || !state.email || !state.subject || !state.message;

  return (
    <div>
      <div className={clsx(classes.container, classes.mt50, classes.mb50)}>
        <Grid
          container
          component={Paper}
          square
          className={clsx(classes.paperBorder)}
          elevation={0}
        >
          <Grid
            item
            xs={12}
            md={6}
            className={clsx(
              classes.contactInfoBg,
              classes.textWhite,
              classes.relativePosition
            )}
          >
            <Hidden mdDown>
              <MapContainer
                center={position}
                zoom={zoomLvl}
                scrollWheelZoom={false}
                style={{ height: "100%", width: "100%", background: "#000" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                  <Popup>
                    239 MOD Towers, Herbert Macaulay way,
                    <br /> Yaba, Lagos, Nigeria.
                  </Popup>
                </Marker>
              </MapContainer>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              //   spacing={6}
              className={clsx(
                // classes.bgBlack,
                classes.textWhite,
                classes.container,
                classes.contactInfoBg
              )}
              justify="space-between"
            >
              <Grid item>
                <Typography
                  gutterBottom
                  className={clsx(classes.text, classes.lightGreyText)}
                >
                  Address
                </Typography>
                <Typography
                  gutterBottom
                  className={clsx(classes.text, classes.mb20, classes.grid)}
                >
                  239 MOD Towers, Herbert Macaulay way,
                  <br /> Yaba, Lagos, Nigeria.
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  gutterBottom
                  className={clsx(classes.text, classes.lightGreyText)}
                >
                  Contact
                </Typography>
                <Typography
                  className={clsx(classes.text, classes.link2)}
                  component="a"
                  display="block"
                  href="tel:(+234)9046291565"
                >
                  (+234)9046291565
                </Typography>
                <Typography
                  display="block"
                  className={clsx(classes.text, classes.link2)}
                  component="a"
                  href="mailto:info@germiny.org"
                >
                  info@germiny.org
                </Typography>
              </Grid>
            </Grid>
            <div className={clsx(classes.container)}>
              <Typography
                className={clsx(classes.boldText, classes.mb20, classes.mt20)}
                gutterBottom
              >
                Have a question?
              </Typography>
              <form onSubmit={handleSubmit}>
                <InputField
                  placeholder="Your Name"
                  className={classes.textField}
                  name="name"
                  value={state.name}
                  onChange={handleChange}
                />
                <InputField
                  placeholder="Your Email"
                  type="email"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  className={classes.textField}
                />
                <InputField
                  placeholder="Subject"
                  name="subject"
                  value={state.subject}
                  onChange={handleChange}
                  className={classes.textField}
                />
                <InputField
                  placeholder="Write message..."
                  className={classes.textField}
                  name="message"
                  value={state.message}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
                <motion.div
                  whileTap={{
                    scale: 0.8,
                  }}
                  className={classes.fitContent}
                >
                  <MButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isValid() || loading}
                  >
                    {loading ? <Loader /> : "Send"}
                  </MButton>
                </motion.div>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ContactContent;
