import { useEffect, useState } from "react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import useStyles from "../../../theme/styles";

const HeroCount = ({ page }) => {
  const classes = useStyles();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    let counter = 100;
    const interval = setInterval(() => {
      if (counter === 0) {
        counter = 100;
      }
      counter -= 1;
      setWidth(counter);
    }, 80);
    return () => clearInterval(interval);
  }, [page]);

  const countVariants = {
    hidden: {
      x: 250,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        x: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          duration: 5,
          ease: "easeOut",
        },
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const wrapperVariants = {
    hidden: {
      y: -1050,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          duration: 5,
          ease: "easeOut",
        },
      },
    },
    exit: {
      y: -1050,
      opacity: 0,
    },
  };

  return (
    <AnimatePresence initial={true} custom={page}>
      <motion.div
        key="count"
        layout
        className={classes.countWrapper}
        variants={wrapperVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <div className={classes.overflowHidden}>
          <motion.div
            key={page}
            variants={countVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={clsx(classes.count)}
          >
            {page === 1 ? "02" : page === 2 ? "03" : "01"}
          </motion.div>
          <div
            key="progress"
            style={{
              width: `${width}%`,
              height: 3,
              backgroundColor: "red",
              position: "absolute",
              bottom: 0,
              left: 0,
              transition: "width 60ms linear",
            }}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default HeroCount;
