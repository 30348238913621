import React from "react";
import { Snackbar, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const Feedback = ({
  open,
  handleClose,
  message,
  vertical = "top",
  horizontal = "right",
  onClose = null,
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={
        onClose ? (
          <>
            <IconButton color="inherit" onClick={onClose}>
              <Close />
            </IconButton>
          </>
        ) : null
      }
    />
  );
};

export default Feedback;
