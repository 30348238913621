import { motion } from "framer-motion";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";

import Detail from "../components/LeadershipComponents/Detail";
import Wrapper from "../components/Wrapper";
import { pageTransition } from "../utils/transition";
import { organizationStructuredData } from "../structured-data";

const LeadershipDetail = () => {
  const { name } = useParams();
  return (
    <>
      <Helmet>
        <title>
          Germiny - Meet {name}, one of the brilliant minds behind the scene.
        </title>
        <meta property="og:title" content="Leadership" />
        <meta property="og:site_name" content="Germiny" />
        <meta property="og:url" content="https://germiny.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={`Germiny Leadership Team. Meet the amazing ${name}`}
        />

        <script type="application/ld+json">
          {organizationStructuredData({
            headline: "Leadership",
            authorName: "Arifayan Idowu",
            description:
              "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
          })}
        </script>
      </Helmet>
      <Wrapper>
        <motion.div
          layout
          variants={pageTransition}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Detail />
        </motion.div>
      </Wrapper>
    </>
  );
};

export default LeadershipDetail;
