import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { asyncFetch } from "../api/asyncFetch";

let source = axios.CancelToken.source();

const BASE_URL = process.env.REACT_APP_BASE_URL;

let endpoint = "auth/practitioner/register";
let endpoint2 = "practitioner/doc";

export const createUser = createAsyncThunk(
  "users/createUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await asyncFetch(`${BASE_URL}/${endpoint}`, "post", data);

      return await res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const uploadDoc = createAsyncThunk(
  "users/uploadDoc",
  async (body, { rejectWithValue }) => {
    try {
      const headers = {
        Authorization: `Bearer ${body.token}`,
      };
      const cancelToken = source.token;

      const res = await asyncFetch(
        `${BASE_URL}/${endpoint2}/${body.type}`,
        "put",
        body.formData,
        headers,
        cancelToken
      );

      return await res.data?.userDoc;
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(
          "This action has been cancelled.\r\n If you're having any difficulty uploading document, please reload and try again."
        );
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const cancelRequest = () => {
  source.cancel();
  source = axios.CancelToken.source();
};

const initialState = {
  users: [],
  user: null,
  status: "idle",
  loading: false,
  error: null,
  doc: null,
  token: null,
  isNew: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUserState: (state, _) => {
      state.user = null;
      state.doc = null;
      state.token = null;
      state.isNew = false;
      state.users = [];
      state.error = null;
      state.loading = false;
    },
    resetLoader: (state, _) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state, _) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "idle";
        state.user = payload.data;
        state.token = payload.token;
        state.isNew = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = "idle";
        state.loading = false;
        state.error = action.error?.error;
      });
    builder
      .addCase(uploadDoc.pending, (state, _) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(uploadDoc.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "idle";
        state.doc = payload;
      })
      .addCase(uploadDoc.rejected, (state, action) => {
        state.status = "idle";
        state.loading = false;
        state.error = action.error?.error;
      });
  },
});

const { reducer, actions } = usersSlice;

export const { resetUserState, resetLoader } = actions;

export default reducer;
