import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useStyles from "../../../theme/styles";
import MButton from "../../Reusables/MButton";

const CorpSection = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.corpSectionContainer)}>
      <Typography gutterBottom className={clsx(classes.subtitle, classes.mt30)}>
        Germiny for Corporate
      </Typography>
      <Typography gutterBottom className={clsx(classes.boldText)}>
        By leveraging technology, we offer an amazing opportunity to provide
        various services for your company.
      </Typography>
      <motion.div
        className={classes.fitContent}
        whileTap={{
          scale: 0.8,
        }}
      >
        <MButton
          variant="contained"
          color="primary"
          component={Link}
          to="/business"
        >
          Read More
        </MButton>
      </motion.div>
    </div>
  );
};

export default CorpSection;
