import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { asyncFetch } from "../api/asyncFetch";

const initialState = {
  teams: [],
  team: null,
  status: "idle",
  loading: false,
  error: null,
};

export const getTeams = createAsyncThunk("teams/getTeams", async () => {
  const res = await asyncFetch("/leaders.json");
  return res.data.leaders;
});

export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    getTeam: (state, { payload }) => {
      state.team = state.teams.find((team) => team.link === payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state, _) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getTeams.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "idle";
        state.teams = payload;
      })
      .addCase(getTeams.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error?.message;
      });
  },
});

const { reducer, actions } = teamsSlice;

export const { getTeam } = actions;

export default reducer;
