import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../../theme/styles";
import MIcon from "../../Reusables/MIcon";
import { ReactComponent as RoutesIcon } from "../../../theme/assets/routes.svg";
import { ReactComponent as Handshake } from "../../../theme/assets/handshake.svg";
import { ReactComponent as Wallet } from "../../../theme/assets/wallet.svg";
import { ReactComponent as Hours } from "../../../theme/assets/24-hours.svg";

const Reason = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container)}>
      <div className={clsx(classes.mt20, classes.mb20)}>
        <Typography gutterBottom className={clsx(classes.subtitle)}>
          Why use Germiny
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Card
              square
              elevation={0}
              className={clsx(classes.paperBorder, classes.cards)}
            >
              <CardHeader
                avatar={<MIcon src={RoutesIcon} fontSize="large" />}
              />
              <CardContent>
                <Typography gutterBottom className={classes.boldText2}>
                  Accessibility
                </Typography>
                <Typography gutterBottom className={classes.text}>
                  Easy Access to Primary Healthcare.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card
              square
              elevation={0}
              className={clsx(classes.paperBorder, classes.cards)}
            >
              <CardHeader
                avatar={
                  <MIcon src={Handshake} viewBox="0 0 60 56" fontSize="large" />
                }
              />
              <CardContent>
                <Typography gutterBottom className={classes.boldText2}>
                  Reliability
                </Typography>
                <Typography gutterBottom className={classes.text}>
                  Guaranteed to get the best healthcare service at all times.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card
              square
              elevation={0}
              className={clsx(classes.paperBorder, classes.cards)}
            >
              <CardHeader avatar={<MIcon src={Wallet} fontSize="large" />} />
              <CardContent>
                <Typography gutterBottom className={classes.boldText2}>
                  Affordability
                </Typography>
                <Typography gutterBottom className={classes.text}>
                  A healthcare service that is totally affordable.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card
              square
              elevation={0}
              className={clsx(classes.paperBorder, classes.cards)}
            >
              <CardHeader avatar={<MIcon src={Hours} fontSize="large" />} />
              <CardContent>
                <Typography gutterBottom className={classes.boldText2}>
                  Availability
                </Typography>
                <Typography gutterBottom className={classes.text}>
                  As practitioners, you can make more money at your spare time.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Reason;
