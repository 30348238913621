import clsx from "clsx";
import ProgressiveImage from "react-progressive-graceful-image";
import { AnimatePresence, motion } from "framer-motion";
import useStyles from "../../../theme/styles";
import { wrap } from "popmotion";

const imagesSm = [
  "/img/small/doc-1.jpeg",
  "/img/small/vet-small.jpeg",
  "/img/small/consult-small.jpeg",
];

const HeroImage = ({ page }) => {
  const classes = useStyles();

  const imageVariants = {
    hidden: {
      opacity: 0.5,
      // x: -1000,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      // zIndex: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    },
    exit: {
      opacity: 0.5,
      // x: -1000,
    },
  };

  const imagesIndex = wrap(0, imagesSm.length, page);

  return (
    <AnimatePresence initial={false} custom={page}>
      {imagesSm.map((images, i) => (
        <ProgressiveImage
          key={i}
          delay={5000}
          src={images}
          placeholder={images}
          className={classes.imgContainer}
        >
          {(src, loading) => (
            <motion.img
              key={page}
              variants={imageVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              src={src}
              alt="Hero Bg"
              style={{
                objectPosition: imagesIndex === 1 && "center 50%",
                opacity: loading ? 0.5 : 1,
                display: imagesIndex === i ? "block" : "none",
              }}
              className={clsx(classes.heroImage)}
            />
          )}
        </ProgressiveImage>
      ))}
    </AnimatePresence>
  );
};

export default HeroImage;
