import {
  Paper,
  Typography,
  Button,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { wrap } from "popmotion";
import { Link } from "react-router-dom";
import useStyles from "../../theme/styles";
import MButton from "../Reusables/MButton";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as ChevronLeft } from "../../theme/assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../theme/assets/chevron-right.svg";

const imagesSm = [
  "/img/small/techies.jpeg",
  "/img/small/innov.jpeg",
  "/img/small/code.jpeg",
];

const content = [
  {
    title: "Collaboration",
    body: "At Germiny®, we work to provide the best solutions to global issues, through the power of technology and team work.",
    link: "/details/products",
  },
  {
    title: "Innovation",
    body: "With the amazing minds at Germiny®, we don't just think about ideas, we make ideas happen.",
    link: "/details/products",
  },
  {
    title: "Technology",
    body: "We harness the power of technology, and use it to deliver amazing solutions that caters to your every need.",
    link: "/details/products",
  },
];

const Landing = () => {
  const classes = useStyles();
  const [[page, direction], setPage] = useState([0, 0]);

  useEffect(() => {
    if (page > imagesSm.length - 1) {
      setPage([0, direction]);
    } else if (page < 0) {
      setPage([imagesSm.length - 1, direction]);
    }
  }, [page, direction]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const indicatorVariant = {
    visible: (page) => ({
      y: page === 1 ? 30 : page === 2 ? 60 : 0,
      transition: {
        type: "spring",
        stiffness: 600,
        damping: 30,
        ease: "easeOut",
      },
    }),
  };

  const textWrapperVariant = {
    hidden: {
      // opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const textVariant = {
    hidden: (direction) => ({
      y: direction > 0 ? 50 : -50,
      opacity: 0,
    }),
    visible: {
      y: 0,
      opacity: 1,

      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
      },
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };

  const imageVariants = {
    hidden: (direction) => ({
      opacity: 0,
      x: direction > 0 ? -100 : 100,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
        delay: 0.5,
      },
    }),
    visible: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
        delay: 0.1,

        // duration: 1.5,
      },
    },
    exit: (direction) => ({
      opacity: 0,
      x: direction > 0 ? -100 : 100,
      // scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 60,
        ease: "easeOut",
      },
    }),
  };

  const counterVariants = {
    hidden: {
      y: 500,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    },
    exit: {
      y: 500,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    },
  };

  const reverseCounterVariants = {
    hidden: {
      y: -500,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    },
    exit: {
      y: -500,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        duration: 1.5,
      },
    },
  };

  const contentsIndex = wrap(0, content.length, page);
  const imagesIndex = wrap(0, imagesSm.length, page);

  return (
    <AnimatePresence initial={true} custom={direction}>
      <div className={clsx(classes.landingContainer, classes.relativePosition)}>
        <div className={classes.overlayBlack} />
        <div className={clsx(classes.landingCounter, classes.grid)}>
          <motion.div
            variants={reverseCounterVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            key={`${page}-001`}
          >
            0
          </motion.div>
          <motion.div
            key={page}
            variants={counterVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {page + 1}
          </motion.div>
        </div>
        <Paper
          square
          elevation={0}
          className={clsx(classes.landingTextContainer, classes.container)}
        >
          <div className={clsx(classes.indicatorContainer)}>
            <motion.div
              variants={indicatorVariant}
              animate="visible"
              custom={page}
              className={clsx(classes.indicator)}
            />
          </div>

          <motion.div
            variants={textWrapperVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
            key={page}
            className={clsx(classes.mt20)}
          >
            <motion.div custom={direction} variants={textVariant}>
              <Typography gutterBottom className={clsx(classes.teamHeader3)}>
                {content[contentsIndex].title}
              </Typography>
            </motion.div>
            <motion.div custom={direction} variants={textVariant}>
              <Typography gutterBottom className={clsx(classes.text)}>
                {content[contentsIndex].body}
              </Typography>
            </motion.div>
          </motion.div>
          <motion.div
            className={classes.fitContent}
            whileTap={{
              scale: 0.8,
            }}
          >
            <MButton
              variant="contained"
              color="primary"
              component={Link}
              to={content[contentsIndex].link || "/details/products"}
            >
              Explore
            </MButton>
          </motion.div>

          <Grid container justify="space-between">
            <Grid item></Grid>
            <Grid item>
              <ButtonGroup className={clsx(classes.borderNone)}>
                <Button
                  color="primary"
                  onClick={() => paginate(-1)}
                  className={clsx(classes.borderNone)}
                >
                  <MIcon src={ChevronLeft} />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx(classes.textWhite, classes.borderNone)}
                  onClick={() => paginate(1)}
                >
                  <MIcon src={ChevronRight} />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
        {imagesSm.map((image, i) => (
          <motion.img
            key={`${page}-${i}`}
            custom={direction}
            variants={imageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            src={image}
            alt={content[i].title}
            className={clsx(classes.heroImage)}
            style={{ display: i === imagesIndex ? "block" : "none" }}
          />
        ))}
      </div>
    </AnimatePresence>
  );
};

export default Landing;
