import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import useStyles from "../../theme/styles";

const Intro = ({ loadText, loading }) => {
  const classes = useStyles();

  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 30,
    ease: "easeOut",
    staggerChildren: 0.5,
    delayChildren: 0.3,
  };
  const transition2 = {
    type: "spring",
    stiffness: 300,
    damping: 30,
    ease: "easeOut",
  };

  const containerAnim = {
    hidden: {
      opacity: 0,
      transition: transition2,
    },
    visible: (loading) => ({
      opacity: loading ? 1 : 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
      },
    }),
    exit: {
      opacity: 0,
      transition: transition2,
    },
  };

  const fadeAnim = {
    hidden: {
      opacity: 0,
    },
    visible: (loadText) => ({
      opacity: loadText ? 1 : 0,
      transition,
    }),
    exit: {
      opacity: 0,
    },
  };

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: transition2,
    },
    exit: {
      opacity: 0,
      y: -30,
    },
  };

  return (
    <AnimatePresence initial={true}>
      <motion.div
        variants={containerAnim}
        initial="hidden"
        animate="visible"
        exit="exit"
        custom={loading}
        className={clsx(classes.introContainer)}
      >
        <motion.div
          className={classes.centerAbsolute2}
          variants={fadeAnim}
          initial="hidden"
          animate="visible"
          exit="exit"
          custom={loadText}
        >
          <motion.div variants={textVariants}>
            <Typography className={clsx(classes.cultureHeader)}>
              Germiny
            </Typography>
          </motion.div>
          <motion.div variants={textVariants}>
            <Typography className={classes.boldText}>[Jurmi-nahy]</Typography>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Intro;
