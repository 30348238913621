import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../theme/styles";

const Culture = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.cultureContainer)}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          className={clsx(classes.container, classes.cultureBlackWrapper)}
        >
          <Typography gutterBottom className={clsx(classes.cultureHeader)}>
            Our Culture
          </Typography>
          <Typography className={clsx(classes.text)}>
            An Inclusive culture of like-minded people, driven by love,
            integrity, empathy, innovation, and commitment focused on the
            upliftment of Humans and Animals across the world.
          </Typography>
          <Typography
            align="right"
            className={clsx(classes.mt50, classes.lightGreyText)}
          >
            Germiny&reg;
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.cultureBg} />
      </Grid>
    </div>
  );
};

export default Culture;
