import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import AppButtons from "../Reusables/AppButtons";

const InfoModal = ({ open, handleClose, classes }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={Paper}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={clsx(classes.teamHeader)}>
              Information
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleClose}
              className={clsx(classes.closeBtn)}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography
            gutterBottom
            className={classes.mb20}
            style={{ lineHeight: 1 }}
          >
            Before you commence your registration, ensure you have the following
            documents:
          </Typography>
          <Paper
            className={clsx(classes.paperBorder, classes.paperTransparent)}
            square
            elevation={0}
          >
            <ul>
              <li>Valid National Identification</li>
              <li>Education Certificate</li>
              <li>Medical License</li>
              <li>Resume/CV</li>
            </ul>
          </Paper>
          <div className={classes.mt20}>
            <Typography gutterBottom>Steps:</Typography>
            <Typography gutterBottom>1. Register (step 1 - 3)</Typography>
            <Typography gutterBottom>2. Upload documents (step 4)</Typography>
            <>
              <Typography gutterBottom>3. Download the mobile app</Typography>
              <AppButtons {...{ clsx, classes }} user="pract" />
            </>
            <Typography gutterBottom>
              4. Login with your phone number & password
            </Typography>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
