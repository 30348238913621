import { Grid, ButtonGroup, Button, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";

import clsx from "clsx";
import { wrap } from "popmotion";

import useStyles from "../../../../theme/styles";
import AllVets from "./AllVets";
import MIcon from "../../../Reusables/MIcon";
import { ReactComponent as ChevronLeft } from "../../../../theme/assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../../theme/assets/chevron-right.svg";
import GetApps from "./GetApps";
import FAQs from "./FAQs";
import Benefit from "../../Benefit";

const FeatureIndex = () => {
  const classes = useStyles();
  const [[page, direction], setPage] = useState([0, 0]);

  const contentsIndex = wrap(0, contents.length, page);
  const imagesIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    if (page > (images.length - 1 || contents.length - 1)) {
      setPage([0, direction]);
    } else if (page < 0) {
      setPage([images.length - 1 || contents.length - 1, direction]);
    }
  }, [page, direction]);

  return (
    <>
      <div className={clsx(classes.container, classes.featureIndexContainer)}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.mb20}
        >
          <Grid item>
            <Typography className={classes.boldText}>Features</Typography>
          </Grid>
          <Grid item>
            <Grid container justify="center" alignItems="center" spacing={3}>
              <Grid item>
                <span>
                  {imagesIndex >= 9 ? null : 0}
                  {imagesIndex + 1}
                </span>
              </Grid>
              <Grid item>
                <ButtonGroup className={clsx(classes.borderNone)}>
                  <Button
                    color="primary"
                    onClick={() => paginate(-1)}
                    className={clsx(classes.borderNone)}
                  >
                    <MIcon src={ChevronLeft} />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(classes.textWhite, classes.borderNone)}
                    onClick={() => paginate(1)}
                  >
                    <MIcon src={ChevronRight} />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AllVets
          {...{
            page,
            direction,
            setPage,
            contents,
            images,
            contentsIndex,
            imagesIndex,
          }}
        />
      </div>
      <Benefit title="How Germiny Veterinarian works?" />
      <FAQs />
      <GetApps />
    </>
  );
};

const contents = [
  {
    header: "CANINE VET",
    body: "Our dogs need their special care too and there is need for the appropriate professional to attend to them. The vet doctor treats diseases, injuries and other disorders that dogs may have. ",
  },
  {
    header: "FELINE VET",
    body: "Cats are unique in a number of ways. A cat vet handles the unique needs that cats have and is responsible for general and total wellbeing. ",
  },
  {
    header: "GENERAL VET",
    body: "Animals have been central to our lives. There are obviously a number of creatures in the animal kingdom and they need the attention of a professional that understands their body needs. General vets are trained to cater for the needs of animals such as reptiles, birds, cats, dogs etc. ",
  },
  {
    header: "AVIAN VET",
    body: "Ducks, Chicken, Fowls and other domestic birds are properly catered for by the bird vet.",
  },
];

const images = [
  {
    img: "/img/small/dog-sick.jpg",
  },
  {
    img: "/img/small/cat-sick.jpg",
  },
  {
    img: "/img/small/turtle.jpg",
  },
  {
    img: "/img/small/small-bird.jpg",
  },
];

export default FeatureIndex;
