import { Grid, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useStyles from "../../theme/styles";
import MButton from "../Reusables/MButton";

const VisionSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const transition = {
    type: "spring",
    stiffness: 200,
    damping: 30,
    ease: "easeOut",
  };

  return (
    <section className={classes.aboutContainer}>
      <Grid
        container
        spacing={3}
        className={classes.mt20}
        direction={matches ? "column-reverse" : "row"}
      >
        <Grid item xs={12} md={6}>
          <Typography
            className={clsx(classes.teamHeader2, classes.mb20)}
            gutterBottom
          >
            Who we are
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            We are a technology driven company focused on the development of
            mobile softwares aimed at bringing convenient world class services
            to Individuals (B2C), Corporate Organisations (B2B), and Government
            Agencies (B2G) geared towards Empowerment and Self-sufficiency.
          </Typography>
          <motion.div
            whileTap={{
              scale: 0.8,
            }}
            className={classes.fitContent}
          >
            <MButton
              variant="contained"
              color="primary"
              component={Link}
              to="/details/products"
            >
              Explore our products
            </MButton>
          </motion.div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            className={clsx(
              classes.overflowHidden,
              classes.aboutCultureWrapper
            )}
          >
            <motion.img
              whileHover={{
                scale: 1.1,
              }}
              transition={transition}
              src="/img/large/collab.jpg"
              alt="visionImg"
              className={classes.aboutCultureImg}
              draggable="false"
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default VisionSection;
