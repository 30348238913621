import React from "react";
import {
  ListItem,
  ListItemText,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Close } from "@material-ui/icons";
import useStyles from "../../theme/styles";
import { useHistory, useRouteMatch } from "react-router";
import { navigate } from "../../utils/navigate";
import { AnimatePresence, motion } from "framer-motion";
import ReactGA from "react-ga";

export default function DropdownMenu({ anchorEl, handleClose, openDropdown }) {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();

  const handleRoute = (path) => {
    navigate(history, path);
    handleClose();
  };
  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 50,
    ease: "easeOut",
  };

  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
    exit: { opacity: 0 },
  };

  const item = {
    hidden: {
      x: -300,
      opacity: 0,
      transition,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition,
    },
    exit: {
      x: -300,
      opacity: 0,
      transition,
    },
  };

  const slideInRight = {
    hidden: {
      x: 300,
      opacity: 0,
      rotate: "360deg",
      transition,
    },
    visible: {
      x: 0,
      opacity: 1,
      rotate: "0deg",
      transition,
    },
    exit: {
      x: 300,
      opacity: 0,
      transition,
    },
  };
  const slideInLeft = {
    hidden: {
      x: -300,
      opacity: 0,
      transition,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition,
    },
    exit: {
      x: -300,
      opacity: 0,
      transition,
    },
  };

  const open = Boolean(anchorEl);

  return (
    <AnimatePresence key={openDropdown}>
      <Drawer
        anchor="top"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: "100vh",
            color: "#fff",
            backgroundColor: "#000",
            overflow: "unset",
            overflowY: "scroll",
            zIndex: 900,
            overflowX: "hidden",
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
      >
        <Grid
          container
          justify="space-between"
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Grid item>
            <motion.div
              variants={slideInLeft}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Typography
                variant="h5"
                style={{
                  marginLeft: 24,
                  marginTop: 6.5,
                  textDecoration: "none",
                  color: "#fff",
                  display: "block",
                }}
                onClick={() => handleRoute("/")}
              >
                Germiny
              </Typography>
            </motion.div>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              onClick={handleClose}
              style={{
                marginRight: 26,
              }}
              edge="end"
              variant="text"
              size="small"
            >
              <motion.div
                variants={slideInRight}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <Close style={{ color: "#fff" }} fontSize="large" />
              </motion.div>
            </IconButton>
          </Grid>
        </Grid>
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <ListItem
            button
            disableGutters
            className={classes.dropdownMenuItems}
            onClick={() => handleRoute("/")}
          >
            <ListItemText
              primary={<motion.div variants={item}>Home</motion.div>}
              className={clsx(classes.menuText, {
                [classes.activeLink2]: match.path === "/",
              })}
            />
          </ListItem>

          <ListItem
            button
            disableGutters
            className={classes.dropdownMenuItems}
            onClick={() => handleRoute("/about")}
          >
            <ListItemText
              primary={<motion.div variants={item}>About Us</motion.div>}
              className={clsx(classes.menuText, {
                [classes.activeLink2]: match.path === "/about",
              })}
            />
          </ListItem>
          <ListItem
            button
            disableGutters
            className={classes.dropdownMenuItems}
            onClick={() => handleRoute("/contact")}
          >
            <ListItemText
              primary={<motion.div variants={item}>Contact Us</motion.div>}
              className={clsx(classes.menuText, {
                [classes.activeLink2]: match.path === "/contact",
              })}
            />
          </ListItem>
          <ListItem
            button
            className={classes.dropdownMenuItems}
            onClick={() => {
              ReactGA.event({
                category: "Product Click",
                action: "Checked the product page",
                label: "Product Page",
              });
              handleRoute("/details/products");
            }}
          >
            <ListItemText
              primary={<motion.div variants={item}>Products</motion.div>}
              className={clsx(classes.menuText, {
                [classes.activeLink2]: match.path === "/details/products",
              })}
            />
          </ListItem>
          <ListItem
            button
            className={classes.dropdownMenuItems}
            onClick={() => handleRoute("/blog")}
          >
            <ListItemText
              primary={<motion.div variants={item}>Blog</motion.div>}
              className={clsx(classes.menuText, {
                [classes.activeLink2]: match.path === "/blog",
              })}
            />
          </ListItem>
        </motion.div>
      </Drawer>
    </AnimatePresence>
  );
}
