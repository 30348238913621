import { Hidden } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import useStyles from "../../../theme/styles";
import GerminyPhonesSvg from "../../Reusables/GerminyPhonesSvg";

const Splash = ({ isLoadContent, isLoad }) => {
  const classes = useStyles();

  const fadeOut = {
    hidden: {
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        ease: "easeOut",
      },
    },
    visible: (isLoad) => ({
      opacity: isLoad ? 1 : 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
      },
    }),
    exit: {
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        ease: "easeOut",
      },
    },
  };

  const contentWrapper = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const fadeInTextVariants = {
    hidden: {
      opacity: 0,
      x: -20,
    },
    visible: (isLoadContent) => ({
      opacity: isLoadContent ? 1 : 0,
      x: isLoadContent ? 0 : -20,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
      },
    }),
    exit: {
      opacity: 0,
    },
  };

  const imageVariants = {
    hidden: {
      opacity: 0,
      x: 20,
    },
    visible: (isLoadContent) => ({
      opacity: isLoadContent ? 1 : 0,
      x: isLoadContent ? 0 : 20,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
      },
    }),
    exit: {
      opacity: 0,
    },
  };

  return (
    <AnimatePresence initial={true}>
      <motion.div
        custom={isLoad}
        variants={fadeOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={clsx(
          classes.splashContainer,
          classes.container,
          classes.relativePosition
        )}
      >
        <motion.div
          variants={contentWrapper}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={clsx(classes.centerAbsolute2)}
          style={{ width: "100%", paddingTop: 60 }}
        >
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item xs={12} md={3} className={classes.relativePosition}>
              <motion.div
                variants={fadeInTextVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                custom={isLoadContent}
              >
                <Typography align="center" className={clsx(classes.teamHeader)}>
                  We Offer Amazing Products
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Hidden smDown>
                <GerminyPhonesSvg {...{ imageVariants, isLoadContent }} />
              </Hidden>
            </Grid>
          </Grid>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Splash;
