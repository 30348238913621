import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

const DatePickerField = ({ label, ...rest }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        autoOk
        label={label}
        fullWidth
        inputVariant="outlined"
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerField;
