import { useState } from "react";
import { Typography, Grid, Tooltip, withStyles } from "@material-ui/core";

import AppleBtnSvg from "./AppleBtnSvg";
import GooglePlay from "./GooglePlay";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 11,
    borderRadius: 0,
    padding: 24,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip);

const AppButtons = ({ clsx = null, classes, user, ...rest }) => {
  const [openAndroid, setOpenAndroid] = useState(false);
  const [openIOS, setOpenIOS] = useState(false);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  return (
    <div {...rest}>
      <Grid container spacing={1} alignItems="center" {...rest}>
        <Grid item>
          {/* <LightTooltip
            arrow
            placement="top"
            open={openAndroid}
            onClose={() => setOpenAndroid(false)}
            onOpen={() => setOpenAndroid(true)}
            title={
              <>
                <ErrorOutlineIcon color="secondary" fontSize="large" />
                <Typography>
                  Our Android application is currently under development, we
                  appreciate your patience, thank you.
                </Typography>
              </>
            }
            aria-label="Our Android application is currently under development, we appreciate your patience, thank you."
          >

          </LightTooltip> */}
          <Typography
            component="a"
            href={
              user === "user"
                ? "https://play.google.com/store/apps/details?id=com.germiny_user"
                : "https://play.google.com/store/apps/details?id=com.germinypract"
            }
            rel="noopener"
            target="_blank"
            className={clsx(classes.link3)}
            // onClick={() => setOpenAndroid(true)}
          >
            <GooglePlay />
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            disabled={user === "user"}
            component="a"
            href={
              user === "user"
                ? "https://apps.apple.com/us/app/germiny/id6670414016"
                : "https://apps.apple.com/us/app/germiny-practitioner/id6451323328"
            }
            rel="noopener"
            target="_blank"
            className={clsx(classes.link3)}
            // onClick={() => setOpenIOS(true)}
          >
            <AppleBtnSvg />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default AppButtons;
