import colors from "../theme/colors";

export const transition = {
  type: "spring",
  stiffness: 600,
  damping: 60,
  ease: "easeOut",
  duration: 5,
  delay: 0.3,
};

export const pageTransition = {
  hidden: {
    opacity: 0,
    backgroundColor: colors.black,
    transition,
  },
  visible: {
    opacity: 1,
    backgroundColor: colors.white,
    transition,
  },
  exit: {
    opacity: 0,
    transition,
  },
};
