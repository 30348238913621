import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import useStyles from "../../theme/styles";

const HeroAbout = () => {
  const classes = useStyles();

  const imageVariants = {
    hidden: {
      scale: 1.1,
    },
    visible: {
      scale: 1,
      transition: {
        scale: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          ease: "easeIn",
        },
      },
    },
    exit: {
      scale: 1.1,
    },
  };
  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 60,
    ease: "easeOut",
  };

  const titleWrapperVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const titleVariant = {
    hidden: {
      y: -50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
    exit: {
      y: -50,
      opacity: 0,
      transition,
    },
  };

  return (
    <motion.div
      variants={imageVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={clsx(classes.aboutHeroBg)}
    >
      <motion.div
        variants={titleWrapperVariant}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={clsx(classes.aboutHeroTitle, classes.container)}
      >
        <motion.div variants={titleVariant}>
          <Typography gutterBottom className={clsx(classes.teamHeader)}>
            About Us
          </Typography>
        </motion.div>
        <motion.div variants={titleVariant}>
          <Typography gutterBottom className={clsx(classes.boldText2)}>
            Redefining existence through technology.
          </Typography>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default HeroAbout;
