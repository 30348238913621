import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import useStyles from "../../../../theme/styles";
import MAccordion from "../../../Reusables/MAccordion";

const FAQs = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.mt30}>
      <div className={classes.container}>
        <Typography className={clsx(classes.subtitle)} gutterBottom>
          Frequently Asked Questions
        </Typography>
      </div>
      {contents.map((item, i) => (
        <MAccordion
          key={i}
          title={item.title}
          index={i}
          expanded={expanded}
          handleChange={handleChange}
        >
          {item.body}
        </MAccordion>
      ))}
    </div>
  );
};

const contents = [
  {
    title:
      "I don't work full-time. Can I use the Germiny Practitioner Application for just a few hours a week?",
    body: "Yes of course, some of our Practitioners/Veterinarians are only active a few hours during weekends and make solid part-time income.",
  },
  {
    title: "How do I get paid as a Practitioner/Veterinarian?",
    body: "Our Germiny Users/Customers pay you in cash or via their debit cards. We pay out to your bank account every week.",
  },
  {
    title: "How much do I pay Germiny?",
    body: "We do not have any monthly fees, so you only pay for finished Diagnosis with our Users/Customers. We usually ask for 10% to 35% of the Diagnosis fee, depending on the time used or overall average rating by our users/customers (Terms and Conditions Apply). You can get more information by contacting us.",
  },
  {
    title: "How long does it take to sign up?",
    body: "Submitting your information takes just 5 minutes. After that you will be invited for a 30 minutes physical verification and training in our office.",
  },
];

export default FAQs;
