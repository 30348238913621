import { Typography, Grid, Paper } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../theme/styles";

const Culture = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid
        container
        component={Paper}
        square
        elevation={0}
        className={clsx(
          classes.overflowHidden,
          classes.bgBlack,
          classes.textWhite,
          classes.mt50
        )}
      >
        <Grid item xs={12} md={6} className={classes.aboutOurCultureImg}></Grid>
        <Grid item xs={12} md={6} className={clsx(classes.container)}>
          <Typography gutterBottom className={clsx(classes.teamHeader2)}>
            Our Culture
          </Typography>
          <Typography className={clsx(classes.boldText)}>
            An inclusive culture of like-minded people driven by love,
            integrity, empathy, innovation and commitment focused on the
            upliftment of humans and animals across the world.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Culture;
