import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";

import useStyles from "../../theme/styles";

const PrivacyComponent = () => {
  const classes = useStyles();

  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 60,
    ease: "easeOut",
  };

  const titleWrapperVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const titleVariant = {
    hidden: {
      y: -50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
    exit: {
      y: -50,
      opacity: 0,
      transition,
    },
  };

  return (
    <div>
      <div className={clsx(classes.policyContainer)}>
        <motion.div
          variants={titleWrapperVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={clsx(
            classes.aboutHeroTitle,
            classes.container,
            classes.textWhite
          )}
        >
          <motion.div variants={titleVariant}>
            <Typography
              gutterBottom
              className={clsx(classes.teamHeader2, classes.mb50)}
              align="center"
            >
              PRIVACY AND SAFETY POLICY
            </Typography>
          </motion.div>
        </motion.div>
      </div>

      <div className={classes.container}>
        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            PLEASE READ THIS PRIVACY POLICY CAREFULLY. IF YOU DO NOT AGREE WITH
            THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE
            APPLICATION.
          </Typography>
        </div>
        <div className={classes.mb50}>
          <Typography className={classes.boldText} gutterBottom>
            We reserve the right to make changes to this Privacy Policy at any
            time and for any reason. We will alert you about any changes by
            updating the “Last updated” date of this Privacy Policy. You are
            encouraged to periodically review this Privacy Policy to stay
            informed of updates. You will be deemed to have been made aware of,
            will be subject to, and will be deemed to have accepted the changes
            in any revised Privacy Policy by your continued use of the
            Application after the date such revised Privacy Policy is posted.
          </Typography>
        </div>
        <div className={classes.mb50}>
          <Typography className={classes.boldText} gutterBottom>
            This Privacy Policy does not apply to the third-party online/mobile
            store from which you install the Application or make payments,
            including any in-game virtual items, which may also collect and use
            data about you. We are not responsible for any of the data collected
            by any such third party.
          </Typography>
        </div>
        <div className={classes.mb50}>
          <Typography className={classes.boldText} gutterBottom>
            Privacy Notice describes the information we collect, how it is used
            and shared, and your choices regarding this information. This policy
            applies to any users of the services of or its affiliates anywhere
            in the world, and to anyone else who contacts the (Company name) or
            otherwise submits information to the (Company name), unless noted in
            the Privacy Notice.
          </Typography>
          <Typography className={classes.boldText} gutterBottom>
            This notice specifically applies to:
          </Typography>
          <div className={classes.boldText}>
            <ul>
              <li>
                Users: individuals who request or receive medical attention,
                including those who receive medical attention requested by
                another individual
              </li>
              <li>
                Practitioners: individuals who render medical services to users
                individually or through partner medical outlets
              </li>
              <li>
                Delivery persons: individuals who provide delivery or other
                services
              </li>
            </ul>
          </div>
          <Typography className={classes.boldText} gutterBottom>
            Data practices are subject to applicable laws in the places in which
            we operate. This means that we engage in the practices described in
            this notice in a particular country or region only if permitted
            under the laws of those places.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            1. Data Collected
          </Typography>
          <div className={classes.boldText}>
            <strong>Germiny collects:</strong>
            <ul>
              <li>
                Data provided by users during account creation. email, phone
                number, login name and password, address, profile picture,
                payment or banking information (including related payment
                verification information), practitioner’s license and other
                government identification This may include their name, documents
                (which may indicate document numbers as well as birth date,
                gender, and photo). This also includes vehicle or insurance
                information of drivers and delivery persons, emergency contact
                information, user settings, and evidence of health or fitness to
                provide services using the (Company name) apps.
              </li>
              <li>
                Data created during use of our services, such as location, app
                usage, and device data
              </li>
              <li>
                Data from other sources, such as third parties that use the app.
                This include user feedbacks/compliments, account owners who
                request the Company’s services for or on behalf of other users,
                Insurance, vehicle, or financial services pro viders for
                practitioners and/or delivery persons, publicly available
                sources, marketing service providers, and partner medical
                outlets companies (for practitioners or delivery persons who use
                our services through an account associated with such a company).
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            2. Use of Personal Data
          </Typography>

          <div className={classes.boldText}>
            <strong>
              Germiny use data to enable reliable and convenient medical
              services, delivery, and other products and services. We also use
              the data we collect:
            </strong>
            <ul>
              <li>
                To enhance the safety and security of our users and services
              </li>
              <li>For customer support</li>
              <li>For research and development</li>
              <li>To enable communications between users</li>
              <li>
                To send marketing and non-marketing communications to users
              </li>
              <li>In connection with legal proceedings</li>
            </ul>
            <strong>
              Germiny does not sell or share user personal data with third
              parties for their direct marketing, except with users’ consent.
            </strong>
          </div>
        </div>

        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            3. Financial Data
          </Typography>
          <Typography className={classes.boldText} gutterBottom>
            Financial information, such as data related to your payment method
            (e.g. valid credit card number, card brand, expiration date) that we
            may collect when you purchase, order, return, exchange, or request
            information about our services from the Application. We store only
            very limited, if any, financial information that we collect.
            Otherwise, all financial information is stored by our payment
            processor, Paystack, and you are encouraged to review their privacy
            policy and contact them directly for responses to your questions.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            4. Mobile Device Access
          </Typography>
          <Typography className={classes.boldText} gutterBottom>
            We may request access or permission to certain features from your
            mobile device, including your mobile device’s camera, location,
            contacts, photos/media/files. If you wish to change our access or
            permissions, you may do so in your device’s settings.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            5. Mobile Device Data
          </Typography>
          <Typography className={classes.boldText} gutterBottom>
            Device information such as your mobile device ID number, model, and
            manufacturer, version of your operating system, phone number,
            country, location, and any other data you choose to provide.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            6. Safety and Security
          </Typography>
          <div className={classes.boldText}>
            We use personal data to help maintain the safety, security, and
            integrity of our services and users. This includes:
            <ul>
              <li>
                Screening practitioners before enabling their use of our
                services and at subsequent intervals, including through reviews
                of background checks, where permitted by law, to help prevent
                use of our services by unsafe or unqualified practitioners.
              </li>
              <li>
                Using device, location, profile, usage, and other data to
                prevent, detect, and combat fraud or unsafe activities.
              </li>
              <li>
                Using user ratings and feedback to encourage compliance with our
                Community Guidelines and as grounds for deactivating
                practitioners and delivery persons with low ratings or who
                otherwise violated such guidelines in certain countries.
              </li>
            </ul>
          </div>
        </div>

        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            7. Disclosure of Personal Information
          </Typography>
          <Typography className={classes.boldText} gutterBottom>
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follow:
          </Typography>
          <Typography className={classes.boldText} gutterBottom>
            - By Law or to Protect Rights If we believe the release of
            information about you is necessary to respond to legal process, to
            investigate or remedy potential violations of our policies, or to
            protect the rights, property, and safety of others, we may share
            your information as permitted or required by any applicable law,
            rule, or regulation. This includes exchanging information with other
            entities for fraud protection and credit risk reduction.
          </Typography>
        </div>

        <div className={classes.mb50}>
          <Typography className={classes.boldText2} gutterBottom>
            8. Data retention and deletion
          </Typography>
          <Typography className={classes.boldText} gutterBottom>
            Following an account deletion request, the Company deletes the
            user’s account and data, unless they must be retained due to legal
            or regulatory requirements, for purposes of safety, security, and
            fraud prevention, or because of an issue relating to the user’s
            account such as an outstanding credit or an unresolved claim or
            dispute. Because we are subject to legal and regulatory requirements
            relating to practitioners, this generally means that we retain their
            account and data for a minimum of 7 years after a deletion request.
            For drivers, their data is generally deleted within 90 days of a
            deletion request, except where retention is necessary for the above
            reasons.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PrivacyComponent;
