import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useStyles from "../../theme/styles";
import MButton from "../Reusables/MButton";

const CompanyInfo = () => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.companyInfoContainer,
        classes.container,
        classes.mt20
      )}
    >
      <div className={clsx(classes.mt30, classes.mb50)}>
        <Typography
          className={clsx(classes.teamHeader2, classes.textWhite)}
          gutterBottom
        >
          {/* Company Info */}
          Leadership
        </Typography>
        <Typography
          className={clsx(classes.textWhite, classes.boldText)}
          gutterBottom
        >
          We are building an empowerment culture at Germiny that aims to enrich
          lives through technology.
        </Typography>
        <Typography
          className={clsx(classes.textWhite, classes.boldText)}
          gutterBottom
        >
          To find out more about the brains behind the curtain.
        </Typography>
        <motion.div
          className={clsx(classes.fitContent)}
          whileTap={{
            scale: 0.8,
          }}
        >
          <MButton
            variant="outlined"
            extraStyle={clsx(classes.btnWhite)}
            component={Link}
            to="/leadership"
          >
            Learn More
          </MButton>
        </motion.div>
      </div>
    </div>
  );
};

export default CompanyInfo;
