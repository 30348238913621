import { Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import ProgressiveImage from "react-progressive-graceful-image";
import useStyles from "../../theme/styles";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as RightArrow } from "../../theme/assets/right-arrow.svg";
import MButton from "../Reusables/MButton";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Features = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item md={6}>
          <div className={clsx(classes.featureImg)}>
            <ProgressiveImage
              delay={3000}
              src={`/img/large/joy-kid.jpeg`}
              placeholder={`/img/large/joy-kid.jpeg`}
              style={{
                backgroundColor: "#000",
              }}
            >
              {(src) => (
                <img src={src} alt="Feature img" className={classes.img} draggable="false" />
              )}
            </ProgressiveImage>
          </div>
        </Grid>
        <Grid
          item
          md={6}
          component={Paper}
          square
          elevation={0}
          className={clsx(
            classes.bgBlack,
            classes.container,
            classes.textWhite
          )}
        >
          <Typography
            gutterBottom
            className={clsx(classes.subtitle, classes.mt60)}
          >
            Your wellbeing is our ultimate priority
          </Typography>
          <Typography gutterBottom className={classes.text}>
            To find out more about Germiny&reg;
          </Typography>

          <motion.div
            className={classes.fitContent}
            whileTap={{
              scale: 0.9,
            }}
          >
            <MButton
              variant="outlined"
              extraStyle={classes.btnWhite}
              endIcon={<MIcon src={RightArrow} />}
              component={Link}
              to="/about"
            >
              Explore
            </MButton>
          </motion.div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Features;
