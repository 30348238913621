import {
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import { useState, useEffect } from "react";
import useStyles from "../../theme/styles";
import RightIcon from "../Reusables/RightIcon";
import LeftArrow from "../Reusables/LeftArrow";

const MissionVisionWrapper = () => {
  const classes = useStyles();
  const [[page, direction], setPage] = useState([0, 0]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (page > (images.length - 1 || contents.length - 1)) {
      setPage([0, direction]);
    } else if (page < 0) {
      setPage([images.length - 1 || contents.length - 1, direction]);
    }
  }, [page, direction]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const imageVariants = {
    hidden: (direction) => ({
      opacity: 0,
      // x: direction > 0 ? -100 : 100,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
        delay: 0.5,
      },
    }),
    visible: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 600,
        damping: 60,
        ease: "easeOut",
        delay: 0.1,

        // duration: 1.5,
      },
    },
    exit: (direction) => ({
      opacity: 0,
      // x: direction > 0 ? -100 : 100,
      // scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 60,
        ease: "easeOut",
      },
    }),
  };

  const textWrapperVariant = {
    hidden: {
      // opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const textVariant = {
    hidden: (direction) => ({
      y: direction > 0 ? 50 : -50,
      opacity: 0,
    }),
    visible: {
      y: 0,
      opacity: 1,

      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
      },
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };

  const contentsIndex = wrap(0, contents.length, page);
  const imagesIndex = wrap(0, images.length, page);

  return (
    <AnimatePresence initial={false} custom={direction}>
      <div className={clsx(classes.overflowHidden, classes.relativePosition)}>
        <motion.div className={clsx(classes.missionWrapper, classes.mt50)}>
          <Grid
            container
            spacing={0}
            justify="flex-end"
            alignItems="center"
            className={clsx(classes.aboutValuesPaginateAbs)}
          >
            <Grid item>
              <IconButton size="small" onClick={() => paginate(-1)}>
                <LeftArrow page={page} size={35} color="#fff" />
              </IconButton>
            </Grid>
            <Grid item>
              <motion.div
                key={page}
                className={clsx(classes.infoCounter, classes.textWhite)}
              >
                {page === 1 ? "02" : page === 2 ? "03" : "01"}
              </motion.div>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => paginate(1)}>
                <RightIcon page={page} size={35} color="#fff" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            container
            direction={matches ? "column-reverse" : "row"}
            component={Paper}
            className={clsx(
              classes.overflowHidden,
              classes.wrapper,
              classes.relativePosition
            )}
            square
            elevation={0}
          >
            <Grid item md={6} xs={12}>
              {contents.map((item, i) => (
                <motion.div
                  key={`${page}-${i}`}
                  className={clsx(
                    classes.slider,
                    classes.container,
                    classes.bgBlack,
                    classes.textWhite
                  )}
                  style={{
                    display: i === contentsIndex ? "block" : "none",
                    transition: "all 300ms ease-in-out",
                  }}
                  variants={textWrapperVariant}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <motion.div variants={textVariant} custom={direction}>
                    <Typography gutterBottom className={classes.teamHeader2}>
                      {item.title}
                    </Typography>
                  </motion.div>
                  <motion.div
                    variants={textVariant}
                    custom={direction}
                    className={classes.pb30}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.body,
                      }}
                      className={clsx(classes.text)}
                    />
                  </motion.div>
                </motion.div>
              ))}
            </Grid>

            <Grid item md={6} xs={12}>
              <motion.div
                key={`${page}-ii`}
                className={clsx(classes.bgBlack, classes.relativePosition)}
              >
                <div className={classes.overlayBlack2} />

                {images.map((item, i) => (
                  <motion.img
                    variants={imageVariants}
                    initial="hidden"
                    animate="visible"
                    custom={direction}
                    exit="exit"
                    key={i}
                    alt={`bg-${i}`}
                    className={classes.wrapperImg}
                    src={item}
                    style={{
                      display: i === imagesIndex ? "block" : "none",
                      objectPosition:
                        (imagesIndex === images.length - 1 ||
                          imagesIndex === images.length - 2) &&
                        "center",
                    }}
                  />
                ))}
              </motion.div>
            </Grid>
          </Grid>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

const images = [
  "/img/large/group-sun.jpeg",
  "/img/large/team-meeting.jpg",
  "/img/large/values.jpeg",
];
const contents = [
  {
    title: "Our Mission",
    body: "To give those in need of empowerment in the world a fair chance to one, through the help of Empathy, Collaboration, Technology and Innovation.",
  },
  {
    title: "Our Vision",
    body: "To be the number one avenue in the world known for various innovative products created for the upliftment of humans and animals with the help of empathy and technology.",
  },
  {
    title: "Our Values",
    body: "<p><strong>INTEGRITY</strong>: Truth and honesty is our watch word; we do the right thing and we give the best to the society in which we find ourselves both professionally and in our personal lives. </p> <p><strong>EMPATHY</strong>: We are considerate and we place ourselves into every situation in other to understand everyone’s feelings and to derive the best value from it. </p> <strong>INNOVATION</strong>: Just as the name implies, we are particular about birthing to life new technologies that brings convenience to the world at large for the betterment of every individual. </p> <p><strong>COMMITMENT</strong>: We are dedicated to providing the best and affordable services to every individual and we also offer continuous improvements including immediate response to feedbacks from our customers.</p>",
  },
];

export default MissionVisionWrapper;
