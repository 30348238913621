import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useState } from "react";
import useStyles from "../../../theme/styles";
import AppButtons from "../../Reusables/AppButtons";

const Hero = () => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const transition = {
    type: "spring",
    stiffness: 200,
    damping: 30,
    ease: "easeOut",
    delay: 0.1,
  };

  return (
    <div className={classes.relativePosition}>
      <div className={clsx(classes.appHeroContainer, classes.container)}>
        <Paper
          square
          className={clsx(
            classes.overflowHidden,
            classes.mt50,
            classes.mb50,
            classes.glass
          )}
        >
          <Grid container direction={matches ? "column-reverse" : "row"}>
            <Grid item xs={12} md={6}>
              <div
                className={clsx(
                  classes.container,
                  classes.relativePosition,
                  classes.appTextContainer
                )}
              >
                <Typography className={classes.absText}>Explore</Typography>
                <div className={clsx(classes.z3)}>
                  <Typography gutterBottom className={clsx(classes.subtitle)}>
                    Germiny Application
                  </Typography>
                  <Typography gutterBottom className={classes.boldText}>
                    At Germiny, we are all about empowerment with the help of
                    technology; leveraging on Geolocation and Maps to give our
                    users a chance to get affordable services wherever you are.
                  </Typography>
                  <AppButtons
                    {...{ clsx, classes }}
                    user="user"
                    className={classes.mt20}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              {matches ? (
                <img
                  src="/img/small/checking-phone.jpeg"
                  alt="check phone"
                  className={classes.slideImg}
                />
              ) : (
                <motion.div
                  onHoverStart={() => setHover(true)}
                  onHoverEnd={() => setHover(false)}
                  className={classes.fitContent}
                >
                  <div className={clsx(classes.overflowHidden)}>
                    <div
                      className={clsx(classes.slides, classes.relativePosition)}
                    >
                      <motion.img
                        src="/img/small/checking-phone.jpeg"
                        alt="check phone"
                        animate={{
                          x: hover ? -500 : 0,
                        }}
                        className={classes.slideImg}
                        transition={transition}
                        draggable="false"
                      />

                      <motion.img
                        src="/img/small/google-maps.jpeg"
                        alt="Maps"
                        animate={{
                          x: hover ? -500 : 0,
                        }}
                        className={classes.slideImg}
                        transition={transition}
                        draggable="false"
                      />
                    </div>
                  </div>
                </motion.div>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default Hero;
