import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import useStyles from "../../theme/styles";

const ContactHeader = () => {
  const classes = useStyles();

  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 60,
    ease: "easeOut",
  };

  const titleWrapperVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const titleVariant = {
    hidden: {
      y: -50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
    exit: {
      y: -50,
      opacity: 0,
      transition,
    },
  };

  return (
    <AnimatePresence initial={true}>
      <div className={clsx(classes.contactHeader, classes.container)}>
        <motion.div
          variants={titleWrapperVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={clsx(classes.aboutHeroTitle, classes.container)}
        >
          <motion.div variants={titleVariant}>
            <Typography gutterBottom className={clsx(classes.teamHeader)}>
              Contact Us
            </Typography>
          </motion.div>
          <motion.div variants={titleVariant}>
            <Typography gutterBottom className={clsx(classes.boldText2)}>
              Let's keep in touch.
            </Typography>
          </motion.div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default ContactHeader;
