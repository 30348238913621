import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Typography,
  Grid,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { comingSoonLogic } from "../../features/info/infoSlice";
import useStyles from "../../theme/styles";
import { pageTransition } from "../../utils/transition";
import InputField from "../Fields/InputField";
import Wrapper from "../Wrapper";
import MButton from "./MButton";
import Loader from "./Loader";
import { organizationStructuredData } from "../../structured-data";

const initialState = {
  email: "",
};

const ComingSoon = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const { loading } = useSelector((state) => state.info);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(comingSoonLogic(state))
      .then(unwrapResult)
      .then((doc) => {
        Swal.fire({
          title: "Success",
          text: doc?.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            setState(initialState);
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "",
          text: err?.error,
          icon: "warning",
        }).then((result) => {
          if (result.isConfirmed) {
            setState(initialState);
          }
        });
      });
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://germiny.org${location.pathname}`}
        />
        <title>
          Germiny&reg; - This page is currently undergoing development, you can
          sign up to get latest updates.
        </title>
        <meta property="og:title" content="Coming Soon" />
        <meta property="og:site_name" content="Germiny" />
        <meta property="og:url" content="https://germiny.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="At Germiny®, we work to provide the best solutions to global issues, through the power of technology and team work."
        />

        <script type="application/ld+json">
          {organizationStructuredData({
            headline: "Coming Soon",
            authorName: "Arifayan Idowu",
            description:
              "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
          })}
        </script>
      </Helmet>
      <Wrapper>
        <motion.div
          layout
          variants={pageTransition}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={clsx(
            classes.comingSoonContainer,
            classes.relativePosition
          )}
        >
          <Typography
            gutterBottom
            className={clsx(classes.comingSoonText, classes.mt60)}
          >
            Coming Soon
          </Typography>
          <Typography
            gutterBottom
            className={clsx(
              classes.mt20,
              classes.textUpper,
              classes.lightGreyText
            )}
          >
            Get Notified when it's ready
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <InputField
                  type="email"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  className={clsx(classes.bgWhite)}
                  placeholder="Enter your email address"
                  required
                />

                <MButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <Loader /> : "Notify Me"}
                </MButton>
              </Grid>
            </Grid>
          </form>
        </motion.div>
      </Wrapper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ComingSoon;
