import { Typography } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../../../theme/styles";
import AppButtons from "../../../Reusables/AppButtons";

const GetApps = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, classes.getAppContainer)}>
      <div className={classes.mb50}>
        <Typography
          gutterBottom
          className={clsx(classes.subtitle, classes.mt40)}
        >
          Do More in the App
        </Typography>
        <div className={classes.mt20}>
          <Typography gutterBottom>Download our mobile app</Typography>
          <AppButtons {...{ clsx, classes }} user="pract" />
        </div>
      </div>
    </div>
  );
};

export default GetApps;
