import { Typography, Grid, Paper } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../theme/styles";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as ClockIcon } from "../../theme/assets/clock.svg";
import { ReactComponent as ArrowChart } from "../../theme/assets/arrow-chart.svg";
import { ReactComponent as NoMoney } from "../../theme/assets/no-money.svg";

const Benefit = ({ title = "" }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, classes.benefitContainer)}>
      <Typography
        className={clsx(classes.subtitle, classes.mb20, classes.mt50)}
        gutterBottom
      >
        Why Germiny?
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper
            className={clsx(
              classes.textCenter,
              classes.cards,
              classes.paperBorder,
              classes.paperTransparent
            )}
            square
            elevation={0}
          >
            <MIcon src={ArrowChart} fontSize="large" color="disabled" />
            <Typography gutterBottom className={classes.boldText2}>
              Earn Money
            </Typography>
            <Typography gutterBottom>
              you can earn extra income on the side.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            className={clsx(
              classes.textCenter,
              classes.cards,
              classes.paperBorder,
              classes.paperTransparent
            )}
            square
            elevation={0}
          >
            <MIcon src={ClockIcon} fontSize="large" color="disabled" />

            <Typography gutterBottom className={classes.boldText2}>
              Flexibility
            </Typography>
            <Typography gutterBottom>
              No minimum or maximum hours and no supervisor or boss.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            className={clsx(
              classes.textCenter,
              classes.cards,
              classes.paperBorder,
              classes.paperTransparent
            )}
            square
            elevation={0}
          >
            <MIcon
              src={NoMoney}
              viewBox="0 0 511.697 511.697"
              fontSize="large"
              color="disabled"
            />

            <Typography gutterBottom className={classes.boldText2}>
              No Monthly Payments
            </Typography>
            <Typography gutterBottom>
              Zero Risk, you only get paid when you earn.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <div className={clsx(classes.mt50, classes.mb50)}>
        <Typography gutterBottom className={clsx(classes.subtitle)}>
          {title}
        </Typography>
        <ol>
          <li>
            <Typography gutterBottom className={clsx(classes.text)}>
              Accept the request
            </Typography>
          </li>
          <li>
            <Typography gutterBottom className={clsx(classes.text)}>
              Visit the User/Client
            </Typography>
          </li>
          <li>
            <Typography gutterBottom className={clsx(classes.text)}>
              Carry out the Primary Health Care Service
            </Typography>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Benefit;
