import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import useStyles from "../../theme/styles";

const LeadershipHeader = () => {
  const classes = useStyles();

  const titleVariant = {
    hidden: {
      y: -50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
      },
    },
    exit: {
      y: -50,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      layout
      className={clsx(classes.container, classes.leadershipHeader)}
    >
      <div className={clsx(classes.aboutHeroTitle, classes.container)}>
        <motion.div
          variants={titleVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Typography className={clsx(classes.teamHeader)}>
            Leadership
          </Typography>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default LeadershipHeader;
