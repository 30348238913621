import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useStyles from "../../theme/styles";
import MButton from "../Reusables/MButton";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as RightArrow } from "../../theme/assets/right-arrow.svg";

const Connect = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.connectContainer, classes.container)}>
      {/* <div className={classes.overlay} /> */}

      <div
        className={clsx(
          classes.relativePosition,
          classes.z3,
          classes.mt20,
          classes.mb20
        )}
      >
        <Typography className={clsx(classes.lightGreyText)}>
          Germiny&reg;
        </Typography>
        <Typography gutterBottom className={clsx(classes.subtitle)}>
          {/* We Connect doctors to the world */}
          Redefining existence through technology
        </Typography>
        <Typography className={classes.boldText} gutterBottom>
          Explore our amazing products
        </Typography>

        <motion.div
          whileTap={{
            scale: 0.9,
            x: 0,
          }}
          style={{
            width: "fit-content",
          }}
        >
          <MButton
            variant="contained"
            color="primary"
            endIcon={<MIcon src={RightArrow} />}
            extraStyle={classes.btn}
            component={Link}
            to="/details/products"
          >
            View Products
          </MButton>
        </motion.div>
      </div>
    </div>
  );
};

export default Connect;
