import { Paper, Typography, Tab, Tabs, Divider } from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import useStyles from "../../../theme/styles";
import MIcon from "../../Reusables/MIcon";
import { ReactComponent as Doctor } from "../../../theme/assets/doctor.svg";
import { ReactComponent as Paw } from "../../../theme/assets/paw.svg";
import { ReactComponent as RightArrow } from "../../../theme/assets/right-arrow.svg";
import { ReactComponent as Consultation } from "../../../theme/assets/consultation.svg";
import MButton from "../../Reusables/MButton";
import { TabPanel, a11yProps } from "../../Reusables/TabPanel";
import AppButtons from "../../Reusables/AppButtons";

const HeroContent = ({ page, handleChange, links, isPositive }) => {
  const classes = useStyles();

  const contentVariants = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 30,
        stiffness: 250,
        duration: 5,
        ease: "easeOut",
        staggerChildren: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const textVariants = {
    hidden: (isPositive) => ({
      x: isPositive ? -50 : 50,
      opacity: 0,
    }),
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        x: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          duration: 5,
          ease: "easeOut",
        },
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <AnimatePresence initial={true} custom={page}>
      <motion.div
        className={clsx(classes.heroContent)}
        variants={contentVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Paper square elevation={0} className={clsx(classes.heroContentPaper)}>
          <Tabs
            value={page}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            variant="fullWidth"
          >
            <Tab
              icon={
                <MIcon
                  src={Doctor}
                  viewBox="0 0 45.759 45.759"
                  fontSize="large"
                />
              }
              label="Become a practitioner"
              {...a11yProps(0)}
            />
            <Tab
              icon={<MIcon src={Paw} fontSize="large" />}
              label="Become a veterinarian"
              {...a11yProps(1)}
            />
            <Tab
              icon={
                <MIcon
                  src={Consultation}
                  fontSize="large"
                  viewBox="0 0 200.677 200.677"
                />
              }
              label="Medical Request"
              {...a11yProps(2)}
            />
          </Tabs>
          <Divider />
          <TabPanel value={page} index={0} className={classes.tabContainer}>
            <motion.div variants={textVariants} custom={isPositive}>
              <Typography
                variant="h2"
                gutterBottom
                className={classes.heroText}
              >
                Save countless lives today
              </Typography>
            </motion.div>
            <Typography gutterBottom>
              By registering with us, as a medical practitioner.
            </Typography>
            <MButton
              variant="outlined"
              component={Link}
              to="/practitioner/register"
              endIcon={<MIcon src={RightArrow} />}
            >
              {"Get Started"}
            </MButton>
            <span>
              <Link
                to="/products/practitioner"
                className={clsx(classes.btnBlock, classes.mb20, classes.link2)}
              >
                Learn more about registering as a practitioner
              </Link>
            </span>
          </TabPanel>
          <TabPanel value={page} index={1} className={classes.tabContainer}>
            <motion.div variants={textVariants} custom={isPositive}>
              <Typography
                variant="h2"
                gutterBottom
                className={classes.heroText}
              >
                A pet is in need of your help
              </Typography>
            </motion.div>
            <Typography gutterBottom>
              Register with us, as a veterinary doctor.
            </Typography>
            <MButton
              variant="outlined"
              endIcon={<MIcon src={RightArrow} />}
              component={Link}
              to="/veterinary/register"
              size="large"
            >
              {"Get Started"}
            </MButton>
            <span>
              <Link
                to="/products/veterinary"
                className={clsx(classes.btnBlock, classes.mb20, classes.link2)}
              >
                Learn more about registering as a veterinary doctor.
              </Link>
            </span>
          </TabPanel>
          <TabPanel value={page} index={2} className={classes.tabContainer}>
            <motion.div
              variants={textVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              custom={isPositive}
            >
              <Typography
                variant="h2"
                gutterBottom
                className={classes.heroText}
              >
                To request for medical attention
              </Typography>
            </motion.div>
            <>
              <Typography>Download our app</Typography>
              <AppButtons {...{ clsx, classes }} user="user" />
            </>

            <span>
              <Link
                to="/products/applications"
                className={clsx(
                  classes.btnBlock,
                  classes.mb20,
                  classes.link2,
                  classes.mt20
                )}
              >
                Learn more about Germiny.
              </Link>
            </span>
          </TabPanel>
        </Paper>
      </motion.div>
    </AnimatePresence>
  );
};

export default HeroContent;
