import { FormControl, InputLabel, Select } from "@material-ui/core";

const SelectField = ({ label, required, children, ...rest }) => {
  return (
    <FormControl fullWidth variant="outlined" required={required}>
      <InputLabel id="label-id">{label}</InputLabel>
      <Select labelId="label-id" {...rest}>
        {children}
      </Select>
    </FormControl>
  );
};

export default SelectField;
