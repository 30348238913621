import { darken, lighten, makeStyles } from "@material-ui/core";
import colors from "./colors";
import { typography } from "./typography";

const letterSpacing = typography.letterSpacing;

const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      padding: theme.spacing(1.5, 3),
      backgroundColor: colors.primary,
      color: colors.white,
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 1),
      },
    },
    content: {
      display: "flex",
      height: "100%",
      minHeight: "auto",
      justifyContent: "space-between",
      flexDirection: "column",
      overflowY: "auto",
      background: colors.black,
      // background:
      //   theme.palette.type === "light" ? colors.white : colors.background,
    },
    fg1: {
      flexGrow: 1,
    },
    imgContainer: {
      background: colors.black,
    },
    imgSize: {
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },

    borderNone: {
      borderRadius: 0,
    },
    heroImage: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      // maxWidth: "100vw",
      height: "100%",
      objectFit: "cover",
      objectPosition: "50%",
      zIndex: 0,
      backgroundAttachment: "fixed",
    },
    container: {
      overflow: "hidden",
      position: "relative",
      padding: theme.spacing(2, 5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 3),
      },
    },
    container2: {
      overflow: "hidden",
      position: "relative",
      padding: theme.spacing(2, 5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 3),
      },
    },
    tabContainer: {
      overflow: "hidden",
      position: "relative",
      padding: theme.spacing(2, 1),
    },
    landingCounter: {
      position: "absolute",
      top: -40,
      right: 10,
      zIndex: 13,
      width: "fit-content",
      overflow: "hidden",
      "& > div": {
        fontSize: "8rem",
        fontWeight: 900,
        color: colors.fadedGrey,
        display: "inline-block",
        height: "fit-content",
      },
    },
    z9: {
      zIndex: 9,
    },
    z98: {
      zIndex: 98,
    },
    z99: {
      zIndex: 99,
    },
    landingContainer: {
      width: "100%",
      height: "75vh",
      overflow: "hidden",
      background: colors.black,

      [theme.breakpoints.only("sm")]: {
        height: "70vh",
      },
      [theme.breakpoints.only("md")]: {
        height: "60vh",
      },
      [theme.breakpoints.only("xs")]: {
        height: "70vh",
      },
      [theme.breakpoints.between("xs", 350)]: {
        height: "80vh",
      },
    },
    btnAbsolute: {
      position: "absolute",
      bottom: 15,
      right: 10,
      zIndex: 4,
    },
    landingTextContainer: {
      position: "absolute",
      padding: 30,
      marginTop: "3%",
      right: 20,
      left: 40,
      zIndex: 12,
      maxWidth: 600,
      height: 300,
      opacity: 1,
      transition: "all 300ms ease-out",
      [theme.breakpoints.down("sm")]: {
        left: 20,
        marginTop: "12%",
        height: "auto",
      },
    },
    indicatorContainer: {
      width: 2,
      height: 90,
      background: "#ccc",
      position: "absolute",
      bottom: "20%",
      left: 10,
      zIndex: 2,
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        // top: "20%",
        left: 5,
      },
    },
    indicator: {
      width: "100%",
      height: 30,
      background: "#000",
    },
    heroContent: {
      position: "relative",
      zIndex: 2,
      margin: "1% auto",
      padding: theme.spacing(2, 5),
      [theme.breakpoints.down("sm")]: {
        // margin: "0% auto",
        padding: 0,

        margin: 0,
      },
    },
    heroContentPaper: {
      maxWidth: 650,
      background: theme.palette.type === "dark" && colors.black,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100%",
      },
    },
    link: {
      position: "relative",
      color: colors.white,
      fontSize: typography.sizes[2],
      fontWeight: typography.weights.bold,
      letterSpacing: 1.05,
      textTransform: "uppercase",
      textDecoration: "none",
      display: "inline-block",
      margin: "0 10px",
      transition: "color 300ms ease-out",
      overflow: "hidden",
      paddingTop: 10,
      paddingBottom: 5,

      "&:hover": {
        color: darken(colors.white, 0.3),
        cursor: "pointer",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        transition: "transform .5s ease",
        left: 0,
        bottom: 0,
        width: "100%",
        height: 2,
        background: colors.activeLink,
        transform: "translateX(-110%)",
      },
      "&:hover:before": {
        transform: "translateX(0)",
      },
    },
    link2: {
      color: "inherit",
      fontSize: typography.sizes[5],
    },
    link3: {
      display: "inline-block",
    },
    link4: {
      color: "inherit",
    },
    logoLink: {
      color: "inherit",
      textDecoration: "none",
    },
    heroSection: {
      position: "relative",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundColor: colors.black,
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    splashContainer: {
      width: "100vw",
      height: "100vh",
      zIndex: 99,
      overflow: "hidden",
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      opacity: 1,
      background: colors.black,
      color: colors.white,
      transition: "all 300ms ease-out",
    },
    heroText: {
      fontWeight: 600,
      lineHeight: 1,
      fontSize: "5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4rem",
      },
    },
    logo: {
      fontFamily: "Rubik",
      textTransform: "uppercase",
      fontSize: typography.sizes[1],
      // fontWeight: typography.weights.bolder,
      letterSpacing,
    },
    menuColor: {
      backgroundColor: colors.white,

      "&:before": {
        backgroundColor: colors.white,
      },
      "&:after": {
        backgroundColor: colors.white,
      },
    },
    dropdownMenuUpper: {
      padding: 10,
      paddingLeft: 20,
    },
    dropdownMenuItems: {
      flip: false,
      padding: 20,
      paddingLeft: 0,
    },
    menuText: {
      "& > *": {
        fontSize: 16,
        fontWeight: 600,
        paddingLeft: 25,
      },
    },

    mtn40: {
      marginTop: -40,
    },

    mt20: {
      marginTop: 20,
    },

    mt30: {
      marginTop: 30,
    },

    mt40: {
      marginTop: 40,
    },
    mt50: {
      marginTop: 50,
    },
    mt60: {
      marginTop: 60,
    },
    mt70: {
      marginTop: 70,
    },
    pr20: {
      paddingRight: 20,
    },
    p2: {
      padding: 2,
    },
    p3: {
      padding: 3,
    },
    p4: {
      padding: 4,
    },
    p5: {
      padding: 5,
    },
    p10: {
      padding: 10,
    },
    p15: {
      padding: 15,
    },
    mr10: {
      marginRight: 10,
    },
    paperBorder: {
      border: `1px solid ${colors.lightGrey}`,
      overflow: "hidden",
    },

    pb20: {
      paddingBottom: 20,
    },
    pb30: {
      paddingBottom: 30,
    },
    pb50: {
      paddingBottom: 50,
    },

    mb20: {
      marginBottom: 20,
    },
    mb50: {
      marginBottom: 50,
    },
    mb60: {
      marginBottom: 60,
    },
    mb70: {
      marginBottom: 70,
    },
    mb80: {
      marginBottom: 80,
    },
    ml20: {
      marginLeft: 20,
    },
    ml30: {
      marginLeft: 30,
    },
    ml40: {
      marginLeft: 40,
    },
    ml50: {
      marginLeft: 50,
    },
    ml100: {
      marginLeft: 100,
    },
    ml10: {
      marginLeft: 10,
    },
    btn: {
      padding: theme.spacing(1.5, 5),
      borderWidth: 2,
      borderColor:
        theme.palette.type === "light" ? colors.primary : colors.white,
      borderRadius: 0,
      "&:hover": {
        borderWidth: 2,
      },
    },
    btn2: {
      padding: theme.spacing(1.5, 5),
      borderWidth: 2,
      "& svg": {
        transform: "translateX(8px)",
        transition: "transform 300ms ease-out",
      },
      "&:hover svg": {
        transform: "translateX(12px)",
      },
    },
    btnWhite: {
      padding: theme.spacing(1.5, 5),
      borderWidth: 2,
      borderColor: colors.white,
      color: colors.white,
      borderRadius: 0,
      "& svg": {
        transform: "translateX(8px)",
        transition: "transform 300ms ease-out",
      },
      "&:hover svg": {
        transform: "translateX(12px)",
      },
    },
    btnWhite2: {
      marginTop: 2.5,
      fontSize: typography.sizes[2],
      fontWeight: typography.weights.bold,
      borderWidth: 2,
      borderColor: colors.white,
      color: colors.white,
      borderRadius: 0,
      "& svg": {
        transform: "translateX(8px)",
        transition: "transform 300ms ease-out",
      },
      "&:hover svg": {
        transform: "translateX(12px)",
      },
    },
    bigBtnWhite2: {
      marginTop: 2.5,
      fontSize: 30,
      padding: theme.spacing(5, 7),
      fontWeight: typography.weights.bold,
      borderWidth: 2,
      borderColor: colors.white,
      color: colors.white,
      letterSpacing: 2.5,
      borderRadius: 0,
      "& svg": {
        transform: "translateX(8px)",
        transition: "transform 300ms ease-out",
      },
      "&:hover svg": {
        transform: "translateX(12px)",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
        padding: theme.spacing(2, 6),
      },
    },
    btnBlock: {
      display: "block",
    },
    countWrapper: {
      position: "absolute",
      right: 20,
      top: "30%",
      width: 150,
      textAlign: "center",
      height: "fit-content",
      padding: 10,

      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        bottom: 0,
        top: "75%",
        display: "none",
      },
      background: colors.black,
      "&:before": {
        content: "''",
        position: "absolute",
        width: 5,
        height: "100vh",
        top: "-100vh",
        background: "#000",
      },
    },
    countLine: {
      width: 5,
      height: "100vh",
      backgroundColor: "#000",
      position: "absolute",
      right: 100,
      top: 0,
      zIndex: 1,
    },
    count: {
      fontSize: 96,
      fontWeight: 900,
      color: "#fff",
    },
    flexSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    overflowHidden: {
      overflow: "hidden",
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
    },
    h400: {
      height: 400,
    },

    abtImgContainer: {
      height: 400,
      overflow: "hidden",
      background: colors.black,
      [theme.breakpoints.down("sm")]: {
        height: 200,
      },
    },
    img2: {
      width: "100%",
      height: 400,
      objectFit: "cover",
      objectPosition: "center",
      [theme.breakpoints.down("sm")]: {
        height: 200,
      },
    },

    featureImg: {
      width: "100%",
      height: "100%",
    },
    paperBlack: {
      backgroundColor: colors.black,
      color: colors.white,

      padding: theme.spacing(2, 7),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 5),
      },
    },
    text: {
      fontSize: typography.sizes[8],
    },
    valuesBg: {
      // height: "100vh",
      position: "relative",
    },
    valuesBlack: {
      backgroundColor: colors.black,
      // padding: theme.spacing(2, 7),
      height: 600,
      // height: "100%",
      overflow: "hidden",

      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    valuesCover: {
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: colors.black,
      padding: theme.spacing(2, 7),
      height: 500,
      // height: "100%",
      zIndex: 1,
      width: "100%",
    },
    valuesCoverText: {
      fontSize: typography.largeText.largest,
      fontWeight: 900,
      textTransform: "uppercase",
      marginTop: 50,
      width: "100%",
      height: "auto",
      letterSpacing,
      lineHeight: 1,
    },
    mAutoCenter: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: "0 auto",
    },
    mAuto: {
      margin: "0 auto",
    },

    valuesMission: {
      position: "relative",
      padding: theme.spacing(5, 7),
      height: 600,

      overflow: "hidden",
      // textAlign: "center",
      background: "#ddc9b4",
      [theme.breakpoints.down("sm")]: {
        // height: "100%",
        overflowY: "scroll",
      },
    },
    valuesVision: {
      position: "relative",
      padding: theme.spacing(5, 7),
      height: 600,
      // height: "100%",

      overflow: "hidden",
      // textAlign: "center",
      background: "#FDEBD0",

      [theme.breakpoints.down("sm")]: {
        // height: "100%",
        overflowY: "scroll",
      },
    },
    valuesCore: {
      position: "relative",
      padding: theme.spacing(5, 7),
      height: 600,
      // height: "100%",

      overflow: "hidden",
      background: "#e7d8c9",

      [theme.breakpoints.down("sm")]: {
        // height: "100%",
        overflowY: "scroll",
      },
    },
    valuesTextWrapper: {
      width: "80%",
      fontSize: "1.15rem",
    },
    controls: {
      position: "absolute",
      top: "25%",
      right: 15,
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 3,
      [theme.breakpoints.down("sm")]: {
        top: "65%",
      },
    },
    relativePosition: {
      position: "relative",
    },
    valuesBadge: {
      position: "absolute",
      top: 10,
      left: "48%",
      marginBottom: 20,
      "& > svg": {
        fill: "#4c4c4c",
      },
    },
    rotateWrapper: {
      position: "absolute",
      bottom: 40,
      left: 2,

      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:after": {
        content: "''",
        position: "absolute",
        top: -1030,
        left: 25,
        width: 2,
        height: 1000,
        backgroundColor: "#676767",
      },
    },
    ml5: {
      marginLeft: 5,
    },

    rotateTextAbs: {
      transform: "rotate(-90deg)",
      textTransform: "uppercase",
      letterSpacing,
      fontSize: 11,
    },
    largeText: {
      fontSize: typography.largeText.largest,
      fontWeight: 900,
      textTransform: "uppercase",
      letterSpacing,
      lineHeight: 1,
      marginTop: 5,
      width: "100%",
      color: colors.white,
    },
    largeText2: {
      display: "block",
      fontSize: typography.largeText.medium,
      fontWeight: 900,
      lineHeight: 1,
      textTransform: "uppercase",
      letterSpacing,
      marginTop: 5,
      color: lighten(colors.black, 0.3),
    },
    gridContainer: {
      height: "100%",
    },
    connectContainer: {
      position: "relative",
      height: "50vh",
      background:
        "url(/img/small/buildings-top.jpeg), rgba(225, 225, 225, 0.8)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 70%",
      backgroundSize: "cover",
      zIndex: 2,
      backgroundBlendMode: "overlay",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    overlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 2,
      top: 0,
      left: 0,
      background: "rgba(225,225,225,0.8)",
    },
    overlayBlack: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 2,
      top: 0,
      left: 0,
      background: "rgba(0,0,0,0.4)",
    },
    overlayBlack2: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 2,
      top: 0,
      left: 0,
      background: "rgba(0,0,0,0.25)",
    },
    introContainer: {
      width: "100vw",
      height: "100vh",
      zIndex: 99,
      overflow: "hidden",
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      opacity: 1,
      background: colors.black,
      color: colors.white,
      transition: "all 300ms ease-out",
    },
    z3: {
      zIndex: 3,
    },
    subtitle: {
      fontSize: typography.largeText.medium,
      fontWeight: typography.weights.bolder,
      lineHeight: 1,
    },
    subtitle2: {
      fontSize: typography.largeText.small,
      fontWeight: typography.weights.medium,
      lineHeight: 1,
    },
    subtitle3: {
      fontSize: "1.5rem",
      fontWeight: typography.weights.medium,
      lineHeight: 1,
    },
    boldText: {
      fontWeight: 500,
      fontSize: "1.2rem",
    },

    boldText2: {
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: 1.5,
      color: "rgb(111 111 111)",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    boldText3: {
      fontWeight: 900,
      fontSize: "1.2rem",
    },
    motto: {
      // fontFamily: "Lobster",
      // fontStyle: "italic",
      fontSize: "2.5rem",
      lineHeight: 1.5,
      color: darken(colors.fadedGrey, 0.3),
      fontWeight: 900,
    },
    textWhite: {
      color: colors.white,
    },
    teamContainer: {
      position: "relative",
      minHeight: "100vh",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      // background: "#fafafa",
      // background: colors.black,
    },
    teamCard: {
      position: "relative",
      height: 350,
      width: 220,
      margin: "auto",
      background: "#1d1d1d",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        // width: "100%",
      },
    },
    cardLayerAbs: {
      position: "absolute",
      top: 0,
      left: 0,
      // width: "100%",
      height: "100%",
      zIndex: 1,
      backdropFilter: "blur(8px)",
      transition: "all 100ms ease-out",
    },
    cardNameWrapper: {
      position: "relative",
      zIndex: 3,
      padding: theme.spacing(1.5),
    },
    cardNameBioWrapper: {
      position: "absolute",
      zIndex: 99,
      padding: theme.spacing(1.5),
    },
    cardHole: {
      position: "relative",
      width: 30,
      height: 5,
      borderRadius: 6,
      backgroundColor: "#fff",
      margin: "auto",
      // marginBottom: 20,
      zIndex: 3,
      // boxShadow: theme.shadows[3],
    },
    cardTag: {
      position: "absolute",
      width: 20,
      height: 20,
      backgroundColor: "#383737",
      top: -18,
      left: "45.5%",
      zIndex: 4,

      "&:after": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: 5,
        // borderRadius: 5,
        background: "#eaeaea",
      },
    },
    teamHeader: {
      display: "block",
      fontSize: "2rem",
      fontWeight: 900,
      lineHeight: 1,
      textTransform: "uppercase",
      letterSpacing,
      marginTop: 5,
    },
    teamHeader2: {
      display: "block",
      fontSize: "2.8rem",
      fontWeight: 900,
      lineHeight: 1,
      textTransform: "uppercase",
      overflowWrap: "break-word",
      // letterSpacing,
      // color: colors.white,
    },
    teamHeader3: {
      display: "block",
      fontSize: "2rem",
      fontWeight: 900,
      lineHeight: 1,
      textTransform: "uppercase",
      overflowWrap: "break-word",
      // letterSpacing,
      // color: colors.white,
    },
    teamName: {
      color: lighten(colors.white, 0.3),
      display: "block",
      fontSize: "1rem",
      fontWeight: 900,
      lineHeight: 1,
      textTransform: "uppercase",
      letterSpacing,
      marginTop: 10,
    },
    teamTitle: {
      color: lighten(colors.white, 0.3),
      display: "block",
      fontSize: "0.75rem",
      marginTop: 10,
      fontWeight: 600,
      lineHeight: 1,
      textTransform: "uppercase",
    },
    teamDept: {
      color: darken(colors.white, 0.3),
      display: "block",
      fontSize: "0.75rem",
      marginTop: 10,
      fontWeight: 600,
      lineHeight: 1,
    },
    teamBio: {
      display: "block",
      marginTop: 10,
      fontWeight: 700,
      lineHeight: 1.1,
      color: "#ccc",
    },
    teamBio2: {
      display: "block",
      marginTop: 10,
      fontWeight: 500,
      lineHeight: 1.1,
      color: "#fafafa",
    },
    passport: {
      width: "100%",
      height: 200,
      margin: "auto",
      objectFit: "cover",
      objectPosition: "center 0%",
      marginTop: -5,
    },
    passport2: {
      width: "100%",
      height: 300,
      margin: "auto",
      objectFit: "cover",
      objectPosition: "center 10%",
      marginTop: -5,
      [theme.breakpoints.down("sm")]: {
        height: 400,
      },
    },
    passport3: {
      width: "100%",
      height: "100%",
      margin: "auto",
      objectFit: "cover",
      objectPosition: "center 10%",
      marginTop: -5,
      [theme.breakpoints.down("sm")]: {
        height: 400,
      },
    },
    passport4: {
      width: "100%",
      height: 400,

      margin: "auto",
      objectFit: "cover",
      objectPosition: "center 10%",
      marginTop: -5,
      [theme.breakpoints.down("sm")]: {
        height: 400,
      },
    },
    circleAbs: {
      margin: "0 auto",
      position: "absolute",
      left: "40%",
      fontSize: "15rem",
      top: 0,
      marginTop: -180,
      fill: "#98989857",
      // transform: "translate(-5%)",
      [theme.breakpoints.down("sm")]: {
        // left: "25%",
        transform: "translate(-35%)",
      },
    },
    grid: {
      display: "flex",
      alignItems: "center",
      "& > *": {
        display: "block",
        margin: "0 3px",
      },
    },
    grid2: {
      display: "flex",
      "& > *": {
        display: "block",
      },
    },
    small: {
      fontSize: 12,
    },
    rotateCompanyName: {
      color: "#2d2d2d9c",
      fontWeight: typography.weights.bolder,
      fontSize: typography.largeText.medium,
      position: "absolute",
      bottom: 0,
      right: -80,
      textTransform: "uppercase",
      transform: "rotate(90deg)",
      zIndex: -1,
    },
    teamCardWrapper: {
      width: "fit-content",
      margin: "auto",
      position: "relative",
    },
    socials: {
      fontSize: 13,
    },
    infoContainer: {
      paddingTop: 100,
      paddingBottom: 100,
      minHeight: "50vh",
      height: "100%",
      position: "relative",
      overflow: "hidden",
      background: "#ab903f1c",
      zIndex: 6,
      // "&:before": {
      //   content: "''",
      //   position: "absolute",
      //   left: 0,
      //   bottom: 0,
      //   width: 300,
      //   height: "100%",
      //   zIndex: 0,
      //   background: "#ffbf001c",
      //   transition: "width 300ms ease-out",
      // },
    },
    centered: {
      textAlign: "center",
      margin: "0 auto",
    },
    infoImg: {
      height: 350,
      width: 525,
      objectFit: "cover",
      objectPosition: "center",
      backgroundColor: colors.black,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    infoImgContainer: {
      height: 350,
      width: 525,
      backgroundColor: colors.black,
      display: "flex",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    infoTitle: {
      fontSize: typography.largeText.medium,
      // color: colors.white,
      fontWeight: 900,
      lineHeight: 1,
      textTransform: "uppercase",
    },
    text2: {
      fontSize: "1.05rem",
    },
    iconContainer: {
      padding: theme.spacing(1),
      background: "#191414",
      "& svg": {
        fill: colors.white,
        marginTop: "150%",
      },
    },
    infoContent: {
      position: "relative",
      width: "100%",
    },
    infoContentInner: {
      paddingTop: 10,
      paddingRight: 5,
      maxWidth: 400,
      width: "100%",
    },
    infoNextBtnAbs: {
      position: "absolute",
      zIndex: 5,
      right: 10,
      top: "30%",
      [theme.breakpoints.down("sm")]: {
        right: 10,
        top: "5%",
      },
    },
    infoCounter: {
      width: 100,
      fontWeight: 900,
      fontSize: "3rem",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    infoCircleAbs: {
      position: "absolute",
      right: "-25%",
      fontSize: "35rem",
      top: 0,
      zIndex: 3,
      marginTop: -180,
      fill: "#9e5a5a38",
      [theme.breakpoints.down("sm")]: {
        right: "-80%",
      },
    },
    newsletterContainer: {
      paddingTop: 60,
      height: "60vh",
      width: "100%",
      overflow: "hidden",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        background: "#fafafa",
      },
    },
    newsBg: {
      background: "url(/img/small/newspaper-1.jpeg), rgba(0,0,0,0.6)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "overlay",
      overflow: "hidden",
    },
    pt60: {
      paddingTop: 60,
    },
    pt70: {
      paddingTop: 70,
    },
    pb60: {
      paddingBottom: 60,
    },
    newsLogoAbsLeft: {
      fontSize: "7rem",
      position: "absolute",
      width: "fit-content",
      top: 0,
      right: "-12.6rem",
      transform: "rotate(90deg)",
      zIndex: 2,
      // textTransform: "uppercase",
      fontWeight: 900,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    newsLogoAbsRight: {
      fontSize: "7rem",
      position: "absolute",
      width: "fit-content",
      top: 0,
      left: "-11.6rem",
      transform: "rotate(90deg)",
      zIndex: 0,
      // textTransform: "uppercase",
      fontWeight: 900,
      color: colors.white,
    },
    letterImg: {
      objectFit: "cover",
      objectPosition: "10%",
      height: 550,
      marginLeft: "auto",
      marginTop: -80,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      display: "block",
    },
    w600: {
      width: 600,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    newsHeader: {
      display: "block",
      fontSize: "2rem",
      fontWeight: 900,
      lineHeight: 1,
      textTransform: "uppercase",
      letterSpacing,
    },
    footer: {
      marginTop: "auto",
      // padding: 28,
      minHeight: "100vh",
      height: "auto",
      background: colors.black,
      color: colors.white,
    },
    footerDivider: {
      background: "rgb(35 34 34)",
    },
    iconBlack: {
      fill: colors.black,
    },
    linkFooter: {
      color: colors.white,
      fontSize: typography.sizes[6],
      fontWeight: typography.weights.medium,
      textDecoration: "none",
      display: "block",
      width: "fit-content",
      margin: "15px 0px",
      transition: "color 300ms ease-out",
      "&:hover": {
        color: darken(colors.white, 0.3),
      },
    },
    lightGreyText: {
      color: darken(colors.fadedGrey, 0.3),
    },
    extraLightGrey: {
      color: colors.extraLightGrey,
    },
    infoIcon: {
      marginLeft: 30,
      marginTop: 30,
    },
    iconBtn: {
      borderRadius: 0,
      padding: theme.spacing(2),
      display: "inline-block",
      "&:hover": {
        background: lighten(colors.black, 0.05),
      },
    },
    aboutHeroBg: {
      width: "100vw",
      height: "40vh",
      position: "relative",
      overflow: "hidden",
      background: colors.black,
      color: colors.white,
    },
    policyContainer: {
      width: "100vw",
      height: "40vh",
      position: "relative",
      overflow: "hidden",
      background: colors.black,
      color: colors.white,
    },
    aboutHeroImg: {
      width: "100%",
      height: "100%",
      objectPosition: "center 30%",
      objectFit: "cover",
    },
    aboutHeroTitle: {
      position: "absolute",
      bottom: 40,
      left: 2,
    },

    deepGreyText: {
      color: colors.deepGrey,
    },
    dotsContainer: {
      display: "flex",
      position: "absolute",
      top: "2%",
      left: "2.8%",
      zIndex: 3,
      alignItems: "center",
      "& > *": {
        margin: "0px 5px",
      },
      [theme.breakpoints.down("sm")]: {
        left: "5%",
      },
    },
    dots: {
      position: "relative",
      width: 10,
      height: 10,
      borderRadius: "50%",
      background: "#cccac8",
      cursor: "pointer",
      overflow: "hidden",
      transition: "all 0.5s ease-out",
      "&:hover": {
        transform: "scale(1.2)",
      },
    },
    dotsActive: {
      position: "absolute",
      width: 10,
      height: 10,
      borderRadius: "50%",
      background: colors.black,
      transition: "all 0.5s ease-out",
      cursor: "pointer",
      zIndex: 1,
      "&:hover": {
        transform: "scale(1.2)",
      },
    },
    z1: {
      zIndex: 1,
    },
    z2: {
      zIndex: 2,
    },
    z4: {
      zIndex: 4,
    },
    practionerFormContainer: {
      background: "#fafafa",
    },
    practFormWrapper: {
      padding: theme.spacing(2, 6),
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 4),
      },
    },
    textField: {
      marginBottom: 30,
    },
    imgWrapper: {
      width: "100%",
      // height: 500,
      overflow: "hidden",

      backgroundBlendMode: "screen",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: 120,
      },
    },

    practFormImg1: {
      width: "100%",
      height: "100%",
      position: "absolute",
      objectFit: "cover",
      objectPosition: "80% 50%",
      zIndex: 1,
      transition: "transform 0.3s ease-in-out",

      // "&:hover": {
      //   transform: "scale(1.1)",
      // },
    },
    practImgOverlayBlack: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 2,
      top: 0,
      left: 0,
      background:
        "url(/img/small/doc-phone-2.jpeg), url(/img/small/darkness-smoke.jpeg),rgba(0,0,0,0.5)",
      backgroundSize: "cover",
      backgroundPosition: "50% 40%",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "multiply",
      // [theme.breakpoints.up("md")]: {
      //   backgroundColor: colors.black,
      // },
    },
    vetImgOverlayBlack: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 2,
      top: 0,
      left: 0,
      background:
        "url(/img/small/oil-painting-2.jpeg),url(/img/small/dog.jpeg),rgba(0,0,0,0.5)",
      backgroundSize: "cover",
      backgroundPosition: "80% 30%",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "multiply",
      // [theme.breakpoints.up("md")]: {
      //   backgroundColor: colors.black,
      // },
    },

    holder: {
      position: "absolute",
      right: -15,
      zIndex: 300000,
      top: "45%",
      backgroundColor: "#000",
      width: 30,
      height: 30,
      border: "10px solid #bf8326",
      borderRadius: "50%",
      cursor: "grab",
    },
    holder2: {
      position: "absolute",
      right: -15,
      zIndex: 300000,
      top: "45%",
      backgroundColor: "#000",
      width: 30,
      height: 30,
      border: "10px solid #bf8326",
      borderRadius: "50%",
      cursor: "grab",
    },

    practFormImg2: {
      width: "100%",
      height: "100%",
      position: "absolute",
      objectFit: "cover",
      objectPosition: "center",
      zIndex: 2,
    },
    practFormTextAbs: {
      position: "absolute",
      zIndex: 3,
      top: "55%",
      display: "block",
      fontSize: "3.5rem",
      fontWeight: 900,
      lineHeight: 1,
      padding: theme.spacing(2),
      // textTransform: "uppercase",
      color: colors.white,
      // background: colors.black,
      letterSpacing,
      marginTop: 5,
      mixBlendMode: "difference",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    justifyCenter: {
      justifyContent: "center",
    },
    dropzone: {
      border: `1px solid #ccc`,
      borderRadius: 0,
      textAlign: "center",
      padding: theme.spacing(6, 2),

      backgroundColor:
        theme.palette.type === "light" ? "#fafafa" : "transparent",
      transition: theme.transitions.create(["all"], {
        easing: theme.transitions.easing.easeIn,
      }),

      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
      "&:hover": {
        borderColor: colors.black,
      },
    },
    cultureContainer: {
      // height: "100vh",
    },
    cultureBlackWrapper: {
      background: colors.black,
      color: colors.white,
    },
    cultureHeader: {
      fontSize: "8rem",
      lineHeight: 1,
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: "4.5rem",
      },
    },
    cultureBg: {
      background: "url(/img/large/togetherness.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "10% 20%",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "multiply",
      transition: "background 300ms ease-in-out",
      [theme.breakpoints.down("sm")]: {
        height: "40vh",
      },
    },
    bgBlack: {
      background: colors.black,
    },
    abstract: {
      background: "url(/img/large/abstract-1.jpeg)",
      backgroundSize: "cover",
      backgroundPosition: "10%",
      backgroundRepeat: "no-repeat",
      padding: 20,
      height: "5vh",
      width: "100vw",
    },
    aboutContainer: {
      padding: theme.spacing(3, 5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3, 3),
      },
    },
    aboutImgWrapper: {
      height: 400,
      overflow: "hidden",
      border: "8px solid #f5f5f5",
    },
    image1: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "50%",
    },
    productsContainer: {
      overflow: "hidden",
    },
    solutionSectionContainer: {
      overflow: "hidden",
    },
    fitContent: {
      width: "fit-content",
      height: "fit-content",
    },

    missionWrapper: {
      width: "100%",
      height: 500,
      background: colors.black,
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    card: {
      maxWidth: 545,
    },
    aboutCultureContainer: {
      minHeight: "50vh",
      height: "100%",
      backgroundBlendMode: "overlay",
      paddingTop: "5%",
    },
    aboutOurCultureImg: {
      height: 400,
      width: "100%",
      backgroundImage: "url(/img/large/culture.jpg)",
      backgroundColor: colors.black,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("sm")]: {
        height: 150,
      },
    },
    splashBg: {
      backgroundImage: "url(/img/svgs/spiral.svg)",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      height: 400,
      width: "100%",
      position: "absolute",
      left: 0,
      top: -150,
      zIndex: -1,
    },
    aboutCultureWrapper: {
      width: "100%",
      height: 400,
      background: colors.black,
      [theme.breakpoints.down("sm")]: {
        height: 150,
      },
    },
    aboutCultureImg: {
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: 400,
      [theme.breakpoints.down("sm")]: {
        height: 150,
      },
    },
    companyInfoContainer: {
      background: "url(/img/large/chess.jpeg), rgba(0,0,0,0.4)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "50vh",
      backgroundBlendMode: "overlay",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    newsContainer: {
      overflow: "hidden",
    },
    jobsContainer: {
      height: "60vh",
      backgroundColor: "#fafafa",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    jobsImg: {
      height: "100%",

      objectFit: "cover",
      objectPosition: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
    },
    loadPageContainer: {
      position: "fixed",
      width: "100vw",
      height: "100vh",
      zIndex: 3000000,
      top: 0,
      left: 0,

      overflow: "hidden",
    },
    loadPageBlackBox: {
      background: "#000",
      width: "100%",
      height: "100vh",
    },
    centerAbsolute: {
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-40%, -50%)",
      textAlign: "center",
    },
    centerAbsolute2: {
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
    activeLink: {
      color: colors.activeLink,
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        transition: "all .5s ease",
        transitionDelay: "300ms",
        left: 0,
        bottom: 0,
        width: "100%",
        height: 2,
        background: colors.activeLink,
        transform: "translateX(0)",
      },
    },
    linkBtn: {
      border: "1px solid #fff",
      color: colors.white,
      fontSize: typography.sizes[2],
      fontWeight: typography.weights.bold,
      letterSpacing: 1.05,
      textTransform: "uppercase",
      textDecoration: "none",
      display: "inline-block",
      margin: "0 10px",
      transition: "color 300ms ease-out",
      overflow: "hidden",
      padding: 10,
      cursor: "pointer",
      "&:hover": {
        color: darken(colors.white, 0.3),
      },
    },
    activeLink2: {
      color: colors.activeLink,
    },
    leadershipHeader: {
      height: "40vh",
      background: colors.black,
      color: colors.white,
    },
    linkBlue: {
      color: colors.lightblue,
      textDecoration: "none",
      cursor: "pointer",
      transition: "all 300ms ease-out",
      width: "fit-content",
      "&:hover": {
        color: darken(colors.lightblue, 0.3),
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    contactHeader: {
      color: colors.white,
      background: colors.black,
      height: "40vh",
    },
    contactInfoBg: {
      background: "url(/img/large/map.jpeg), rgba(0,0,0,0.90)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundBlendMode: "overlay",
    },
    capitalize: {
      textTransform: "capitalize",
      fontSize: 14,
    },
    heroProduct: {
      // color: colors.white,
      // background: colors.black,
      height: "100vh",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    valuesContainer: {
      overflow: "hidden",
      height: "100%",

      background: colors.black,
      [theme.breakpoints.down("sm")]: {
        minHeight: "100%",
        height: "auto",
        background: colors.white,
      },
    },
    valuesGridContainer: {
      overflow: "hidden",
      [theme.breakpoints.up("sm")]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        margin: "0 auto",
      },
    },
    valuesPaper: {
      width: 600,
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
    },
    valuesMobileContainer: {
      position: "absolute",
      top: "0%",
      marginTop: "7%",
      margin: "auto",
      transform: "translate(-10%, -40%)",
      // left: "40%",
      right: 0,
    },
    borderWhite: {
      borderColor: colors.white,
    },
    valuesLetterAbs: {
      fontSize: "2rem",
      position: "absolute",
      zIndex: 1,
    },
    productImg: {
      height: 600,
      width: "100%",
      objectFit: "contain",
      objectPosition: "center",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    productImg2: {
      height: 400,
      width: "100%",
      backgroundColor: colors.black,
      backgroundImage: "url(/img/large/maps-coffee.jpeg)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("sm")]: {
        height: 200,
      },
    },
    productImg3: {
      height: 400,
      width: "100%",
      backgroundColor: colors.black,
      backgroundImage: "url(/img/large/pug.jpeg)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("sm")]: {
        height: 200,
      },
    },
    productImg4: {
      height: 400,
      width: "100%",
      backgroundColor: colors.black,
      backgroundImage: "url(/img/large/pract.jpeg)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("sm")]: {
        height: 200,
        width: "100vw",
      },
    },
    introSectionContainer: {
      background: "#f7f7f7",
      overflow: "hidden",
    },
    safetySection: {
      height: 400,
    },
    safetyBg: {
      backgroundColor: colors.white,
    },
    h100: {
      height: "100%",
    },
    greyBg: {
      background: "#fafafa",
    },
    px5: {
      padding: theme.spacing(0, 5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0),
      },
    },
    corpSectionContainer: {
      padding: theme.spacing(5, 5),
      width: "100vw",
      height: "50vh",
      background:
        "url(/img/large/tall-buildings.jpeg), rgba(225, 225, 225, 0.8)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "overlay",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 3),
        height: "100%",
      },
    },
    comingSoon: {
      letterSpacing: 2,
      // fontSize: 13,
      textTransform: "uppercase",
      color: colors.black,
      fontWeight: 900,
    },
    closeBtn: {
      position: "absolute",
      top: -30,
      right: -20,
      boxShadow: theme.shadows[2],
      zIndex: 999,
      background: colors.black,
      color: colors.white,
      "&:hover": {
        background: colors.black,
      },
    },
    practHeroContainer: {
      minHeight: "70vh",
      height: "auto",
      background: "url(/img/small/woman-phone-2.jpg),rgba(0,0,0,0.8)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "overlay",
      "& > div": {
        marginTop: 50,
        [theme.breakpoints.down("sm")]: {
          marginTop: 20,
        },
      },
    },

    vetHeroContainer: {
      minHeight: "70vh",
      height: "auto",
      background: "url(/img/small/parrot.jpg),rgba(0,0,0,0.8)",
      backgroundPosition: "right 30%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "overlay",
      "& > div": {
        marginTop: 50,
        [theme.breakpoints.down("sm")]: {
          marginTop: 20,
        },
      },
    },

    practCounter: {
      position: "absolute",
      top: 5,
      right: 10,
      fontSize: theme.typography.pxToRem(33),
    },
    featureIndexContainer: {
      // height: "100vh",
    },

    practListsWrapper: {
      minWidth: 400,
      padding: 10,
    },

    slider: {
      width: "100%",
      height: "100%",
    },
    wrapper: {
      width: "100%",
      height: 500,
      overflow: "hidden",
      objectPosition: "center",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    overflowYAuto: {
      overflowY: "auto",
    },
    wrapperImgContainer: {
      width: "100%",
      height: 500,
      [theme.breakpoints.down("sm")]: {
        height: 150,
      },
    },
    wrapperImg: {
      width: "100%",
      height: 500,
      objectPosition: "bottom",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        height: 150,
      },
    },
    aboutValuesPaginateAbs: {
      position: "absolute",
      zIndex: 999999,
      paddingRight: 15,
      width: "50%",
      right: 0,

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    bg: {
      width: "100%",
      height: 430,
      objectPosition: "center",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        height: 150,
      },
    },
    bg2: {
      width: "100%",
      height: "60vh",
      objectPosition: "center",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        height: 250,
      },
    },
    bg3: {
      width: "100%",
      height: "100%",
      objectPosition: "bottom",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        height: 150,
      },
    },
    bigText: {
      fontSize: "xx-large",
      fontWeight: 600,
      lineHeight: 1,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    getAppContainer: {
      // height: "30vh",
      // background: lighten(theme.palette.text.secondary, 0.95),
    },
    getAppContainer2: {
      paddingTop: 60,
      paddingBottom: 60,
    },
    textCenter: {
      textAlign: "center",
    },
    bold: {
      fontWeight: 500,
      fontSize: 18,
    },
    dNone: {
      display: "none",
    },
    labelBtnOutlined: {
      border: "2px solid #333",
      cursor: "pointer",
      padding: theme.spacing(1, 2.5),
      display: "inline-block",
      transition: "all 300ms ease-in-out",
      "&:hover": {
        transform: "scale(1.05)",
        background: "#f9f9f9",
        borderColor: "#000",
      },
    },
    appHeroContainer: {
      height: "100vh",
      overflow: "hidden",
      background: "url(/img/small/server.jpeg), rgba(0,0,0,0.7)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "overlay",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },

    appFeaturesContainer: {
      // height: "100vh",
      overflow: "hidden",
      paddingTop: 60,
      paddingBottom: 60,
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    slides: {
      display: "flex",
      overflowX: "hidden",
      scrollSnapType: "x mandatory",
      scrollBehavior: "smooth",
      WebkitOverflowScrolling: "touch",
      width: 500,
    },
    slideImg: {
      width: 500,
      height: 400,
      objectFit: "cover",
      objectPosition: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: 200,
      },
    },
    appTextContainer: {
      width: "100%",
      height: "100%",
      paddingTop: 70,
    },
    glass: {
      background: "rgba(0,0,0,0.5)",
      backdropFilter: "blur(5px)",
      color: colors.white,
    },
    absText: {
      position: "absolute",
      fontSize: "10rem",
      top: "-20%",
      left: "-1%",
      paddingTop: 40,
      zIndex: 1,
      color: "rgba(225,225,225,0.085)",
      fontWeight: 900,
      [theme.breakpoints.down("sm")]: {
        top: "-13%",
        fontSize: "7rem",
      },
    },
    absText2: {
      position: "absolute",
      fontSize: "10rem",
      top: "-250%",
      left: "-10%",
      paddingTop: 40,
      zIndex: 1,
      color: "rgba(225,225,225,0.085)",
      fontWeight: 900,
      [theme.breakpoints.down("sm")]: {
        // top: "-13%",
        fontSize: "7rem",
      },
    },
    prodFeatPractBg: {
      background: "url(/img/small/healthcare2.jpg), rgba(0,0,0,0.8)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "overlay",
      color: colors.white,
      height: 400,
      textAlign: "center",
      paddingTop: 60,
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    vetFeatPractBg: {
      background: "url(/img/small/husky.jpeg), rgba(0,0,0,0.8)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "overlay",
      color: colors.white,
      height: 400,
      textAlign: "center",
      paddingTop: 60,
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    comingSoonContainer: {
      minHeight: "80vh",
      height: "100%",
      background: "url(/img/small/dark_chair.jpeg), rgba(0,0,0,0.6)",
      backgroundSize: "cover",
      backgroundPosition: "80% 63%",
      backgroundRepeat: "no-repeat",
      color: colors.white,
      backgroundBlendMode: "multiply",
      textAlign: "center",
      "& > div": {
        margin: "0 auto",
      },
    },
    comingSoonText: {
      fontSize: "5em",
      letterSpacing: 4.5,
      lineHeight: 0.8,
      textTransform: "uppercase",
    },
    textUpper: {
      textTransform: "uppercase",
    },
    bgWhite: {
      background: colors.white,
    },
    businessHeroContainer: {
      height: "80vh",
      background: "url(/img/small/business-man.jpeg)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "overlay",
    },
    minTextField: {
      width: 350,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    benefitContainer: {
      height: "100%",
      minHeight: "70vh",
      background: "#fafafa",
    },

    cards: {
      height: "100%",
      minHeight: 100,
      paddingTop: 20,
      paddingBottom: 20,
    },
    paperTransparent: {
      background: "transparent",
    },
    footerLink: {
      color: colors.white,
      fontSize: typography.sizes[2],
      fontWeight: 300,
      letterSpacing: 1.05,
      textTransform: "uppercase",
      textDecoration: "none",
      display: "inline-block",
      margin: "0 10px",
      transition: "color 300ms ease-out",
      overflow: "hidden",
      paddingTop: 10,
      paddingBottom: 5,
      "&:hover": {
        color: darken(colors.white, 0.3),
      },
    },
    stepsContainer: {
      marginLeft: "17.5%",
      color: colors.white,
      "& > *": {
        fontSize: 18,
      },
    },
    accordionTitle: {
      color: "#5a5a5a",
    },
    boxFlex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 5,
    },
    box: {
      display: "inline-block",
      padding: theme.spacing(0.5, 1),
      border: `2px solid ${theme.palette.error.main}`,
      marginLeft: 5,
      lineHeight: 1,
    },
    borderSuccess: {
      borderColor: "green",
    },
    flexWrapper: {
      display: "flex",
      overflow: "hidden",
    },

    errorCheck: {
      background: theme.palette.error.main,
      position: "relative",
      transition: "color 300ms ease-out",
      overflow: "hidden",
      width: "100%",
      height: 2,
      marginTop: 2,
    },
    successCheck: {
      background: "green",
    },
    pulse: {
      animation: "$pulse-animation 2s infinite",
      boxShadow: "0px 0px 1px 30px #0000001a",
      borderRadius: "50%",
    },
    "@keyframes pulse-animation": {
      "0%": {
        boxShadow: "0 0 0 0px rgba(0, 0, 0, 0.2)",
      },
      "100%": {
        boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
      },
    },
    skewed: {
      transform: "skew(-10deg, 0deg)",
      transformOrigin: "bottom left",
      width: "100%",
      height: "100vh",
      backgroundColor: "#000",
      position: "relative",
      overflow: "hidden",
    },
    greySkewLine: {
      position: "absolute",
      left: "52.7%",
      background: "#dddddd",
      width: 5,
      height: "100%",
      transform: "skew(-10deg, 0deg)",
      transformOrigin: "bottom left",
    },
    skewedInnerImg: {
      transform: "skew(10deg, 0deg)",
      position: "absolute",
      top: 0,
      left: "-20%",
      width: "100vw",
      height: "100%",
      objectFit: "contain",
      transformOrigin: "bottom left",
      transition: "all 300ms ease-out",
      objectPosition: "center",
    },
    snackbar: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }),
  { index: 1 }
);

export default useStyles;
