import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import useStyles from "../../../theme/styles";
import MAccordion from "../../Reusables/MAccordion";

const AppFAQs = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.mt30}>
      <div className={classes.container}>
        <Typography className={clsx(classes.subtitle)} gutterBottom>
          Frequently Asked Questions
        </Typography>
      </div>
      {contents.map((item, i) => (
        <MAccordion
          key={i}
          title={item.title}
          index={i}
          expanded={expanded}
          handleChange={handleChange}
        >
          {item.body}
        </MAccordion>
      ))}
    </div>
  );
};

const contents = [
  {
    title: "From what age can you use the Germiny Software?",
    body: "The appropriate age to use the Germiny Software is 18 years and above",
  },
  {
    title:
      "Can I use the Germiny App to get secondary or tertiary health care? ",
    body: "No, Germiny is strictly limited to primary health care and Veterinary care",
  },
  {
    title: "How do I sign up as a user on Germiny?",
    body: "You can sign up by registering on our app on the Playstore or Appstore",
  },
  {
    title: "How do I verify that practitioners are certified?",
    body: "You can verify by checking their license number at the point of booking.",
  },
];

export default AppFAQs;
