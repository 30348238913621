import {
  Paper,
  Typography,
  Grid,
  CardActionArea,
  useMediaQuery,
  useTheme,
  Backdrop,
} from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import ProgressiveImage from "react-progressive-graceful-image";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router";

import useStyles from "../../theme/styles";
import { getTeams } from "../../features/teams/teamsSlice";
import Loader from "../Reusables/Loader";

const LeadershipList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isWhite, setIsWhite] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { teams, loading } = useSelector((state) => state.teams);

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  const onHoverStart = (i) => {
    setIndex(i);
    setIsWhite(true);
    setIsHovered(true);
  };

  const onTapStart = (i) => {
    if (matches) {
      setIndex(i);
      if (isWhite) {
        setIsWhite(false);
      } else {
        setIsWhite(true);
      }
      setIsHovered((prev) => !prev);
    }
  };

  const onHoverEnd = () => {
    setIsHovered(false);
    setIsWhite(false);
  };

  const transition = {
    type: "spring",
    stiffness: 300,
    damping: 30,
    ease: "easeOut",
  };

  const listVariant = {
    hidden: {
      opacity: 0,
      transition,
    },
    visible: {
      opacity: 1,
      transition,
    },
    exit: {
      opacity: 0,
      transition,
    },
  };

  const itemsVariant = {
    hidden: {
      opacity: 0,
      x: -50,
      transition,
    },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        ease: "easeOut",
        duration: 0.5,
        delay: i * 0.2,
      },
    }),
    exit: {
      opacity: 0,
      transition,
    },
  };

  const slideUpVariants = {
    visible: (i) => ({
      y: i === index && isHovered ? -250 : 0,
      transition: {
        y: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          duration: 5,
          delay: 0.1,
          ease: "easeOut",
        },
      },
    }),
  };

  const revealBioVariants = {
    visible: (i) => ({
      opacity: i === index && isHovered ? 1 : 0,
      x: i === index && isHovered ? 0 : -300,
      y: -250,
      transition: {
        x: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          duration: 5,
          ease: "easeOut",
          delay: 0.2,
        },
        opacity: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          duration: 5,
          ease: "easeOut",
        },
      },
    }),
  };

  return (
    <AnimatePresence>
      <motion.div
        key="items"
        variants={listVariant}
        layout
        initial="hidden"
        animate="visible"
        exit="exit"
        className={clsx(classes.container)}
      >
        <Typography gutterBottom className={clsx(classes.boldText2)}>
          Biographies
        </Typography>
        <Typography gutterBottom className={clsx(classes.teamHeader)}>
          Executive Team
        </Typography>

        <motion.div layout className={classes.mt50}>
          <Grid container spacing={3}>
            {teams.map((item, i) => (
              <Grid item xs={12} md={3} key={item.id}>
                <motion.div
                  variants={itemsVariant}
                  onHoverStart={() => onHoverStart(i)}
                  onHoverEnd={onHoverEnd}
                  onTapStart={() => onTapStart(i)}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  custom={i}
                >
                  <Paper
                    className={clsx(classes.paperBorder)}
                    square
                    elevation={0}
                    component={CardActionArea}
                    onClick={() => history.push(`/leadership/${item.link}`)}
                  >
                    {index === i && (
                      <CardLayerAbs
                        color="rgb(0 0 0 / 48%)"
                        isHovered={isHovered}
                      />
                    )}
                    <ProgressiveImage
                      delay={3000}
                      src={item.image}
                      placeholder={item.image}
                      style={{
                        backgroundColor: "#000",
                      }}
                    >
                      {(src, loading) => (
                        <motion.img
                          layoutId={item.id}
                          src={src}
                          alt={item.name}
                          className={clsx(classes.passport2)}
                          style={{
                            objectPosition:
                              (i === 1 ||
                                i === 5 ||
                                i === 6 ||
                                i === 7 ||
                                i === 8) &&
                              "center 50%",
                            opacity: loading ? 0.9 : 1,
                          }}
                        />
                      )}
                    </ProgressiveImage>
                    <motion.div
                      custom={i}
                      variants={slideUpVariants}
                      animate="visible"
                      className={clsx(classes.cardNameWrapper, {
                        [classes.textWhite]: i === index && isWhite === true,
                      })}
                    >
                      <Typography
                        gutterBottom
                        style={{
                          transition: "color 300ms ease-out",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        className={clsx(classes.lightGreyText)}
                      >
                        {item.title}
                      </Typography>
                    </motion.div>

                    <motion.div
                      variants={revealBioVariants}
                      custom={i}
                      animate="visible"
                      className={classes.cardNameBioWrapper}
                    >
                      <Typography
                        className={clsx(classes.teamBio)}
                        gutterBottom
                      >
                        Bio
                      </Typography>
                      <Typography
                        gutterBottom
                        className={clsx(classes.teamBio2)}
                      >
                        {item.bio}
                      </Typography>
                    </motion.div>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </motion.div>
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
    </AnimatePresence>
  );
};

const CardLayerAbs = ({ color, isHovered }) => {
  const classes = useStyles();

  const hoverVariant = {
    hidden: {
      opacity: 0,
      width: 0,
    },
    visible: {
      width: isHovered ? "100%" : 0,
      opacity: isHovered ? 1 : 0,
    },
  };

  return (
    <motion.div
      variants={hoverVariant}
      initial="hidden"
      animate="visible"
      className={classes.cardLayerAbs}
      style={{
        backgroundColor: color,
      }}
    />
  );
};

export default LeadershipList;
