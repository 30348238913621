import clsx from "clsx";
import { Hidden, useTheme, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import useStyles from "../../../theme/styles";
import HeroContent from "./HeroContent";
import HeroCount from "./HeroCount";
import HeroImage from "./HeroImage";
import GooglePlay from "../../Reusables/GooglePlay";
import AppleBtnSvg from "../../Reusables/AppleBtnSvg";

const appLinks = [
  {
    src: "/img/app-store-google.svg",
    alt: "Google",
    component: GooglePlay,
  },
  {
    src: "/img/app-store-apple.svg",
    alt: "Apple",
    component: AppleBtnSvg,
  },
];

const HeroProduct = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [links, setLinks] = useState([]);
  const [isPositive, setIsPositive] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let newPage = page;
    setLinks(appLinks);

    const interval = setInterval(() => {
      newPage += 1;
      if (newPage > 0) {
        setIsPositive(true);
      } else {
        setIsPositive(false);
      }

      if (newPage > 2) {
        newPage = 0;
      }

      setPage((prev) => (prev = newPage));
    }, 8000);
    if (matches) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [matches, page]);

  const handleChange = (event, newValue) => {
    setPage((prev) => (prev = newValue));
    if (newValue > page) {
      setIsPositive(true);
    } else {
      setIsPositive(false);
    }
  };

  return (
    <>
      <div className={clsx(classes.heroSection)}>
        <HeroContent {...{ handleChange, page, links, isPositive }} />
        <HeroCount {...{ page }} />
        <Hidden smDown>
          <HeroImage {...{ page }} />
        </Hidden>
      </div>
    </>
  );
};

export default HeroProduct;
