import AppFAQs from "./AppFAQs";
import Features from "./Features";
import GetApps from "./GetApps";
import Hero from "./Hero";

const AppIndex = () => {
  return (
    <>
      <Hero />
      <Features />
      <AppFAQs />
      <GetApps />
    </>
  );
};

export default AppIndex;
