import { Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const Logo = () => {
  return (
    <NavLink
      to="/"
      style={{ display: "flex", color: "white", textDecoration: "none" }}
    >
      <Typography variant="h5">Germiny</Typography>
    </NavLink>
  );
};

export default Logo;
