import { Paper, Typography, Grid } from "@material-ui/core";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useStyles from "../../theme/styles";
import MButton from "../Reusables/MButton";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as MegaPhone } from "../../theme/assets/megaphone.svg";
import { ReactComponent as Blog } from "../../theme/assets/blog.svg";
import { ReactComponent as Safety } from "../../theme/assets/safety.svg";

const NewsSection = () => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.newsContainer, classes.container, classes.pb60)}
    >
      <Typography
        gutterBottom
        className={clsx(classes.teamHeader2, classes.mt50)}
      >
        Keep up with the latest
      </Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={4}>
          <Paper
            square
            className={clsx(classes.p15, classes.paperBorder, classes.cards)}
            elevation={0}
          >
            <MIcon fontSize="large" src={MegaPhone} />
            <Typography gutterBottom className={clsx(classes.boldText3)}>
              Newsroom
            </Typography>
            <Typography gutterBottom className={clsx(classes.text)}>
              Get announcements about our latest updates, and so much more.
            </Typography>
            <motion.div
              whileTap={{
                scale: 0.8,
              }}
              className={classes.fitContent}
            >
              <MButton variant="outlined" component={Link} to="/newsroom">
                Learn More
              </MButton>
            </motion.div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            square
            className={clsx(classes.p15, classes.paperBorder, classes.cards)}
            elevation={0}
          >
            <MIcon fontSize="large" src={Blog} viewBox="0 -20 512 511" />
            <Typography gutterBottom className={clsx(classes.boldText3)}>
              Blog
            </Typography>
            <Typography gutterBottom className={clsx(classes.text)}>
              Find new ways to explore and learn about Germiny products,
              innovations, and more.
            </Typography>
            <motion.div
              whileTap={{
                scale: 0.8,
              }}
              className={classes.fitContent}
            >
              <MButton variant="outlined" component={Link} to="/blog">
                Learn More
              </MButton>
            </motion.div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            square
            className={clsx(classes.p15, classes.paperBorder, classes.cards)}
            elevation={0}
          >
            <MIcon fontSize="large" src={Safety} />
            <Typography gutterBottom className={clsx(classes.boldText3)}>
              Safety Policy
            </Typography>
            <Typography gutterBottom className={clsx(classes.text)}>
              We take safety very seriously. To find out about our safety
              policy.
            </Typography>
            <motion.div
              whileTap={{
                scale: 0.8,
              }}
              className={classes.fitContent}
            >
              <MButton variant="outlined" component={Link} to="/privacy">
                Learn More
              </MButton>
            </motion.div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewsSection;
