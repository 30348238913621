import { useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import theme from "./theme";
import routes, { RouteWrapper } from "./routes";
import colors from "./theme/colors";
import ReactGA from "react-ga4";

ReactGA.initialize("G-7HP1HYB64K");

function App() {
  const location = useLocation();

  const [theming, setTheme] = useState(theme);

  const toggleTheme = () => {
    let newPaletteType = theming.palette.type === "light" ? "dark" : "light";

    setTheme((prev) => ({
      ...prev,
      palette: {
        type: newPaletteType,
        background: {
          default: newPaletteType === "dark" ? colors.background : "#fafafa",
          paper: newPaletteType === "light" ? "#fff" : colors.paperDark,
        },
      },
      overrides: {
        ...theming.overrides,
      },
    }));
    localStorage.setItem("germiny:type", newPaletteType);
  };

  const muiTheme = createMuiTheme(theming);
  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <AnimateSharedLayout type="switch">
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            {routes.map((route, i) => (
              <RouteWrapper key={i} {...route} toggleTheme={toggleTheme} />
            ))}
          </Switch>
        </AnimatePresence>
      </AnimateSharedLayout>
    </MuiThemeProvider>
  );
}

export default App;
