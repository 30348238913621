import { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Hidden,
  IconButton,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import useStyles from "../../theme/styles";
import Logo from "../Reusables/Logo";

const Navbar = ({
  handleClick,
  openDropdown,
  handleOpenSignup,
  openDropdown2,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [matches]);

  return (
    <AppBar position="static" elevation={0} className={classes.appBar}>
      <Toolbar>
        <div>
          <Logo />
        </div>
        <div className={classes.fg1} />
        {isMobile && (
          <>
            <div>
              <Button
                className={clsx(classes.btnWhite2)}
                variant="outlined"
                fontSize="small"
                onClick={handleOpenSignup}
              >
                Sign Up
              </Button>
            </div>

            <IconButton
              color="inherit"
              edge="end"
              onClick={handleClick}
              className={clsx("hamburger")}
              variant="text"
              size="small"
              style={{
                marginLeft: 10,
              }}
            >
              <span
                className={clsx("hamburger-box")}
                style={{
                  marginTop: (openDropdown || openDropdown2) && 10,
                }}
              >
                <span
                  className={clsx("hamburger-inner", classes.menuColor, {
                    [classes.menuColor]: openDropdown || openDropdown2,
                  })}
                ></span>
              </span>
            </IconButton>
          </>
        )}

        {!isMobile && (
          <Hidden mdDown>
            <Typography
              exact
              component={NavLink}
              to="/"
              className={classes.link}
              activeClassName={clsx(classes.activeLink)}
            >
              Home
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/about"
              className={classes.link}
              activeClassName={clsx(classes.activeLink)}
            >
              About Us
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/contact"
              className={classes.link}
              activeClassName={clsx(classes.activeLink)}
            >
              Contact Us
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/details/products"
              className={classes.link}
              activeClassName={clsx(classes.activeLink)}
            >
              Products
            </Typography>
            <Typography
              exact
              component={NavLink}
              to="/blog"
              className={classes.link}
              activeClassName={clsx(classes.activeLink)}
            >
              Blog
            </Typography>
            <Button
              className={clsx(classes.btnWhite2)}
              variant="outlined"
              fontSize="small"
              onClick={handleOpenSignup}
            >
              Sign Up
            </Button>
          </Hidden>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
