import {
  Paper,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import useStyles from "../../../../theme/styles";

const AllPracts = ({
  page,
  direction,
  setPage,
  contents,
  images,
  contentsIndex,
  imagesIndex,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const imageVariants = {
    hidden: {
      opacity: 0,

      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
        delay: 0.5,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
        delay: 0.1,

        // duration: 1.5,
      },
    },
    exit: {
      opacity: 0,

      // scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 60,
        ease: "easeOut",
      },
    },
  };

  const textWrapperVariant = {
    hidden: {
      // opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const textVariant = {
    hidden: (direction) => ({
      y: direction > 0 ? 50 : -50,
      opacity: 0,
    }),
    visible: {
      y: 0,
      opacity: 1,

      transition: {
        type: "spring",
        stiffness: 300,
        damping: 60,
        ease: "easeOut",
      },
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };

  return (
    <AnimatePresence key={page}>
      <Paper className={classes.overflowHidden} square elevation={0}>
        <Grid container direction={matches ? "column-reverse" : "row"}>
          <Grid item md={6} xs={12}>
            {contents.map((item, i) => (
              <motion.div
                key={i}
                className={clsx(
                  classes.slider,
                  classes.container,
                  classes.bgBlack,
                  classes.textWhite
                )}
                style={{
                  display: i === contentsIndex ? "block" : "none",
                }}
                variants={textWrapperVariant}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <motion.div variants={textVariant} custom={direction}>
                  <Typography gutterBottom className={clsx(classes.bigText)}>
                    {item.header}
                  </Typography>
                </motion.div>
                <motion.div variants={textVariant} custom={direction}>
                  <Typography gutterBottom className={classes.boldText}>
                    {item.body}
                  </Typography>
                </motion.div>
              </motion.div>
            ))}
          </Grid>

          <Grid item md={6}>
            <div
              className={clsx(
                classes.overflowHidden,
                classes.bgBlack,
                classes.relativePosition
              )}
            >
              {images.map((item, i) => (
                <motion.img
                  variants={imageVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  key={i}
                  alt={`bg-${i}`}
                  className={classes.bg}
                  src={item.img}
                  style={{ display: i === imagesIndex ? "block" : "none" }}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </AnimatePresence>
  );
};

export default AllPracts;
