import {
  Typography,
  Grid,
  Paper,
  IconButton,
  ButtonGroup,
  Button,
  Hidden,
} from "@material-ui/core";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import useStyles from "../../theme/styles";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as LeftArrow } from "../../theme/assets/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../theme/assets/right-arrow.svg";
import { ReactComponent as ChevronLeft } from "../../theme/assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../theme/assets/chevron-right.svg";

const Values = () => {
  const classes = useStyles();
  const [[page, direction], setPage] = useState([0, 0]);

  useEffect(() => {
    if (page > contents.length - 1) {
      setPage([0, direction]);
    } else if (page < 0) {
      setPage([contents.length - 1, direction]);
    }
  }, [page, direction]);

  const slideInVariants = {
    hidden: (direction) => ({
      x: direction > 0 ? -60 : 60,
      opacity: 0.99,
    }),
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        staggerChildren: 0.3,
        delayChildren: 0.2,
      },
    },
    exit: {
      x: -60,
      opacity: 0,
    },
  };

  const textVariants = {
    hidden: {
      y: 60,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
      },
    },
    exit: {
      y: -60,
      opacity: 0,
    },
  };

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const index = wrap(0, contents.length, page);

  return (
    <AnimatePresence initial={false} custom={direction}>
      <div
        className={clsx(
          classes.valuesContainer,
          classes.relativePosition
          // classes.container
        )}
      >
        <Hidden smDown>
          <video autoPlay muted loop id="myVideo" className={classes.img}>
            <source src="/videos/video-bg.mp4" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </Hidden>

        <Hidden smUp>
          <div className={clsx(classes.valuesMobileContainer, classes.z99)}>
            <Grid container alignItems="center">
              <Grid item>
                <span className={clsx(classes.lightGreyText, classes.mr10)}>
                  {page + 1}/{contents.length}
                </span>
              </Grid>
              <Grid item>
                <ButtonGroup
                  variant="outlined"
                  color="primary"
                  className={clsx(classes.borderWhite)}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    className={clsx(classes.borderNone)}
                    onClick={() => paginate(-1)}
                  >
                    <MIcon src={ChevronLeft} />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(classes.borderNone)}
                    onClick={() => paginate(1)}
                  >
                    <MIcon src={ChevronRight} />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </div>
        </Hidden>
        <Grid
          container
          spacing={3}
          justify="space-evenly"
          alignItems="center"
          className={clsx(classes.z9, classes.valuesGridContainer)}
        >
          <Grid item>
            <Hidden smDown>
              <IconButton
                onClick={() => paginate(-1)}
                variant="contained"
                className={classes.textWhite}
              >
                <MIcon src={LeftArrow} fontSize="large" />
              </IconButton>
            </Hidden>
          </Grid>
          <Grid item>
            <motion.div
              key={page}
              variants={slideInVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              custom={direction}
            >
              <Paper
                square
                elevation={0}
                className={clsx(
                  classes.container,
                  // classes.bgBlack,
                  // classes.textWhite,
                  classes.valuesPaper
                )}
              >
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  className={classes.mt20}
                >
                  <Grid item>
                    <Typography
                      gutterBottom
                      className={clsx(
                        // classes.cultureHeader,
                        classes.boldText,
                        classes.lightGreyText,
                        classes.z99
                      )}
                    >
                      Who We Are
                    </Typography>
                  </Grid>
                  <Hidden smDown>
                    <Grid item>
                      <span className={classes.lightGreyText}>
                        {page + 1}/{contents.length}
                      </span>
                    </Grid>
                  </Hidden>
                </Grid>
                <motion.div variants={textVariants}>
                  <Typography className={clsx(classes.cultureHeader)}>
                    {contents[index].title}
                  </Typography>
                </motion.div>

                <motion.div
                  variants={textVariants}
                  className={clsx(classes.text)}
                  dangerouslySetInnerHTML={{ __html: contents[index].body }}
                />
              </Paper>
            </motion.div>
          </Grid>
          <Grid item>
            <Hidden smDown>
              <IconButton
                onClick={() => paginate(1)}
                className={classes.textWhite}
              >
                <MIcon src={RightArrow} fontSize="large" />
              </IconButton>
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </AnimatePresence>
  );
};

const contents = [
  {
    title: "Mission",
    body: "<p>To give those in need of empowerment in the world a fair chance to one through the help of Empathy, Collaboration, Technology and Innovation.</p>",
  },
  {
    title: "Vision",
    body: "<p>To be the number one avenue in the world known for various innovative products created for the upliftment of humans and animals with the help of empathy and technology.</p>",
  },
  {
    title: "Values",
    body: `<p style="position: relative"><span style="z-index: 2; position: relative"><strong>Integrity</strong>: truth and honesty is our watch word; we do the right thing and we give the best to the society in which we find ourselves both professionally and in our personal lives.</span></p> <p style="position: relative"><span style="z-index: 2; position: relative"><strong>Empathy</strong>: we are considerate and we place ourselves into every situation in other to understand everyone’s feelings and to derive the best value from it.</span></p> <p style="position: relative"><span style="z-index: 2; position: relative"><strong>Innovation</strong>: just as the name implies, we are particular about birthing to life new technologies that brings convenience to the world at large for the betterment of every individual.</span></p> <p style="position: relative"><span style="z-index: 2; position: relative"><strong>Commitment</strong>: we are dedicated to providing the best and affordable services to every individual and we also offer continuous improvements including immediate response to feedbacks from our customers.</span></p>`,
  },
];

export default Values;
