import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from "clsx";
import useStyles from "../../theme/styles";

const MAccordion = ({ title, index, expanded, handleChange, children }) => {
  const classes = useStyles();
  return (
    <div>
      <Accordion
        expanded={expanded === index}
        onChange={handleChange(index)}
        square
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<Add fontSize="small" />}
          aria-controls={`panel-${index}-content`}
          id={`panel-${index}`}
        >
          <div className={clsx(classes.container)}>
            <Typography
              className={clsx(
                classes.bold,
                classes.text,
                classes.accordionTitle
              )}
            >
              {title}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={clsx(classes.container)}>
            <Typography>{children}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </div>
  );
};

export default MAccordion;
