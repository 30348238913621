import { Grid, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useStyles from "../../../theme/styles";
import AppButtons from "../../Reusables/AppButtons";
import MButton from "../../Reusables/MButton";

const Hero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className={clsx(classes.practHeroContainer, classes.container)}>
      <div className={clsx(classes.textWhite)}>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          spacing={5}
        >
          <Grid item md={8}>
            <Typography className={clsx(classes.subtitle)} gutterBottom>
              Germiny Practitioner
            </Typography>
            <Typography gutterBottom className={clsx(classes.boldText)}>
              {/* Register with us today and get a chance to do what you love,
              whenever you want. */}
              Primary healthcare is one of the most important aspects of the
              healthcare system. Accessibility and affordability of the right
              healthcare professional plays a huge role in getting this care.
              The Germiny Practitioner is concerned with maintaining and
              restoring health through the diagnosis, prognosis and treatment of
              diseases, injuries and other physical and mental impairments in
              the comfort of your home, office or any where you are.
            </Typography>

            <motion.div
              className={classes.fitContent}
              whileTap={{ scale: 0.8 }}
            >
              <MButton
                variant="outlined"
                extraStyle={classes.btnWhite}
                component={Link}
                to="/practitioner/register"
              >
                Sign Up
              </MButton>
            </motion.div>
            {matches ? (
              <AppButtons {...{ clsx, classes }} user="pract" />
            ) : null}
          </Grid>
          <Grid item md={4}>
            {matches ? null : (
              <div className={classes.textCenter}>
                <img src="/img/svgs/qr-code.svg" alt="QR code" height={250} />

                <div className={clsx(classes.mt20)}>
                  <AppButtons
                    {...{ clsx, classes }}
                    justify="center"
                    user="pract"
                  />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Hero;
