import CorpSection from "./CorpSection";
import HeroProduct from "./HeroProduct";
import IntroSection from "./IntroSection";
import PractSection from "./PractSection";
import Reason from "./Reason";
import Safety from "./Safety";
import VetSection from "./VetSection";

const ProductHome = () => {
  return (
    <>
      <HeroProduct />
      <IntroSection />
      <Reason />
      <VetSection />
      <PractSection />
      <CorpSection />
      <Safety />
    </>
  );
};

export default ProductHome;
