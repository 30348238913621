export function organizationStructuredData({
  headline,
  authorName,
  description,
}) {
  return JSON.stringify({
    "@context": "http://schema.org/",
    headline,
    author: authorName,
    description,
    "@type": "Organization",
    name: "Germiny",
    alternateName: "Germiny",
    url: "https://germiny.org",
    logo: "https://germiny.org/favicon-192x912.png",
    sameAs: [
      "https://www.facebook.com/112255277213482/",
      "https://twitter.com/Germiny_Africa",
      "https://instagram.com/germiny_africa",
      "https://youtube.com/channel/UCC8SOOph0qa_62k0kZXjwlQ",
      "https://www.linkedin.com/company/germinyafrica",
    ],
  });
}
