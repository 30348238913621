import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

import Features from "../components/HomeComponents/Features";
import Values from "../components/HomeComponents/Values";
import Connect from "../components/HomeComponents/Connect";
import Info from "../components/HomeComponents/Info";
import Newsletter from "../components/HomeComponents/Newsletter";
import Wrapper from "../components/Wrapper";
import Culture from "../components/HomeComponents/Culture";
import { pageTransition } from "../utils/transition";
import Landing from "../components/HomeComponents/Landing";
import Intro from "../components/HomeComponents/Intro";
import { organizationStructuredData } from "../structured-data";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [loadText, setLoadText] = useState(true);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/homepage" });
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3500);
    const timer2 = setTimeout(() => {
      setLoadText(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Intro loading={loading} loadText={loadText} />
      ) : (
        <>
          <Helmet>
            <title>Germiny - Redefining existence through technology</title>
            <meta property="og:title" content="Homepage" />
            <meta property="og:site_name" content="Germiny" />
            <meta property="og:url" content="https://germiny.org" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="Germiny&reg; &mdash; A technological solution which aims to improve the primary healthcare system in the world. Our Motto: Germiny&reg;. Redefining existence through technology. At Germiny®, we work to provide the best solutions to global issues, through the power of technology and team work."
            />

            <script type="application/ld+json">
              {organizationStructuredData({
                headline: "Collaboration, Innovation, Technology.",
                authorName: "Arifayan Idowu",
                description:
                  "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
              })}
            </script>
          </Helmet>
          <Wrapper>
            <motion.div
              layout
              variants={pageTransition}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Landing />
              <Connect />
              <Features />
              <Culture />
              <Values />

              <Newsletter />
              <Info />
            </motion.div>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default Home;
