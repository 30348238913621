import axios from "axios";

export const asyncFetch = async (
  url,
  method = "get",
  data = null,
  headers = null,
  cancelToken = null
) => {
  return axios({
    method,
    url,
    data,
    headers,
    cancelToken,
  });
};
