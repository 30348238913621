import { useEffect, useState } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useStyles from "../../theme/styles";
import MIcon from "../Reusables/MIcon";
import { ReactComponent as RightArrow } from "../../theme/assets/right-arrow.svg";
import { ReactComponent as CircleOutline } from "../../theme/assets/circle-outline.svg";
import MButton from "../Reusables/MButton";
import RightIcon from "../Reusables/RightIcon";
import LeftArrow from "../Reusables/LeftArrow";
import colors from "../../theme/colors";

const contents = [
  {
    title: "About Us",
    text: "Find out about our journey, our inspiration, our driving force and how we leverage technology at Germiny.",
    link: "/about",
    img: "/img/small/robot.webp",
  },
  {
    title: "Newsroom",
    text: "See announcements about our latest features, releases and more.",
    link: "/newsroom",
    img: "/img/small/reading-newspaper.webp",
  },
  {
    title: "Our Products",
    text: " Check out our amazing products, there is so much to love.",
    link: "/details/products",
    img: "/img/small/woman-phone.webp",
  },
];

const Info = () => {
  const classes = useStyles();
  const [[page, direction], setPage] = useState([0, 0]);

  useEffect(() => {
    if (page > 2) {
      setPage([0, direction]);
    }
    if (page < 0) {
      setPage([2, direction]);
    }
  }, [page, direction]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const transition = {
    type: "spring",
    stiffness: 400,
    damping: 30,
    ease: "easeOut",
  };

  const imageVariants = {
    hidden: (direction) => ({
      x: direction > 0 ? -1000 : 1000,

      opacity: 0,
    }),
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        x: {
          type: "spring",
          stiffness: 300,
          damping: 30,
          duration: 5,
          ease: "easeOut",
        },
        opacity: {
          duration: 0.2,
        },
      },
    },
    exit: {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    },
  };

  const textContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const activeAnimate = {
    hidden: {
      x: -10,
      opacity: 0,
      backgroundColor: "#cccac8",
    },
    visible: {
      x: 0,
      opacity: 1,
      backgroundColor: colors.black,
    },
  };

  const textVariants = {
    hidden: { y: 60, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
    exit: {
      opacity: 0,
    },
  };

  const counterVariants = {
    hidden: {
      opacity: 0,
      y: -50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 30,
        ease: "easeOut",
        delay: 0.1,
      },
    },
    exit: {
      opacity: 0,
      x: 350,
    },
  };

  const imageIndex = wrap(0, contents.length, page);

  return (
    <AnimatePresence key={page} initial>
      <div
        className={clsx(
          classes.container,
          classes.infoContainer,
          classes.relativePosition
        )}
      >
        <span className={clsx(classes.mt50, classes.dotsContainer, classes.z3)}>
          <span className={classes.dots} onClick={() => setPage([0, 0])}>
            <motion.span
              className={clsx({ [classes.dotsActive]: page === 0 })}
              custom={page}
              variants={activeAnimate}
              initial="hidden"
              animate="visible"
            />
          </span>
          <span className={classes.dots} onClick={() => setPage([1, 0])}>
            <motion.span
              className={clsx({ [classes.dotsActive]: page === 1 })}
              custom={page}
              variants={activeAnimate}
              initial="hidden"
              animate="visible"
            />
          </span>
          <span className={classes.dots} onClick={() => setPage([2, 0])}>
            <motion.span
              className={clsx({ [classes.dotsActive]: page === 2 })}
              variants={activeAnimate}
              initial="hidden"
              animate="visible"
            />
          </span>
        </span>
        <MIcon
          src={CircleOutline}
          fontSize="large"
          viewBox="0 0 34.76 34.76"
          className={classes.infoCircleAbs}
        />
        <motion.div className={classes.z3}>
          <Grid container justify="center">
            <Grid item md={6} xs={12}>
              <div className={clsx(classes.infoImgContainer)}>
                {contents.map((item, i) => (
                  <motion.img
                    key={i}
                    variants={imageVariants}
                    initial="hidden"
                    animate="visible"
                    custom={direction}
                    src={item.img}
                    alt={item.title}
                    className={clsx(classes.infoImg)}
                    style={{
                      display: i === imageIndex ? "block" : "none",
                    }}
                  />
                ))}
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={clsx(classes.infoContent)}>
                <motion.div
                  variants={textContainerVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className={clsx(classes.infoContentInner)}
                >
                  <motion.div variants={textVariants}>
                    <Typography
                      gutterBottom
                      className={clsx(classes.infoTitle)}
                    >
                      {contents[imageIndex]?.title}
                    </Typography>
                  </motion.div>
                  <motion.div variants={textVariants}>
                    <Typography className={clsx(classes.boldText)} gutterBottom>
                      {contents[imageIndex]?.text}
                    </Typography>
                  </motion.div>
                  <motion.div
                    className={clsx(classes.fitContent, classes.z3)}
                    whileTap={{ scale: 0.8 }}
                  >
                    <MButton
                      extraStyle={clsx(classes.btn2)}
                      variant="contained"
                      color="primary"
                      endIcon={<MIcon src={RightArrow} />}
                      to={contents[imageIndex].link || "/"}
                      component={Link}
                    >
                      Learn More
                    </MButton>
                  </motion.div>
                </motion.div>
              </div>
            </Grid>
          </Grid>
        </motion.div>

        <div className={clsx(classes.infoNextBtnAbs)}>
          <Grid container alignItems="center" spacing={0}>
            <Grid item>
              <IconButton size="small" onClick={() => paginate(-1)}>
                <LeftArrow page={page} color="#000" />
              </IconButton>
            </Grid>
            <Grid item>
              <motion.div
                variants={counterVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={classes.infoCounter}
              >
                {page === 1 ? "02" : page === 2 ? "03" : "01"}
              </motion.div>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => paginate(1)}>
                <RightIcon page={page} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default Info;
