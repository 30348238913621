export const typography = {
  letterSpacing: 1.5,
  sizes: {
    1: 11,
    2: 12,
    3: 13,
    4: 14,
    5: 15,
    6: 16,
    7: 17,
    8: 18,
    9: 24,
    10: 28,
    11: 36,
    12: 40,
  },
  weights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 600,
    bolder: 900,
  },
  largeText: {
    largest: "7.5rem",
    medium: "3rem",
    small: "2.5rem",
  },
};
