import { Typography, Grid, Hidden, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link, useHistory } from "react-router-dom";
import useStyles from "../../../theme/styles";
import MButton from "../../Reusables/MButton";
import MIcon from "../../Reusables/MIcon";
import { ReactComponent as Shield } from "../../../theme/assets/shield.svg";
import { ReactComponent as Shield_Circle } from "../../../theme/assets/shield_circle.svg";

const Safety = () => {
  const classes = useStyles();
  const history = useHistory();

  const absoluteIconPosition = {
    position: "absolute",
    top: 0,
    right: 0,
  };

  return (
    <div
      className={clsx(
        classes.container,
        classes.safetyBg,
        classes.relativePosition
      )}
    >
      <div>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography
              gutterBottom
              className={clsx(classes.subtitle, classes.mt30)}
            >
              Safety Policy
            </Typography>
            <Typography gutterBottom className={clsx(classes.boldText)}>
              At Germiny, your safety is of the highest importance to us. To
              read more about our safety policy.
            </Typography>
            <motion.div
              whileTap={{ scale: 0.8 }}
              className={classes.fitContent}
            >
              <MButton
                variant="contained"
                color="primary"
                component={Link}
                to="/privacy"
              >
                Learn More
              </MButton>
            </motion.div>
          </Grid>
          <Grid item>
            <Hidden smDown>
              <MIcon
                viewBox="0 0 485 485"
                src={Shield}
                style={{
                  fontSize: "20rem",
                }}
              />
            </Hidden>
            <Hidden smUp>
              <IconButton
                style={absoluteIconPosition}
                onClick={() => history.push("/privacy")}
              >
                <MIcon
                  src={Shield_Circle}
                  style={{ fontSize: "2.8rem" }}
                  className={clsx(classes.pulse)}
                />
              </IconButton>
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Safety;
