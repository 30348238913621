import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

import ContactContent from "../components/ContactComponents/ContactContent";
import ContactHeader from "../components/ContactComponents/ContactHeader";
import Wrapper from "../components/Wrapper";
import { pageTransition } from "../utils/transition";
import { organizationStructuredData } from "../structured-data";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Germiny - Drop us a line</title>
        <meta property="og:title" content="Contact Us" />
        <meta property="og:site_name" content="Germiny" />
        <meta property="og:url" content="https://germiny.org" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="239 MOD Towers, Herbert Macaulay way,Yaba, Lagos, Nigeria."
        />

        <script type="application/ld+json">
          {organizationStructuredData({
            headline: "Contact Us",
            authorName: "Arifayan Idowu",
            description:
              "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
          })}
        </script>
      </Helmet>
      <Wrapper>
        <motion.div
          layout
          variants={pageTransition}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <ContactHeader />
          <ContactContent />
        </motion.div>
      </Wrapper>
    </>
  );
};

export default Contact;
