import {
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import useStyles from "../../../theme/styles";
import MButton from "../../Reusables/MButton";

const PractSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Grid
        container
        component={Paper}
        square
        elevation={0}
        className={clsx(
          classes.overflowHidden,
          classes.bgBlack,
          classes.textWhite
        )}
        justify="center"
        alignItems="center"
        direction={matches ? "column-reverse" : "row"}
      >
        <Grid item xs={12} md={6} className={clsx(classes.container)}>
          <Typography
            gutterBottom
            className={clsx(classes.subtitle, classes.mt30)}
          >
            Germiny for Practitioner
          </Typography>
          <Typography gutterBottom className={clsx(classes.boldText)}>
            We provide affordable primary healthcare to you whenever and
            wherever you are. To find out more about Germiny for practitioner.
          </Typography>
          <motion.div
            className={classes.fitContent}
            whileTap={{
              scale: 0.8,
            }}
          >
            <MButton
              variant="outlined"
              extraStyle={classes.btnWhite}
              component={Link}
              to="/products/practitioner"
            >
              Learn More
            </MButton>
          </motion.div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.productImg4} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PractSection;
